<template>
    <sidenav v-if="showSidenav" :custom_class="color" :class="[isRTL ? 'fixed-end' : 'fixed-start']" />
    <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
        <navbar v-if="showNavbar" :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']" :color="isAbsolute ? 'text-white opacity-8' : ''" :min-nav="navbarMinimize" />
        <router-view />
        <app-footer v-show="showFooter" />
        <configurator :toggle="toggleConfigurator" :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']" />
    </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
// import Navbar from "@/examples/Navbars/Navbar.vue";
// import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";

export default {
    name: "App",
    components: {
        Sidenav,
        Configurator,
        // Navbar,
        // AppFooter
    },
    computed: {
        ...mapState([
            "isRTL",
            "color",
            "isAbsolute",
            "isNavFixed",
            "navbarFixed",
            "absolute",
            "showSidenav",
            "showNavbar",
            "showFooter",
            "showConfig",
            "hideConfigButton"
        ])
    },
    beforeMount() {
        const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

        if (window.innerWidth > 1200) {
            sidenav.classList.add("g-sidenav-pinned");
        }
    },
    methods: {
        ...mapMutations(["toggleConfigurator", "navbarMinimize"])
    }
};
</script>
<style>
.dataTable-pagination-list .active a {
    background-image: linear-gradient(195deg,
            #66bb6a 0%,
            #43a047 100%) !important;
}
</style>
