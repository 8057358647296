import axios from 'axios';

const URL = `${process.env.VUE_APP_API_BASE_URL}/dashboard`;

export default {
    async getDashboard() {
        try {
            const { data } = await axios.get(URL);

            data.fechaIn = new Date(data.fechaIn).toLocaleDateString('es-MX', {
                timeZone: 'America/Merida',
                day: 'numeric',
                month: 'long',
            });

            data.fechaFn = new Date(data.fechaFn).toLocaleDateString('es-MX', {
                timeZone: 'America/Merida',
                day: 'numeric',
                month: 'long',
            });

            return data;
        } catch (error) {
            console.log('Dashboard Service:', error);

            return null;
        }
    },
};
