<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 plan">
            <div class="col-12">
                <div class="plan d-flex align-items-center">
                    <div class="col-6">
                        <h5 class="mb-4">Bitácoras de Control</h5>
                    </div>
                    <div class="col-6 text-end"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <material-button class="float-right btn btm-sm btn-success" @click="this.$router.push({ name: 'AddControlLog' })">Agregar Nuevo</material-button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="title-column ps-2">Número Económico</th>
                                        <th class="title-column ps-2">Fecha</th>
                                        <th class="title-column ps-2">% SOC Entrada</th>
                                        <th class="title-column ps-2">% SOC Salida</th>
                                        <th class="title-column ps-2">Gestor</th>
                                        <th class="title-column ps-2">Observaciones</th>
                                        <th class="title-column ps-2 text-center">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in bitacorasList" :key="item.iIdBitacoraControl">
                                        <td><p class="table-p">{{ item.vNumEconomico }}</p></td>
                                        <td><p class="table-p">{{ item.dFechaBitacora }}</p></td>
                                        <td><p class="table-p">{{ item.fSocEntrada }}</p></td>
                                        <td><p class="table-p text-center">{{ item.fSocSalida }}</p></td>
                                        <td><p class="table-p text-center">{{ item.vGestor }}</p></td>
                                        <td><p class="table-p text-center">{{ item.vObservaciones }}</p></td>
                                        <td class="text-center">
                                            <div class="col-md-12 m-0 text-center">
                                                <div class="d-flex align-items-center justify-content-end">
                                                    <material-button class="float-right btn btm-sm btn-success mx-auto" @click="this.$router.push({ name: 'EditCorrective', params: { id: item.iIdBitacoraControl } })">Detalle</material-button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// import Choices from "choices.js";
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: "ControlLogList",
    components: {
        
    },
    data() {
        return {
            bitacorasList: [],
        }
    },
    async mounted() {
        this.getBitacoras();
    },
    methods: {
        getBitacoras() {
            this.bitacorasList = [];
            
            axios.get(API_URL + "/bitacoras/control").then(res => {
				this.bitacorasList = res.data;
			});
        }
    }
};
</script>
<style>
    .title-column {
        text-transform: uppercase !important;
        color: #7b809a !important;
        opacity: 0.7 !important;
        font-weight: 700 !important;
        font-size: 0.65rem !important;
    }
    .table-p {
        font-weight: 400 !important;
        font-size: 0.75rem !important;
        line-height: 1.25 !important;
        margin-bottom: 0 !important;
    }
</style>
