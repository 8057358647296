import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getPlans(params) {
        const response = await axios.get(API_URL + "/plans", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },
    async getPlan(planId) {
        const response = await axios.get(API_URL + "/plans/" + planId, { headers: authHeader() });
        console.log(response.data);
        return response.data;
    },
    async addPlan(plan) {
        plan.type = "plans"
        const newJson = dataFormatter.serialize({ stuff: plan });
        let object    = newJson.data?.attributes;

        console.log(object);
        const response = await axios.post(API_URL + "/plans", object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async editPlan(plan) {
        plan.type = "plans"
        const newJson = dataFormatter.serialize({ stuff: plan });
        let object = newJson.data?.attributes;
        const response = await axios.put(API_URL + "/plans/" + plan.iIdPlanMantenimientoPrev, object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async deletePlan(planId) {
        await axios.delete(API_URL + "/plans/" + planId, { headers: authHeader() });
    },
}