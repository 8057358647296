import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getRutas(params) {
        const response = await axios.get(API_URL + "/rutas", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },

    async addRuta(ruta) {
        ruta.type = "rutas"
        //Modelo.relationshipNames=['roles']

        const newJson = dataFormatter.serialize({ stuff: ruta })
        const response = await axios.post(API_URL + "/ruta", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteRuta(rutaId) {
        await axios.delete(API_URL + "/ruta/eliminar-ruta/" + rutaId, { headers: authHeader() });
    },

    async getRuta(rutaId) {
        const response = await axios.get(API_URL + "/rutas/" + rutaId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editRuta(ruta) {
        ruta.type = "rutas"
        const newJson = dataFormatter.serialize({ stuff: ruta })
        const response = await axios.patch(API_URL + "/ruta/" + ruta.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async uploadFile(pic) {
        const postUrl = API_URL + "/subirArchivo";
        const response = await axios.post(postUrl,
            { file: pic },
            { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        return response.data;
    }
}