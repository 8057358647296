/* eslint-disable no-unused-vars */
import unidadesServices from '../services/units.service';

const initialState = { unidades: null, unidad: null };

export const units = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUnidades({ commit }, options) {
            const unidades = await unidadesServices.getUnidades(options);
            commit('getUnidadesSuccess', unidades);
        },

        async addUnidad({ commit }, newunidad) {
            const unidad = await unidadesServices.addUnidad(newunidad);
            commit('getUnidadSuccess', unidad);
        },

        async deleteUnidad({ commit }, unidadId) {
            await unidadesServices.deleteUnidad(unidadId);
        },


        async getUnidad({ commit }, unidadId) {
            const unidad = await unidadesServices.getUnidad(unidadId);
            commit('getUnidadSuccess', unidad);
        },

        async editUnidad({ commit }, modifiedunidad) {
            await unidadesServices.editUnidad(modifiedunidad); 
        },


    },
    mutations: {
        getUnidadesSuccess(state, unidades) {
            state.unidades = unidades;
        },
        successUpload(state, picURL){
            state.unidad.profile_image = picURL;
        },
        getUnidadSuccess(state,unidad){
            state.unidad = unidad;
        }

    },
    getters: {
        getUnidadesData(state) {
            return state.unidades?.data;
        },
        getUnidadesMeta(state) {
            return state.unidades?.meta;
        },
        getUnidad(state){
            return state.unidad
        },
        getImage(state){
            return state.unidad.profile_image
        },
    }
}

