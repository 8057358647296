<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 company">
            <div class="col-12">
                <div class="card">
                    <div class="card-header border-bottom">
                        <div class="company d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Empresas</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Add Company' })">
                                    Agregar Empresa
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="dataTable-search search-block col-2 ms-3">
                            <material-input v-model:value="search" placeholder="Search..." class="mb-1 dataTable-input search-input-table" variant="static" />
                        </div>
                        <div class="table-responsive">
                            <table id="company-list" ref="table" class="table table-flush">
                                <thead class="thead-light">
                                    <tr>
                                        <th title="vEmpresa" class="w-20">Nombre de la empresa</th>
                                        <th title="vNombreEnlace" class="w-20">Nombre del enlace</th>
                                        <th title="vTelefonoEnlace" class="w-20">Contacto del enlace</th>
                                        <th title="vUbicacionEncierro" class="w-30">Ubicación de encierro</th>
                                        <th data-sortable="false" class="text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm">
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end align-items-center">
                        <BasePagination v-model="pagination.currentPage" class="pagination-success pagination-md me-3" :value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total" @click="getDataFromPage($event)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DataTable } from "simple-datatables";
import eventTable from "@/mixins/eventTable.js"
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";
import store from "@/store";
import _ from "lodash";

var sortDirection  = "created_at";
var searchQuery    = '';
var getCurrentPage = 1;

const getCompaniesList = _.debounce(async function (params) {
    await store.dispatch("companies/getCompanies", {
        ...(params.sort ? { sort: params.sort } : {}),
        filter: {
            ...(params.query ? { name: params.query } : {}),
        },
        page: {
            number: params.nr,
            size: params.perpage,
        },
    });
}, 300);

export default {
    name: "CompaniesList",
    components: {
        MaterialButton,
        MaterialInput,
        BasePagination
    },
    mixins: [eventTable],
    data() {
        return {
            search: '',
            dataCompanies: [],
            pagination: {},
            dataTable: null,
            keys: null,
        }
    },
    computed: {
        companiesList() {
            return this.$store.getters["companies/getCompaniesData"];

        },
        metaPage() {
            return this.$store.getters["companies/getCompaniesMeta"];
        }
    },
    watch: {
        metaPage: {
            handler: "reactivePagination",
            immediate: false,
            deep: true,
        },
        companiesList: {
            handler: "reactiveTable",
            immediate: false,
            deep: true,
        },
        search: {
            handler: "reactiveSearch"
        }
    },
    async mounted() {
        if (this.$refs.table) {
            this.dataTable = new DataTable(this.$refs.table, {
                searchable: false,
                fixedHeight: false,
                perPage: 5,
            });

            document.querySelector(".dataTable-bottom").remove()
            this.dataTable.label = null;
            this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

            await getCompaniesList({
                sort: sortDirection,
                query: '',
                nr: getCurrentPage,
                perpage: this.dataTable.options.perPage
            });

            this.dataTable.on('datatable.perpage', async function (perpage) {
                this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
                await getCompaniesList({
                    sort: sortDirection,
                    query: searchQuery,
                    nr: getCurrentPage = 1,
                    perpage: perpage
                });
            });
            
            this.dataTable.on('datatable.sort', async function (column, direction) {
                column = this.headings[column].title;
                direction = direction == "asc" ? "" : "-";
                await getCompaniesList({
                    sort: sortDirection = direction + column,
                    query: searchQuery,
                    nr: getCurrentPage,
                    perpage: this.options.perPage
                });
            });
        };
    },
    beforeUnmount() {
        sortDirection = "created_at";
        searchQuery = '';
        getCurrentPage = 1;
    },
    methods: {
        async getDataFromPage(page) {
            await getCompaniesList({
                sort: sortDirection,
                query: this.search,
                nr: page,
                perpage: this.dataTable.options.perPage
            });
        },
        async reactiveSearch() {
            searchQuery = this.search;
            await getCompaniesList({
                sort: sortDirection,
                query: this.search,
                nr: getCurrentPage = 1,
                perpage: this.dataTable.options.perPage
            });
        },
        async reactivePagination() {
            this.pagination = this.metaPage;
            this.keys = Object.keys(this.pagination);

            this.pagination = {
                currentPage: this.pagination[this.keys[0]],
                perPage: this.pagination[this.keys[3]],
                total: this.pagination[this.keys[5]]
            }
            getCurrentPage = this.pagination.currentPage;
            return this.pagination;
        },
        async reactiveTable() {
            this.dataCompanies = [];
            if (this.companiesList.length > 0) {
                this.companiesList.forEach(company => {
                    this.dataCompanies.push(
                        [
                            `<h6 class="my-auto">${company.vEmpresa}</h6>`,
                            company.vNombreEnlace,
                            company.vTelefonoEnlace,
                            company.vUbicacionEncierro,
                            `<div class="text-end">${this.actionEditButton(company.iIdEmpresa, "Edit Company") + this.actionDeleteButton(company.iIdEmpresa, "Delete Company")}</div>`
                        ]
                    )
                });
                this.dataTable.data = [];
                this.dataTable.refresh();
                this.dataTable.insert({ data: this.dataCompanies });
                this.removeEvent();
                this.eventToCall({
                    table: this.dataTable,
                    myUserId: null,
                    redirectPath: "Edit Company",
                    deletePath: "companies/deleteCompany",
                    getPath: "companies/getCompanies",
                    textDelete: "Registro eliminado!",
                    textDefaultData: 'companies'
                });
            }
            else {
                this.dataTable.setMessage('No results match your search query');
            }
        }
    }
};
</script>
