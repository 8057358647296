<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <div class="row">
                                    <div class="col-12 col-lg-9 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">event</i>
                                                </div>
                                                <h6 class="mb-0">Registro de mantenimiento correctivo</h6>
                                            </div>
                                            <div class="card-body">
                                                <div class="row mt-4">
                                                    <div class="col-12 col-md-3">
                                                        <div class="input-group input-group-static bg-white">
                                                            <!-- <select v-model="unidad.iIdUnidad" class="form-control" name="iIdUnidad" placeholder="Selecciona" @change="onChangeUnidad($event)">
                                                                <option selected value="">Seleccione una unidad</option>
                                                                <option v-for="item in unidades" :key="item.iIdUnidad" :value="item.iIdUnidad">
                                                                    {{ item.vPlaca }}
                                                                </option>
                                                            </select> -->
                                                            <select id="iIdUnidad" class="form-control" @change="onChangeUnidad($event)">
                                                                <option selected disabled>Seleccionar Unidad</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Placa</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.vPlaca }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Num Econónico</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.vNumEconomico }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Marca</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.vNombreMarca }}</p>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Modelo</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.vNombreModelo }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Año</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.iAnioFabricacion }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Serie</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.vSerie }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Odómetro</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.iKmActual }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row my-2">
                                                    <div class="col-12">
                                                        <hr class="hr">
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-md-3">
                                                        <div class="input-group input-group-static null is-focused">
                                                            <material-input id="dFechaIngreso" v-model:value="corrective.dFechaIngreso" label="Fecha Ingreso a taller" variant="static" name="dFechaIngreso" type="date"></material-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="input-group input-group-static null is-focused">
                                                            <material-input id="dFechaEgreso" v-model:value="corrective.dFechaEstimada" label="Fecha programada de salida" variant="static" name="dFechaEgreso" type="date"></material-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="input-group input-group-static null is-focused">
                                                            <material-input id="dFechaEgresoReal" v-model:value="corrective.dFechaEgreso" label="Fecha real de salida de taller" variant="static" name="dFechaEgresoReal" type="date"></material-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <material-input id="vUbicacion" v-model:value="corrective.vUbicacion" label="Lugar" variant="static" name="vUbicacion"></material-input>
                                                    </div>
                                                </div>
                                                <div class="row d-flex align-items-end justify-content-center mt-4">
                                                    <div class="col-md-3">
                                                        <material-input id="fCosto" v-model:value="corrective.fCosto" label="Costo Total" variant="static" name="fCosto"></material-input>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <material-input id="vComentarios" v-model:value="corrective.vComentarios" label="Comentarios" variant="static" name="vComentarios"></material-input>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Estatus</h6>
                                                        <p class="text-sm text-secondary mb-0">
                                                            <span v-if="corrective.iEstatus === 1" class="badge badge-md badge-info">En Proceso</span>
                                                            <span v-else-if="corrective.iEstatus === 3" class="badge badge-md badge-danger">Cancelado</span>
                                                            <span v-else class="badge badge-md badge-success">Completado</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Factura del Mantenimiento</h6>
                                                        <div class="col-12 col-md-12 col-lg-12">
                                                            <material-button v-show="!file" size="sm" type="button">
                                                                <label for="fileInput" class="mb-0 text-white small cursor-pointer">Agregar Factura</label>
                                                                <input id="fileInput" type="file" style="display: none;" @change.prevent="uploadInvoice">
                                                            </material-button>
                                                            <div v-show="file">
                                                                <material-button class="btn btn-icon btn-2 btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                    <span class="btn-inner--icon"><i class="material-icons">description</i></span>
                                                                </material-button>
                                                                <material-button size="sm" type="button" color="danger" class="mx-2" @click.prevent="deleteInvoice">
                                                                    <label class="mb-0 text-white small cursor-pointer">&#10005; Eliminar</label>
                                                                </material-button>
                                                                <material-button size="sm" type="button">
                                                                    <label for="fileInput" class="mb-0 text-white small cursor-pointer">Cambiar</label>
                                                                    <input id="fileInput" type="file" style="display: none;" @change.prevent="uploadInvoice">
                                                                </material-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-12">
                                                        <hr class="hr">
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="row mt-4">
                                                        <div class="col-12 mt-3">
                                                            <div class="d-flex flex-row justify-content-between">
                                                                <h6 class="mb-0">Detalle del Mantenimiento</h6>
                                                                <material-button type="button" class="float-right btn btm-sm" data-bs-toggle="modal" data-bs-target="#modalActivity">Agregar Actividad</material-button>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 mt-3">
                                                            <div class="table-responsive">
                                                                <table class="table align-items-center mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="title-column ps-2 text-start">Actividad</th>
                                                                            <th class="title-column ps-2 text-center">Duración estimada</th>
                                                                            <th class="title-column ps-2 text-end">Costo mano de obra</th>
                                                                            <th class="title-column ps-2 text-end">Costo refacciones</th>
                                                                            <th class="title-column ps-2 text-end">Costo otros</th>
                                                                            <th class="title-column ps-2 text-end">Costo total</th>
                                                                            <th class="title-column ps-2 text-center">Estatus</th>
                                                                            <th class="title-column ps-2 text-center">Evidencia</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(item, index) in corrective.lDetalle" :key="item.iIdDetallePlanMantenimientoPrev">
                                                                            <td class="text-start"><p class="table-p mb-0">{{ item.vNombreActividad }}</p></td>
                                                                            <td class="text-center"><p class="table-p mb-0">{{ item.iDuracionHorasTotal }}</p></td>
                                                                            <td class="text-end"><p class="table-p mb-0">$ {{ formatPrice(item.fCostoManoObra) }}</p></td>
                                                                            <td class="text-end"><p class="table-p mb-0">$ {{ formatPrice(item.fCostoRefacciones) }}</p></td>
                                                                            <td class="text-end"><p class="table-p mb-0">$ {{ formatPrice(item.fCostoOtro) }}</p></td>
                                                                            <td class="text-end"><p class="table-p mb-0">$ {{ formatPrice(item.fCostoTotal) }}</p></td>
                                                                            <td class="text-center"><input v-model="item.selected" type="checkbox"></td>
                                                                            <td class="text-center">
                                                                                <div class="mt-1 mb-2 text-cente">
                                                                                    <material-button v-show="item.vEvidence == ''" size="sm" type="button">
                                                                                        <label :for="`imageInput_${index}`" class="mb-0 text-white small">Select Image</label>
                                                                                        <input :id="`imageInput_${index}`" :data-type="`${index}`" type="file" style="display: none;" accept="image/*" @change.prevent="uploadEvidenciaDetalle">
                                                                                    </material-button>
                                                                                    <div v-show="item.vEvidence != ''">
                                                                                        <material-button class="btn btn-icon btn-2 btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#modalImage" @click.prevent="onViewImage(item.vEvidencia)">
                                                                                            <span class="btn-inner--icon"><i class="material-icons">description</i></span>
                                                                                        </material-button>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-5">
                                                    <div class="col-md-12 d-flex justify-content-end mt-3">
                                                        <material-button class="float-right btn btm-sm btn-danger me-3" @click="this.$router.push({ name: 'CorrectiveList' })">Cancelar</material-button>
                                                        <material-button class="float-right btn btm-sm" @click.prevent="update">Editar</material-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title font-weight-normal">Factura del Mantenimiento</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe :src="corrective.vFactura" style="width:100%; height:700px;" frameborder="0"></iframe>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalImage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title font-weight-normal">Evidencia</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img :src="image" style="width:100%; object-fit: contain;">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalActivity" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalActivityLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="modalActivityLabel" class="modal-title font-weight-normal">Agregar Actividad</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mt-4">
                        <div class="col-12 my-3 ps-4">
                            <div class="form-check form-switch">
                                <input id="flexSwitchCheckDefault" v-model="selectActivity" class="form-check-input" type="checkbox" checked="true" >
                                <label class="form-check-label" for="flexSwitchCheckDefault">Seleccionar Actividad Existente</label>
                            </div>
                        </div>
                        <template v-if="selectActivity">
                            <div class="col-12 mb-3">
                                <div class="input-group input-group-static">
                                    <select v-model="iIdActividad" class="form-control" name="iIdActividad" placeholder="Selecciona" @change="onChangeActividad()">
                                        <option selected value="">Selecciona Actividad</option>
                                        <option v-for="item in actividades" :key="item.iIdActividad" :value="item.iIdActividad">
                                            {{ item.vDescripcion }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-12">
                                <div class="row my-3">
                                    <div class="col-12 col-md-6">
                                        <material-input id="vNombreActividad" v-model:value="detalle.vNombreActividad" label="Nombre actividad" variant="dynamic" name="vNombreActividad"></material-input>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <material-input id="iDuracionHorasTotal" v-model:value="detalle.iDuracionHorasTotal" label="Duración (hrs)" variant="static" name="iDuracionHorasTotal"></material-input>
                                    </div>
                                </div>
                                <div class="row my-3">
                                    <div class="col-12 col-md-6">
                                        <material-input id="fCostoManoObra" v-model:value="detalle.fCostoManoObra" label="Mano de obra" variant="static" name="fCostoManoObra" @change="onChangePrice()"></material-input>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <material-input id="fCostoRefacciones" v-model:value="detalle.fCostoRefacciones" label="Refacciones" variant="static" name="fCostoRefacciones" @change="onChangePrice()"></material-input>
                                    </div>
                                </div>
                                <div class="row my-3">
                                    <div class="col-12 col-md-6">
                                        <material-input id="fCostoOtro" v-model:value="detalle.fCostoOtro" label="Otros costos" variant="static" name="fCostoOtro" @change="onChangePrice()"></material-input>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <material-input id="fCostoTotal" v-model:value="detalle.fCostoTotal" label="Total" variant="static" name="fCostoTotal"></material-input>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="col-12 my-3 text-center">
                            <material-input id="vComentarios" v-model:value="detalle.vComentarios" label="Comentarios" variant="static" name="vComentarios"></material-input>
                        </div>
                        <div class="col-8 mx-auto my-3 ps-4">
                            <div class="mt-1 mb-2 text-center">
                                <material-button v-if="detalle.vEvidencia == ''" size="sm" type="button">
                                    <label for="evidence" class="mb-0 text-white small cursor-pointer">Agregar Evidencia</label>
                                    <input id="evidence" type="file" style="display: none;" @change.prevent="uploadEvidenciaModal">
                                </material-button>
                                <div v-else>
                                    <img :src="detalle.vEvidencia" style="width:100%; object-fit: contain;">
                                    <div class="row">
                                        <div class="col-md-12 d-flex justify-content-center mt-3">
                                            <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="deleteEvidenciaModal">
                                                <label class="mb-0 text-white small cursor-pointer">&#10005; Eliminar</label>
                                            </material-button>
                                            <material-button size="sm" type="button">
                                                <label for="fileInput" class="mb-0 text-white small cursor-pointer">Cambiar</label>
                                                <input id="fileInput" type="file" style="display: none;" @change.prevent="uploadEvidenciaModal">
                                            </material-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <material-button class="float-right btn btm-sm bg-gradient-secondary" data-bs-dismiss="modal">Close</material-button>
                    <material-button class="float-right btn btm-sm" @click.prevent="addDetail()">Agregar</material-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
// import formMixin from "@/mixins/formMixin.js"; 
import showSwal from "../../../mixins/showSwal";
import { Modal } from 'bootstrap';
import Choices from 'choices.js';
import axios from 'axios';
import authHeader from '../../../services/auth-header';

const API_URL  = process.env.VUE_APP_API_BASE_URL;
const FILE_URL = process.env.VUE_APP_FILE_BASE_URL;
import correctivesService from '../../../services/correctives.service';

export default {
    name: 'EditCorrective',
    components: {
        MaterialButton,
        MaterialInput,
    },
    data() {
        return {
            actividades: [],
            unidades: [],
            unidad: {},
            corrective: {
                iIdMantenimiento: null,
                vFolio: "",
                iIdUnidad: "",
                dFechaIngreso: new Date(),
                dFechaEgreso: "",
                dFechaEstimada: "",
                iOdometro: "",
                vComentarios: "",
                fCosto: "",
                vFactura: "",
                vUbicacion: "",
                iIdTipoMantenimiento: "",
                iEstatus: 1,
                lDetalle: [],
            },
            detalle: {
                iIdMantenimiento: null,
                vNombreActividad: "",
                iDuracionHorasTotal: null,
                fCostoManoObra: null,
                fCostoRefacciones: null,
                fCostoOtro: null,
                fCostoTotal: null,
                vEvidencia: "",
                iEstatus: 1,
                bActivo: true,
                selected: false
            },
            selectActivity: false,
            file: null,
            evidence: null,
            image: null,
            addModal: null,
            iIdActividad: null,
            errors: {}
        }
    },
    async mounted() {
        var modelElement = document.getElementById("iIdUnidad");
        this.unidadesChoices = new Choices(modelElement, { searchEnabled: true });
        await this.getData(this.$route.params.id);

        this.getActividades();
        this.getUnidadesChoice(0);

        this.addModal = new Modal(document.getElementById('modalActivity'));
    },
    methods: {
        getUnidadesChoice(iIdModelo) {
            this.unidades = [];

			axios.get(API_URL + "/unidades/modelo/" + iIdModelo, { headers: authHeader() }).then(res => {
				this.unidades = res.data;

				this.unidadesChoices.setChoices(this.unidades, 'iIdUnidad', 'vNumEconomico');
                this.unidadesChoices.setChoiceByValue(this.corrective.iIdUnidad);
                this.unidad = this.unidades.find(x => x.iIdUnidad == this.corrective.iIdUnidad);
			});

		},
        getActividades() {
            this.actividades = [];

			axios.get(API_URL + "/plans/actividades").then(res => {
                this.actividades = res.data;
			});
		},
        onChangeUnidad(event) {
            this.unidad = this.unidades.find(x => x.iIdUnidad == event.target.value);

            if(this.unidad != undefined && this.unidad != null) {
                this.corrective.iIdUnidad = this.unidad.iIdUnidad;
                this.corrective.iOdometro = this.unidad.iKmActual;
            }
		},
        onChangeActividad() {
            let actividad = this.actividades.find(x => x.iIdActividad == this.iIdActividad);
            if(actividad != undefined && actividad != null) {
                this.detalle.iIdMantenimiento    = null;
                this.detalle.vNombreActividad    = actividad.vDescripcion;
                this.detalle.iDuracionHorasTotal = actividad.iDurecionHoras;
                this.detalle.fCostoManoObra      = actividad.fCostoManoObra;
                this.detalle.fCostoRefacciones   = actividad.fCostoRefacciones;
                this.detalle.fCostoOtro          = actividad.fCostoOtro;
                this.detalle.fCostoTotal         = actividad.fCostoTotal;
                this.detalle.selected            = false;
                this.detalle.bActivo             = true;
                this.detalle.vEvidencia          = "";
                this.detalle.iEstatus            = 1;
                console.log(actividad);
            }

            console.log(this.detalle);
		},
        onChangePrice() {
            let fCostoManoObra    = Number(this.detalle.fCostoManoObra);
            let fCostoRefacciones = Number(this.detalle.fCostoRefacciones);
            let fCostoOtro        = Number(this.detalle.fCostoOtro);

            this.detalle.fCostoTotal = fCostoManoObra + fCostoRefacciones + fCostoOtro;
        },
        async uploadInvoice(event) {
            this.file = event.target.files[0];
            this.corrective.vFactura = URL.createObjectURL(this.file);

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.file);
            this.corrective.vFactura = FILE_URL + rutaFile;
        },
        async uploadEvidenciaModal(event) {
            let evidence = event.target.files[0];
            this.detalle.vEvidencia = URL.createObjectURL(evidence);

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", evidence);
            this.detalle.vEvidencia = FILE_URL + rutaFile;
        },
        async uploadEvidenciaDetalle(event) {
            const index = event.target.getAttribute("data-type");
            this.corrective.lDetalle[index].fFile = event.target.files[0];

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.corrective.lDetalle[index].fFile);
            this.corrective.lDetalle[index].vEvidencia = FILE_URL + rutaFile
        },
        deleteInvoice() {
            this.file = null
            this.corrective.vFactura = null;
        },
        deleteEvidenciaModal() {
            this.file = null
            this.detalle.vEvidencia = null;
        },        
        deleteEvidenciaDetalle(index) {
            this.corrective.lDetalle[index].fFile = null
            this.corrective.lDetalle[index].vEvidencia = require("@/assets/img/placeholder.jpg");
        },
        addDetail() {
            if(this.detalle.fCostoTotal > 0) {
                this.corrective.fCosto = Number(this.corrective.fCosto) + Number(this.detalle.fCostoTotal);
            }

            this.corrective.lDetalle.push(this.detalle);
            this.detalle = {
                iIdMantenimiento: null,
                vNombreActividad: "",
                iDuracionHorasTotal: null,
                fCostoManoObra: null,
                fCostoRefacciones: null,
                fCostoOtro: null,
                fCostoTotal: null,
                vEvidencia: "",
                iEstatus: 1,
                bActivo: true,
                selected: false
            };


            this.addModal.hide();
            document.querySelector('.modal-backdrop').remove();
        },
        onViewImage(path) {
            this.image = path;
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        formatDate(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1;
            var y = date.getFullYear();
            return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
        },
        async getData(id) {
            this.corrective = await correctivesService.getCorrective(id);
            this.detalle.iIdMantenimiento = this.corrective.iIdMantenimiento;

            this.corrective.dFechaIngreso = this.formatDate(new Date(this.corrective.dFechaIngreso));
            this.corrective.dFechaEstimada = this.formatDate(new Date(this.corrective.dFechaEstimada));
            this.corrective.dFechaEgreso = this.formatDate(new Date(this.corrective.dFechaEgreso));
        },
        async update() {
            try {
                await this.$store.dispatch("correctives/editCorrective", this.corrective);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Corrective added successfully!",
                    width: 500
                });
                this.$router.push({ name: "CorrectiveList" });
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Ocurrió un error, contacte con el administrador.",
                    width: 500
                });
            }
        }
    }
}
</script>
<style>
    .table thead th {
        padding: 0.75rem 12px !important;
    }
    .input-group-outline .choices__inner {
        border: 1px solid #d2d6da !important;
    }
    .title-column {
        text-transform: uppercase !important;
        color: #7b809a !important;
        opacity: 0.7 !important;
        font-weight: 700 !important;
        font-size: 0.65rem !important;
    }
    .table-p {
        font-weight: 400 !important;
        font-size: 0.75rem !important;
        line-height: 1.25 !important;
        margin-bottom: 0 !important;
    }
</style>