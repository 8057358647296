/* eslint-disable no-unused-vars */
import modelosService from '../services/modelos.service';
const initialState = { modelos: null, modelo: null };

export const modelos = {
    namespaced: true,
    state: initialState,
    actions: {
        async getModelos({ commit }, options) {
            const modelos = await modelosService.getModelos(options);
            commit('getModelosSuccess', modelos);
        },
        async addModelo({ commit }, newmodelo) {
            const modelo = await modelosService.addModelo(newmodelo);
            commit('getModelosuccess', modelo);
        },
        async deleteModelo({ commit }, modeloId) {
            await modelosService.deleteModelo(modeloId);
        },
        async getModelo({ commit }, modeloId) {
            const modelo = await modelosService.getModelo(modeloId);
            commit('getModelosuccess', modelo);
        },
        async editModelo({ commit }, modifiedmodelo) {
            await modelosService.editModelo(modifiedmodelo); 
        },
    },
    mutations: {
        getModelosSuccess(state, modelos) {
            state.modelos = modelos;
        },
        successUpload(state, picURL){
            state.modelo.profile_image = picURL;
        },
        getModeloSuccess(state,modelo){
            state.modelo = modelo;
        }
    },
    getters: {
        getModelosData(state) {
            return state.modelos?.data;
        },
        getModelosMeta(state) {
            return state.modelos?.meta;
        },
        getModelo(state){
            return state.modelo
        },
        getImage(state){
            return state.modelo.profile_image
        },
    }
}

