import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getPrevActivities(params) {
        const response = await axios.get(API_URL + "/prevMaintenaceActivity", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },
    async getPrevActivity(activityId) {
        const response = await axios.get(API_URL + "/prevMaintenaceActivity/" + activityId, { headers: authHeader() });
        console.log(response.data);
        return response.data;
    },
    async addPrevActivity(activity) {
        const response = await axios.post(API_URL + "/prevMaintenaceActivity", activity, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async editPrevActivity(activity) {
        const response = await axios.put(API_URL + "/prevMaintenaceActivity/" + activity.iIdActividad, activity, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async deletePrevActivity(id) {
        await axios.delete(API_URL + "/prevMaintenaceActivity/" + id, { headers: authHeader() });
    },
}