<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <!--form panels-->
                                <div class="row">
                                    <div class="col-12 col-lg-10 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div
                                                    class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">event</i>
                                                </div>
                                                <h6 class="mb-0">Registro de incidentes</h6>
                                            </div>
                                            <div class="card-body">
                                                <form class="multisteps-form__form">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-4">
                                                            <h6 class="mb-0 text-sm">Selecionar unidad</h6>
                                                            <div class="input-group input-group-outline bg-white">
                                                                <select v-model="iIdUnidad" class="form-control"
                                                                    name="iIdUnidad" placeholder="Selecciona"
                                                                    @change="onChangeUnidad($event)">
                                                                    <option selected value="">Seleccione una unidad
                                                                    </option>
                                                                    <option v-for="item in unidades"
                                                                        :key="item.iIdUnidad"
                                                                        :value="item.iIdUnidad">
                                                                        {{ item.vPlaca }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Placa</h6>
                                                            <p class="text-sm text-secondary mb-0">{{ unidad.vPlaca }}
                                                            </p>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Num Econónico</h6>
                                                            <p class="text-sm text-secondary mb-0">{{
                                                                    unidad.vNumEconomico }}</p>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Marca</h6>
                                                            <p class="text-sm text-secondary mb-0">{{ unidad.NombreMarca
                                                                }}</p>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Modelo</h6>
                                                            <p class="text-sm text-secondary mb-0">{{
                                                                    unidad.NombreModelo }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Año</h6>
                                                            <p class="text-sm text-secondary mb-0">{{
                                                                    unidad.iAnioFabricacion }}</p>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Serie</h6>
                                                            <p class="text-sm text-secondary mb-0">{{ unidad.vSerie }}
                                                            </p>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Odómetro</h6>
                                                            <p class="text-sm text-secondary mb-0">{{ unidad.iKmActual
                                                                }}</p>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-4">
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Fecha Ingreso a taller</h6>
                                                            <div
                                                                class="input-group input-group-dynamic null is-focused">
                                                                <material-input id="dFechaIngreso"
                                                                    v-model:value="incident.dFechaIngreso"
                                                                    variant="dynamic" name="dFechaIngreso"
                                                                    type="date"></material-input>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Fecha programada de salida</h6>
                                                            <div
                                                                class="input-group input-group-dynamic null is-focused">
                                                                <material-input id="dFechaEstimada"
                                                                    v-model:value="incident.dFechaEstimada"
                                                                    variant="dynamic" name="dFechaEstimada"
                                                                    type="date"></material-input>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Fecha real de salida de taller</h6>
                                                            <div
                                                                class="input-group input-group-dynamic null is-focused">
                                                                <material-input id="dFechaEgreso"
                                                                    v-model:value="incident.dFechaEgreso"
                                                                    variant="dynamic" name="dFechaEgreso"
                                                                    type="date"></material-input>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Lugar</h6>
                                                            <material-input id="vcomentarios"
                                                                v-model:value="incident.vcomentarios" variant="dynamic"
                                                                name="vcomentarios"></material-input>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-4">
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Estatus</h6>
                                                            <p class="text-sm text-secondary mb-0"><span
                                                                    class="badge badge-md badge-info">En Proceso</span>
                                                            </p>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Costo Total</h6>
                                                            <material-input id="fCosto" v-model:value="incident.fCosto"
                                                                variant="dynamic" name="fCosto"
                                                                type="number"></material-input>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <material-button v-show="!incident.vFactura" size="sm" type="button">
                                                                <label for="fileInput" class="mb-0 text-white small cursor-pointer">Agregar Factura</label>
                                                                <input id="fileInput" type="file" style="display: none;" @change.prevent="uploadInvoice">
                                                            </material-button>
                                                            <div v-show="incident.vFactura">
                                                                <material-button class="btn btn-icon btn-2 btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                    <span class="btn-inner--icon"><i class="material-icons">description</i></span>
                                                                </material-button>
                                                                <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="deleteInvoice">
                                                                    <label class="mb-0 text-white small cursor-pointer">&#10005; Eliminar</label>
                                                                </material-button>
                                                                <material-button size="sm" type="button">
                                                                    <label for="fileInput" class="mb-0 text-white small cursor-pointer">Cambiar</label>
                                                                    <input id="fileInput" type="file" style="display: none;" @change.prevent="uploadInvoice">
                                                                </material-button>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Tipo de Incidente</h6>
                                                            <p class="text-sm text-secondary mb-0">Colision</p>
                                                        </div> -->
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Fecha y Hora del incidente</h6>
                                                            <div
                                                                class="input-group input-group-dynamic null is-focused">
                                                                <material-input id="oIncidencia.dFecha"
                                                                    v-model:value="incident.oIncidencia.dFecha"
                                                                    variant="dynamic" name="oIncidencia.dFecha"
                                                                    type="date"></material-input>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Lugar del incidente</h6>
                                                            <material-input id="oIncidencia.vLugar"
                                                                v-model:value="incident.oIncidencia.vLugar"
                                                                variant="dynamic"
                                                                name="oIncidencia.vLugar"></material-input>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">víctimas</h6>
                                                            <material-input id="oIncidencia.iVictimas"
                                                                v-model:value="incident.oIncidencia.iVictimas"
                                                                variant="dynamic" name="oIncidencia.iVictimas"
                                                                type="number"></material-input>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Lesionados</h6>
                                                            <material-input id="oIncidencia.iLesionados"
                                                                v-model:value="incident.oIncidencia.iLesionados"
                                                                variant="dynamic" name="oIncidencia.iLesionados"
                                                                type="number"></material-input>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-4">
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">Responsable</h6>
                                                            <material-input id="oIncidencia.vResponsable"
                                                                v-model:value="incident.oIncidencia.vResponsable"
                                                                variant="dynamic"
                                                                name="oIncidencia.vResponsable"></material-input>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <h6 class="mb-0 text-sm">El conductor fue el responsable
                                                            </h6>
                                                            <material-input id="oIncidencia.vConductor"
                                                                v-model:value="incident.oIncidencia.vConductor"
                                                                variant="dynamic"
                                                                name="oIncidencia.vConductor"></material-input>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <h6 class="mb-0 text-sm">Fotografia del incidente</h6>
                                                            <p class="text-sm text-secondary mb-0">
                                                                <img :src="incident.oIncidencia.vEvidencia"
                                                                    class="image img-responsive"
                                                                    style="width: 100%; border-radius: 5px;">
                                                            </p>
                                                            <material-button v-show="!incident.oIncidencia.vEvidencia" size="sm" type="button">
                                                                <label for="fileInput2" class="mb-0 text-white small cursor-pointer">Agregar Evidencia</label>
                                                                <input id="fileInput2" type="file" style="display: none;" @change.prevent="uploadEvidenciaIncident" >
                                                            </material-button>
                                                            <div v-show="incident.oIncidencia.vEvidencia">
                                                                <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="deleteEvidenceIncident">
                                                                    <label class="mb-0 text-white small cursor-pointer">&#10005; Eliminar</label>
                                                                </material-button>
                                                                <material-button size="sm" type="button">
                                                                    <label for="fileInput2" class="mb-0 text-white small cursor-pointer">Cambiar</label>
                                                                    <input id="fileInput2" type="file" style="display: none;" @change.prevent="uploadEvidenciaIncident">
                                                                </material-button>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12">
                                                            <hr class="hr">
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="row mt-4">
                                                            <div class="col-12 mt-3">
                                                                <div class="d-flex flex-row justify-content-between">
                                                                    <h6 class="mb-0">Detalle del Mantenimiento</h6>
                                                                    <material-button type="button" class="float-right btn btm-sm" data-bs-toggle="modal" data-bs-target="#modalActivity">Agregar Actividad</material-button>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-3">
                                                                <div class="table-responsive">
                                                                    <table class="table align-items-center mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="title-column ps-2 text-center">Actividad</th>
                                                                                <th class="title-column ps-2 text-center">Duración estimada</th>
                                                                                <th class="title-column ps-2 text-end">Costo mano de obra</th>
                                                                                <th class="title-column ps-2 text-end">Costo refacciones</th>
                                                                                <th class="title-column ps-2 text-end">Costo otros</th>
                                                                                <th class="title-column ps-2 text-end">Costo total</th>
                                                                                <th class="title-column ps-2 text-center">Evidencia</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(item, index) in incident.lDetalle" :key="item.iIdDetallePlanMantenimientoPrev">
                                                                                <td class="text-start"><p class="table-p mb-0">{{ item.vNombreActividad }}</p></td>
                                                                                <td class="text-center"><p class="table-p mb-0">{{ item.iDuracionHorasTotal }}</p></td>
                                                                                <td class="text-end"><p class="table-p mb-0">$ {{ formatPrice(item.fCostoManoObra) }}</p></td>
                                                                                <td class="text-end"><p class="table-p mb-0">$ {{ formatPrice(item.fCostoRefacciones) }}</p></td>
                                                                                <td class="text-end"><p class="table-p mb-0">$ {{ formatPrice(item.fCostoOtro) }}</p></td>
                                                                                <td class="text-end"><p class="table-p mb-0">$ {{ formatPrice(item.fCostoTotal) }}</p></td>
                                                                                <td class="text-center">
                                                                                    <div class="mt-1 mb-2 text-cente">
                                                                                        <material-button v-show="item.vEvidencia == ''" size="sm" type="button">
                                                                                            <label :for="`imageInput_${index}`" class="mb-0 text-white small">Select Image</label>
                                                                                            <input :id="`imageInput_${index}`" :data-type="`${index}`" type="file" style="display: none;" accept="image/*" @change.prevent="uploadEvidenciaDetalle">
                                                                                        </material-button>
                                                                                        <div v-show="item.vEvidencia != ''">
                                                                                            <material-button class="btn btn-icon btn-2 btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#modalImage" @click.prevent="onViewImage(item.vEvidencia)">
                                                                                                <span class="btn-inner--icon"><i class="material-icons">description</i></span>
                                                                                            </material-button>
                                                                                            <!-- <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="deleteEvidenciaDetalle(index)">
                                                                                                <label class="mb-0 text-white small">&#10005; Eliminar</label>
                                                                                            </material-button>
                                                                                            <material-button size="sm" type="button">
                                                                                                <label :for="`imageInput_${index}`" class="mb-0 text-white small">Cambiar</label>
                                                                                                <input :id="`imageInput_${index}`" :data-type="`${index}`" type="file" style="display: none;" accept="image/*" @change.prevent="uploadEvidenciaDetalle">
                                                                                            </material-button> -->
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-md-12 d-flex justify-content-end mt-3">
                                                            <material-button
                                                                class="float-right btn btm-sm btn-danger me-3"
                                                                @click="this.$router.push({ name: 'Companies List' })">Cancelar</material-button>
                                                            <material-button class="float-right btn btm-sm"
                                                                @click.prevent="handleAdd">Guardar</material-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title font-weight-normal">Factura del Mantenimiento</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe :src="incident.vFactura" style="width:100%; height:700px;" frameborder="0"></iframe>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalImage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title font-weight-normal">Evidencia</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img :src="image" style="width:100%; object-fit: contain;">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalActivity" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalActivityLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="modalActivityLabel" class="modal-title font-weight-normal">Agregar Actividad</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mt-4">
                        <div class="col-12 my-3 ps-4">
                            <div class="form-check form-switch">
                                <input id="flexSwitchCheckDefault" v-model="selectActivity" class="form-check-input" type="checkbox" checked="true" >
                                <label class="form-check-label" for="flexSwitchCheckDefault">Seleccionar Actividad Existente</label>
                            </div>
                        </div>
                        <template v-if="selectActivity">
                            <div class="col-12 mb-3">
                                <div class="input-group input-group-dynamic">
                                    <select v-model="iIdActividad" class="form-control" name="iIdActividad" placeholder="Selecciona" @change="onChangeActividad()">
                                        <option selected value="">Selecciona Actividad</option>
                                        <option v-for="item in actividades" :key="item.iIdActividad" :value="item.iIdActividad">
                                            {{ item.vDescripcion }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-12">
                                <div class="row my-3">
                                    <div class="col-12 col-md-6">
                                        <material-input id="vNombreActividad" v-model:value="detalle.vNombreActividad" label="Nombre actividad" variant="dynamic" name="vNombreActividad"></material-input>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <material-input id="iDuracionHorasTotal" v-model:value="detalle.iDuracionHorasTotal" label="Duración (hrs)" variant="dynamic" name="iDuracionHorasTotal"></material-input>
                                    </div>
                                </div>
                                <div class="row my-3">
                                    <div class="col-12 col-md-6">
                                        <material-input id="fCostoManoObra" v-model:value="detalle.fCostoManoObra" label="Mano de obra" variant="dynamic" name="fCostoManoObra"></material-input>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <material-input id="fCostoRefacciones" v-model:value="detalle.fCostoRefacciones" label="Refacciones" variant="dynamic" name="fCostoRefacciones"></material-input>
                                    </div>
                                </div>
                                <div class="row my-3">
                                    <div class="col-12 col-md-6">
                                        <material-input id="fCostoOtro" v-model:value="detalle.fCostoOtro" label="Otros costos" variant="dynamic" name="fCostoOtro"></material-input>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <material-input id="fCostoTotal" v-model:value="detalle.fCostoTotal" label="Total" variant="dynamic" name="fCostoTotal"></material-input>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="col-12 my-3 text-center">
                            <material-input id="vComentarios" v-model:value="detalle.vComentarios" label="Comentarios" variant="dynamic" name="vComentarios"></material-input>
                        </div>
                        <div class="col-8 mx-auto my-3 ps-4">
                            <div class="mt-1 mb-2">
                                <material-button v-if="detalle.vEvidencia == ''" size="sm" type="button">
                                    <label for="evidence" class="mb-0 text-white small cursor-pointer">Agregar Evidencia</label>
                                    <input id="evidence" type="file" style="display: none;" @change.prevent="uploadEvidenciaModal">
                                </material-button>
                                <div v-else>
                                    <img :src="detalle.vEvidencia" style="width:100%; object-fit: contain;">
                                    <div class="row">
                                        <div class="col-md-12 d-flex justify-content-center mt-3">
                                            <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="deleteEvidenciaModal">
                                                <label class="mb-0 text-white small cursor-pointer">&#10005; Eliminar</label>
                                            </material-button>
                                            <material-button size="sm" type="button">
                                                <label for="fileInput" class="mb-0 text-white small cursor-pointer">Cambiar</label>
                                                <input id="fileInput" type="file" style="display: none;" @change.prevent="uploadEvidenciaModal">
                                            </material-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <material-button class="float-right btn btm-sm bg-gradient-secondary" data-bs-dismiss="modal">Close</material-button>
                    <material-button class="float-right btn btm-sm" @click.prevent="addDetail()">Agregar</material-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
//import ValidationError from "@/components/ValidationError.vue";
// import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import { Modal } from 'bootstrap';

import axios from 'axios';
import authHeader from '../../../services/auth-header';
const API_URL = process.env.VUE_APP_API_BASE_URL;
const FILE_URL = process.env.VUE_APP_FILE_BASE_URL;

export default {
    name: 'AddPlan',
    components: {
        MaterialButton,
        MaterialInput,
        //ValidationError,
    },
    data() {
        return {
            detalle: {
                iIdMantenimiento: null,
                vNombreActividad: "",
                iDuracionHorasTotal: null,
                fCostoManoObra: null,
                fCostoRefacciones: null,
                fCostoOtro: null,
                fCostoTotal: null,
                vEvidencia: "",
                iEstatus: 1,
                bActivo: true,
                selected: false,
                iIdActividad: null
            },
            incident: {
                iIdUnidad: null,
                dFechaIngreso: null,
                dFechaEgreso: null,
                dFechaEstimada: null,
                iOdometro: 0,
                vComentarios: "",
                fCosto: "",
                vFactura: "",
                vUbcacion: "",
                oIncidencia: {
                    vEvidencia: null
                },
                lDetalle: []
            },
            unidades: [],
            unidad: {},
            selectActivity: false,
            file: null,
            evidence: null,
            image: null,
            addModal: null,
            iIdActividad: null,
            errors: {}
        }
    },
    // mixins: [], 
    async mounted() {
        this.getUnidad();
        this.getMarcas();
        this.getModelos(0);
        this.getActividades();

        this.addModal = new Modal(document.getElementById('modalActivity'));
        //this.incident.oIncidencia.vEvidencia = require("@/assets/img/placeholder.jpg");
    },
    methods: {
        getMarcas() {
            axios.get(API_URL + "/unidad/obtener-marcas").then(res => {
                this.marcas = res.data;
            });
        },
        getUnidad() {
            axios.get(API_URL + "/unidades/all", { headers: authHeader() }).then(res => {
                this.unidades = res.data;
            });
        },
        getUnidadById(id) {
            axios.get(API_URL + "/unidades/" + id).then(res => {
                this.unidad = res.data;
                this.incident.iIdUnidad = this.unidad.iIdUnidad;
            });
        },
        onChangeUnidad(event) {
            this.getUnidadById(event.target.value);
        },
        getActividades() {
            this.actividades = [];

			axios.get(API_URL + "/plans/actividades").then(res => {
                this.actividades = res.data;
			});
		},
        getModelos(idMarca) {
            this.modelos = [];

            axios.get(API_URL + "/modelos/marca/" + idMarca).then(res => {
                this.modelos = res.data;
            });

        },
        onChangeMarca(event) {
            this.getModelos(event.target.value);
        },
        onChangeModelo(event) {
            console.log(event);
            //this.maintenancePlan.iIdModelo = event.target.value;
        },
        onChangeActividad() {
            let actividad = this.actividades.find(x => x.iIdActividad == this.iIdActividad);
            if(actividad != undefined && actividad != null) {
                this.detalle.iIdMantenimiento    = null;
                this.detalle.vNombreActividad    = actividad.vDescripcion;
                this.detalle.iDuracionHorasTotal = actividad.iDuracionHorasTotal ?? 0;
                this.detalle.fCostoManoObra      = actividad.fCostoManoObra;
                this.detalle.fCostoRefacciones   = actividad.fCostoRefacciones;
                this.detalle.fCostoOtro          = actividad.fCostoOtro;
                this.detalle.fCostoTotal         = actividad.fCostoTotal;
                this.detalle.selected            = false;
                this.detalle.bActivo             = true;
                this.detalle.vEvidencia          = "";
                this.detalle.iEstatus            = 1;
                this.detalle.iIdActividad        = actividad.iIdActividad;
            }
		},
        async uploadInvoice(event) {
            this.file = event.target.files[0];
            this.incident.vFactura = URL.createObjectURL(this.file);

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.file);
            this.incident.vFactura = FILE_URL + rutaFile;
        },
        async uploadEvidenciaIncident(event) {
            this.file = event.target.files[0];
            this.incident.oIncidencia.vEvidencia = URL.createObjectURL(this.file);

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.file);
            this.incident.oIncidencia.vEvidencia = FILE_URL + rutaFile;
        },
        async uploadEvidenciaModal(event) {
            let evidence = event.target.files[0];
            this.detalle.vEvidencia = URL.createObjectURL(evidence);

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", evidence);
            this.detalle.vEvidencia = FILE_URL + rutaFile;
        },
        async uploadEvidenciaDetalle(event) {
            const index = event.target.getAttribute("data-type");
            this.incident.lDetalle[index].fFile = event.target.files[0];

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.incident.lDetalle[index].fFile);
            this.incident.lDetalle[index].vEvidencia = FILE_URL + rutaFile
        },
        deleteInvoice() {
            this.file = null
            this.incident.vFactura = null;
        },
        deleteEvidenceIncident() {
            this.file = null
            this.incident.oIncidencia.vEvidencia = require("@/assets/img/placeholder.jpg");
        },
        deleteEvidenciaModal() {
            this.file = null
            this.detalle.vEvidencia = null;
        },        
        deleteEvidenciaDetalle(index) {
            this.incident.lDetalle[index].fFile = null
            this.incident.lDetalle[index].vEvidencia = require("@/assets/img/placeholder.jpg");
        },
        addDetail() {
            if(this.detalle.fCostoTotal > 0) {
                this.incident.fCosto += this.detalle.fCostoTotal;
            }

            this.incident.lDetalle.push(this.detalle);
            this.detalle = {
                iIdMantenimiento: null,
                vNombreActividad: "",
                iDuracionHorasTotal: null,
                fCostoManoObra: null,
                fCostoRefacciones: null,
                fCostoOtro: null,
                fCostoTotal: null,
                vEvidencia: "",
                iEstatus: 1,
                bActivo: true,
                selected: false
            };


            this.addModal.hide();
            document.querySelector('.modal-backdrop').remove();
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        onViewImage(path) {
            this.image = path;
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        async handleAdd() {
            this.resetApiValidation();
            try {
                //await this.$store.dispatch("incidents", this.incident);
                axios.post(API_URL + "/incidents", this.incident).then(res => {
                    console.log("save incident")
                    console.log(res.data)
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Registro exitoso!",
                        width: 500
                    });
                    this.$router.push({ name: "Incident" })
                });

            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Ocurrió un error, contacte con el administrador.",
                    width: 500
                });
            }
        }
    }
}
</script>
<style>
.table thead th {
    padding: 0.75rem 12px !important;
}

.input-group-outline .choices__inner {
    border: 1px solid #d2d6da !important;
}
</style>