<template>
    <section class="container py-4">
        <div class="row m-2 mx-sm-0">
            <div class="card col-12 p-0">
                <div class="card-header border-bottom">
                    <div class="d-flex flex-column flex-sm-row gap-3 justify-content-sm-between align-items-sm-center">
                        <h5 class="mb-0">Bitácora Inicial</h5>
                        <material-button @click="this.$router.push({ name: 'AddBitacoraInicial' })">Agregar Registro</material-button>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <div class="row">
                        <div class="co-12 col-md-6 col-lg-4">
                            <material-input v-model:value="search" variant="static" placeholder="Buscar..." />
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table ref="table" class="table">
                                    <thead class="text-bold">
                                        <tr>
                                            <th data-type="string">NÚMERO ECONÓMICO</th>
                                            <th data-type="number">KM</th>
                                            <th data-type="string">MATRICULA</th>
                                            <th data-type="string">OBRA</th>
                                            <th data-type="string">OPERARIO</th>
                                            <th data-type="date" data-format="DD/MM/YYYY">FECHA</th>
                                            <th data-type="string">ESTATUS</th>
                                            <th>ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-sm"></tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex justify-content-end">
                                <!-- <BasePagination
                                    v-model="pagination.currentPage"
                                    class="pagination-success pagination-md m-0 gap-2"
                                    :value="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total="pagination.total"
                                    @click="getDataFromPage($event)"
                                /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { DataTable } from 'simple-datatables';
import MaterialButton from '@/components/MaterialButton.vue';
// import MaterialInput from '@/components/MaterialInput.vue';
import eventTable from '@/mixins/eventTable.js';

export default {
    name: 'BitacoraInicialList',
    components: {
        MaterialButton,
        // MaterialInput,
    },
    mixins: [eventTable],
    data() {
        return {
            table: null,
            search: '',
        };
    },
    computed: {
        bitacoras() {
            return this.$store.getters['bitacoraInicial/getBitacorasInicial'];
        },
    },
    watch: {
        bitacoras: {
            handler: 'reactiveTable',
            immediate: false,
            deep: true,
        },
    },
    mounted() {
        this.fetchBitacoras();

        if (this.$refs.table) {
            this.table = new DataTable(this.$refs.table, {
                labels: {
                    placeholder: 'Buscar...',
                    pageTitle: 'Página {page}',
                    noRows: 'Sin registros',
                    info: 'Mostrando {start} de {end} de {rows} registros',
                    noResults: 'Sin resultados en la búsqueda',
                },
                locale: 'es-MX',
            });
            this.table.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
            document.querySelector('.dataTable-dropdown > label').childNodes[1].textContent = ' Registros por página';
        }
    },
    methods: {
        fetchBitacoras() {
            this.$store.dispatch('bitacoraInicial/getBitacorasInicial');
        },
        reactiveTable() {
            const data = [];

            for (const bitacora of this.bitacoras) {
                let estatus = true;

                for (const value of Object.values(bitacora)) {
                    if (typeof value === 'boolean' && !value) {
                        estatus = false;
                        break;
                    }
                }

                data.push([
                    bitacora.unidad.vNumEconomico,
                    bitacora.nKm,
                    bitacora.vMatricula,
                    bitacora.vObra,
                    bitacora.vOperario,
                    bitacora.dFecha,
                    `<span class="badge badge-md badge-${estatus ? 'success' : 'danger'}">${estatus ? 'Correcto' : 'Incorrecto'}</span>`,
                    `<span>
                        ${this.actionPrintButton(`bitacoras/inicial/pdf/${bitacora.iIdBitacoraInicial}`, 'Imprimir Bitácora') +
                        this.actionEditButton(bitacora.iIdBitacoraInicial, 'Editar Bitácora') +
                        this.actionDeleteButton(bitacora.iIdBitacoraInicial, 'Eliminar Bitácora')}
                    </span>`,
                ]);
            }

            this.table.data = [];
            this.table.refresh();
            this.table.insert({ data });

            this.removeEvent();
            this.eventToCall({
                table: this.table,
                redirectPath: 'EditBitacoraInicial',
                deletePath: 'bitacoraInicial/deleteBitacoraInicial',
                getPath: 'bitacoraInicial/getBitacorasInicial',
                textDelete: 'Bitácora inicial eliminada.',
                textDefaultData: 'Bitácoras iniciales',
            });
        },
    },
};
</script>
