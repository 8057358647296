import inspectionsService from '@/services/inspections.service';

const initialState = {
    unidades: [],
    unidad: null,
    inspecciones: [],
    inspeccion: null,
    message: '',
};

export const inspections = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUnidades({ commit }) {
            const unidades = await inspectionsService.getUnidades();
            commit('getUnidades', unidades);
        },
        async getUnidad({ commit }, id) {
            const unidad = await inspectionsService.getUnidad(id);
            commit('getUnidad', unidad);
        },
        async getInspecciones({ commit }) {
            const inspecciones = await inspectionsService.getInspecciones();
            commit('getInspecciones', inspecciones);
        },
        async createInspeccion({ commit }, inspeccion) {
            const message = await inspectionsService.createInspeccion(inspeccion);
            commit('createInspeccion', message);
        },
        async getInspeccion({ commit }, id) {
            const inspeccion = await inspectionsService.getInspeccion(id);
            commit('getInspeccion', inspeccion);
        },
        async updateInspeccion({ commit }, inspeccion) {
            const message = await inspectionsService.updateInspeccion(inspeccion);
            commit('updateInspeccion', message);
        },
        async deleteInspeccion({ commit }, id) {
            const message = await inspectionsService.deleteInspeccion(id);
            commit('deleteInspeccion', message);
        },
    },
    mutations: {
        getUnidades(state, unidades) {
            state.unidades = unidades;
        },
        getUnidad(state, unidad) {
            state.unidad = unidad;
        },
        getInspecciones(state, inspecciones) {
            state.inspecciones = inspecciones;
        },
        createInspeccion(state, message) {
            state.message = message;
        },
        getInspeccion(state, inspeccion) {
            state.inspeccion = inspeccion;
        },
        updateInspeccion(state, message) {
            state.message = message;
        },
        deleteInspeccion(state, message) {
            state.message = message;
        },
    },
    getters: {
        getUnidades(state) {
            return state.unidades;
        },
        getUnidad(state) {
            return state.unidad;
        },
        getInspecciones(state) {
            return state.inspecciones;
        },
        createInspeccion(state) {
            return state.message;
        },
        getInspeccion(state) {
            return state.inspeccion;
        },
        updateInspeccion(state) {
            return state.message;
        },
        deleteInspeccion(state) {
            return state.message;
        },
    },
};
