import { Loader } from "@googlemaps/js-api-loader"
let map;
const APY_KEY = "AIzaSyCzd04rijApv9mCEvIfukQ6Ulji37O5qHU";
const loader = new Loader({
  apiKey: APY_KEY,
  version: "weekly"
});
//const libraries = "places,geometry";

export const InitMap = (element) => {
  

  loader.load().then(async () => {
    const { Map } = await window.google.maps.importLibrary("maps");

    map = new Map(element, {
      center: { lat: 41.876, lng: -87.624 },
      zoom: 8,
    });
  });
  return map
};

export const CreateKmlLayer = (kml) => {
  loader.load().then(async () => {
    const { KmlLayer } = await window.google.maps.importLibrary("maps");
    new KmlLayer({
        url: kml, //"https://googlearchive.github.io/js-v2-samples/ggeoxml/cta.kml",
        map: map,
    });
  });
}
