<template>
    <div class="py-4 h-100 container-fluid">
      <div class="row">
        <div class="text-center col-12">
          <h3 class="mt-5">Editar Unidad</h3>
          <div class="mb-5 multisteps-form">
            <!--progress bar -->
            <div class="row">
              <div class="mx-auto my-5 col-12 col-lg-10">
                <div class="card">
                  <div
                    class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                  >
                    <div
                      class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                    >
                      <div class="multisteps-form__progress">
                        <button
                          class="multisteps-form__progress-btn js-active"
                          type="button"
                          title="Datos Generales"
                          :class="activeStep >= 0 ? activeClass : ''"
                        >
                          <span>Datos Generales</span>
                        </button>
                        <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="Poliza de Seguro"
                          :class="activeStep >= 1 ? activeClass : ''"
                        >
                          <span>Poliza de Seguro</span>
                        </button>
                        <button
                          class="multisteps-form__progress-btn"
                          type="button"
                          title="Galeria de Imagenes"
                          :class="activeStep >= 2 ? activeClass : ''"
                          @click="activeStep = 2"
                        >
                          <span>Galeria de Imagenes</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <form class="multisteps-form__form">
                      <!--single form panel-->
                      <general-data v-if="activeStep === 0" />
                      <!--single form panel-->
                      <insurance-policy :class="activeStep === 1 ? activeClass : ''" />
                      <!--single form panel-->
                      <galery-images :class="activeStep === 2 ? activeClass : ''" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  //COMPONENTS
  import generalData from "./components/GeneralData.vue";
  import insurancePolicy from "./components/InsurancePolicy.vue";
  import galeryImages from "./components/GaleryImages.vue";
  
  export default {
    name: "EditUnit",
    components: { generalData, insurancePolicy, galeryImages },
    data() {
      return {
        activeClass: "js-active position-relative",
        activeStep: 0,
        formSteps: 2,
      };
    },
    methods: {
      nextStep() {
        if (this.activeStep < this.formSteps) {
          this.activeStep += 1;
        } else {
          this.activeStep -= 1;
        }
      },
      prevStep() {
        if (this.activeStep > 0) {
          this.activeStep -= 1;
        }
      },
    },
  };
  </script>
  