<template>
	<div class="pt-3 bg-white multisteps-form__panel js-active position-relative" data-animation="FadeIn">
		<h5 class="font-weight-bolder mb-0">Datos Generales</h5>
		<p class="mb-0 text-sm">Campos obligatorios *</p>
		<div class="multisteps-form__content">
			<div class="row mt-3">
				<div v-if="showEmpresa" class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">
						<select id="iIdEmpresa" class="multisteps-form__select form-control" name="iIdEmpresa">
							<option value="" selected disabled>Seleccionar Empresa</option>
						</select>
					</div>
					<validation-error v-if="errors && errors.iIdEmpresa" class="text-danger"> <small>{{ errors.iIdEmpresa[0]
					}}</small> </validation-error>
				</div>
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">
						<select id="iIdRuta" class="form-control" name="iIdRuta" @change="onChangeRoute($event)">
							<option value="" selected disabled>Seleccionar Ruta</option>
						</select>
					</div>
					<validation-error v-if="errors && errors.iIdRuta" class="text-danger"> <small>{{ errors.iIdRuta[0]
					}}</small> </validation-error>
				</div>
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic null is-focused">
						<material-input id="vNombreTipoRuta" v-model:value="tipoRuta.vDescripcion" variant="dynamic" label="Tipo Ruta" name="vDescripcion" disabled />
					</div>
				</div>
			</div>
			<div class="mt-3 row">
				<div class="col-12 col-sm-4">
					<material-input id="vSerie" v-model:value="generalData.vSerie" variant="dynamic" label="Número Serie" name="vSerie" />
					<validation-error v-if="errors && errors.vSerie" class="text-danger"> <small>{{ errors.vSerie[0]
					}}</small> </validation-error>
				</div>
				<div class="col-12 col-sm-4">
					<material-input id="vNumMotor" v-model:value="generalData.vNumMotor" variant="dynamic" label="Número Motor" />
					<validation-error v-if="errors && errors.vNumMotor" class="text-danger"> <small>{{ errors.vNumMotor[0]
					}}</small> </validation-error>
				</div>
				<div class="col-12 col-sm-4">
					<material-input id="vChasis" v-model:value="generalData.vChasis" variant="dynamic" label="Número Chasis" name="vChasis" />
					<validation-error v-if="errors && errors.vChasis" class="text-danger"> <small>{{ errors.vChasis[0]
					}}</small> </validation-error>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">{{ generalData.iAnioFabricacion }}
						<select id="choices-age" v-model="generalData.iAnioFabricacion" class="form-control" name="choices-age">
							<option value="" disabled>Seleccionar Año Motor</option>
							<option value="2023">2023</option>
							<option value="2024">2024</option>
							<option value="2025">2025</option>
							<option value="2026">2026</option>
							<option value="2027">2027</option>
							<option value="2028">2028</option>
							<option value="2029">2029</option>
							<option value="2030">2030</option>
						</select>
					</div>
					<validation-error v-if="errors && errors.iAnioFabricacion" class="text-danger"> <small>{{ errors.iAnioFabricacion[0] }}</small> </validation-error>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">
						<select id="iIdMarca" class="form-control" name="iIdMarca" @change="onChangeMarca($event)">
							<option value="" selected disabled>Seleccionar Marca</option>
						</select>
					</div>
				</div>
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">
						<select id="iIdModelo" class="form-control" name="iIdModelo" @change="onChangeModelo($event)">
							<option value="" selected disabled>Seleccionar Modelo</option>
						</select>
					</div>
					<validation-error v-if="errors && errors.iIdModelo" class="text-danger"> <small>{{ errors.iIdModelo[0]
					}}</small> </validation-error>
				</div>
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic null is-focused">
						<material-input id="iIdTipoVehiculo" v-model:value="modeloDetail.vNombreTipoVehiculo" variant="dynamic" label="Tipo de Vehiculo" name="iIdTipoVehiculo" disabled />
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic null is-focused">
						<material-input id="iIdTipoCombustible" v-model:value="modeloDetail.vNombreTipoCombustible" variant="dynamic" label="Combustible" name="iIdTipoCombustible" disabled />
					</div>
				</div>
				<div class="col-12 col-sm-2">
					<div class="input-group input-group-dynamic null is-focused">
						<material-input id="iCapacidadPasajerosSentados"
							v-model:value="modeloDetail.iCapacidadPasajerosSentados" label="Pasajeros sentados" variant="dynamic" name="iCapacidadPasajerosSentados" disabled />
					</div>
				</div>
				<div class="col-12 col-sm-2">
					<div class="input-group input-group-dynamic null is-focused">
						<material-input id="iCapacidadPasajerosPie" v-model:value="modeloDetail.iCapacidadPasajerosPie" label="Pasajeros de pie" variant="dynamic" name="iCapacidadPasajerosPie" disabled />
					</div>
				</div>
				<div class="col-12 col-sm-3">
					<div class="input-group input-group-dynamic null is-focused">
						<material-input id="iCapacidadTotal" v-model:value="modeloDetail.iCapacidadTotal" label="Capacidad de pasajeros" variant="dynamic" name="iCapacidadTotal" disabled />
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">
						<div class="input-group input-group-dynamic null is-focused">
							<material-input id="nLargo" v-model:value="modeloDetail.nLargo" label="Largo" variant="dynamic" name="nLargo" disabled />
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">
						<div class="input-group input-group-dynamic null is-focused">
							<material-input id="nAncho" v-model:value="modeloDetail.nAncho" label="Ancho" variant="dynamic" name="nAncho" disabled />
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">
						<div class="input-group input-group-dynamic null is-focused">
							<material-input id="iNumeroEjes" v-model:value="modeloDetail.iNumeroEjes" label="Ejes" variant="dynamic" name="iNumeroEjes" disabled />
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-5">
				<div class="col-12 col-sm-4">
					<div class="input-group input-group-dynamic">
						<div class="input-group input-group-dynamic null is-focused">
							<material-input id="nPesoVehicular" v-model:value="modeloDetail.nPesoVehicular" label="Peso Vehicular" variant="dynamic" name="nPesoVehicular" disabled />
						</div>
					</div>
				</div>
			</div>
			<div class="mt-3 row">
				<div class="col-12 col-sm-4">
					<material-input id="vPlaca" v-model:value="generalData.vPlaca" variant="dynamic" label="Placas" name="vPlaca" />
					<validation-error v-if="errors && errors.vPlaca" class="text-danger"> <small>{{ errors.vPlaca[0] }}</small> </validation-error>
				</div>
				<div class="col-12 col-sm-4">
					<material-input id="nKm" v-model:value="generalData.nKm" variant="dynamic" label="Kilometraje recorrido" name="nKm" />
					<validation-error v-if="errors && errors.nKm" class="text-danger"> <small>{{ errors.nKm[0] }}</small>
					</validation-error>
				</div>
				<div class="col-12 col-sm-4">
					<material-input id="vNumeroEconomico" v-model:value="generalData.vNumeroEconomico" variant="dynamic" label="Número Economico" name="vNumeroEconomico" />
					<validation-error v-if="errors && errors.vNumeroEconomico" class="text-danger"> <small>{{ errors.vNumeroEconomico[0] }}</small> </validation-error>
				</div>
			</div>
			<div class="mt-4 button-row d-flex">
				<material-button type="button" color="dark" variant="gradient" class="ms-auto mb-0 js-btn-next" @click="dataAdd">Guardar</material-button>
			</div>
		</div>
	</div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
// import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../../mixins/showSwal";
import Choices from "choices.js";

import axios from 'axios';
import authHeader from '../../../../services/auth-header'
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
	name: 'GeneralDataAdd',
	components: {
		MaterialInput,
		MaterialButton
	},
	data() {
		return {
			companies: [],
			companiesChoises: null,
			anios: [],
			aniosChoises: null,
			rutas: [],
			rutaChoises: null,
			marcas: [],
			marcasChoises: null,
			modelos: [],
			modelosChoises: null,
			generalData: {
				iIdUnidad: null,
				iActivo: true,
				iIdEmpresa: null,
				iIdRuta: null,
				iIdTipoRuta: '',
				vSerie: '',
				vNumMotor: '',
				vChasis: '',
				iAnioFabricacion: '',
				iIdMarca: null,
				iIdModelo: null,
				vPlaca: '',
				nKm: '',
				vNumeroEconomico: ''
			},
			tipoRuta: {
				vDescripcion: ''
			},
			modeloDetail: {
				iIdTipoVehiculo: '',
				iIdTipoCombustible: '',
				iCapacidadPasajerosSentados: '',
				iCapacidadPasajerosPie: '',
				iCapacidadTotal: '',
				nLargo: '',
				nAncho: '',
				iNumeroEjes: '',
				nPesoVehicular: '',
				vNombreTipoCombustible: '',
				vNombreTipoVehiculo: ''
			},
			showEmpresa: true,
			errors: {},
			profile: {}
		}
	},
	// mixins: [formMixin],
	async mounted() {
		console.log("id====>", this.$route.params.id);
		
		this.generalData.iIdUnidad = this.$route.params.id ?? 0;
		//Editar
		if(this.generalData.iIdUnidad > 0){
			this.find(this.generalData.iIdUnidad);
		}

		const _profile = JSON.parse(localStorage.getItem('profile'))
		this.profile = _profile

		if(this.profile.iIdRol != 1 && this.profile.iIdRol != 4 && this.profile.iIdRol != 5 && this.profile.iIdRol != 6){
			this.showEmpresa = false;
		}

		//EMPRESAS
		this.getCompanies();
		//RUTAS
		this.getRutas();
		//MARCAS
		this.getMarcas();
		//MODELOS
		var modelElement = document.getElementById("iIdModelo");
        this.modelosChoises = new Choices(modelElement, {
            searchEnabled: true,
        });

		//MOTOR
		if (document.getElementById('choices-age')) {
			var element = document.getElementById('choices-age');
			console.log(element);
			this.aniosChoises = new Choices(element, {
				searchEnabled: false
			});
			if(this.generalData.iIdUnidad > 0){
				this.aniosChoises.setChoiceByValue(this.generalData.iAnioFabricacion)
			}
		};
	},
	methods: {
		//find
		find(id){
            axios.get(API_URL +`/unidades/${id}`).then(res =>{
                console.log("ver find")
				console.log(res.data)

				this.generalData = res.data;
				this.generalData.nKm = res.data.iKmActual;
            }).catch(function (error){
                if(error.response.status === 404){
                    showSwal.methods.showSwal({
                    type: "error",
                    message: "No se encontro la Ruta para Editar!",
                    width: 500
                });
                }
            });

        },
		//EMPRESAS
		getCompanies() {
			axios.get(API_URL + '/unidad/obtener-companies', { headers: authHeader() }).then(res => {
				this.companies = res.data;
				if (document.getElementById("iIdEmpresa")) {
					var element = document.getElementById("iIdEmpresa");
					this.companiesChoises = new Choices(element, {
						searchEnabled: true,
						itemSelectText: 'Press to select',
					});
					this.companiesChoises.setChoices(this.companies, 'iIdEmpresa', 'vEmpresa')

					if(this.generalData.iIdUnidad > 0){
						this.companiesChoises.setChoiceByValue(this.generalData.iIdEmpresa)
					}
				}
			});
		},
		//RUTAS
		getRutas() {
			axios.get(API_URL + '/unidad/obtener-rutas').then(res => {
				this.rutas = res.data;
				if (document.getElementById("iIdRuta")) {
					var element = document.getElementById("iIdRuta");
					this.rutaChoises = new Choices(element, {
						searchEnabled: true,
					});
					this.rutaChoises.setChoices(this.rutas, 'iIdRuta', 'vNombreRuta')

					if(this.generalData.iIdUnidad > 0){
						this.rutaChoises.setChoiceByValue(this.generalData.iIdRuta)
						this.getTiposRuta(this.rutas.find(a => a.iIdRuta == this.generalData.iIdRuta)?.iIdTipoRuta);
					}
				}
			});
		},
		//OBTENER ID RUTA
		onChangeRoute(event) {
			const _tipoRuta = this.rutas.find(a => a.iIdRuta == event.target.value)?.iIdTipoRuta;
			this.generalData.iIdRuta = event.target.value;

			//OBTENER TIPOS RUTA ASOCIADA A LA RUTA
			this.getTiposRuta(_tipoRuta);
		},
		getTiposRuta(iIdRuta) {
			axios.get(API_URL + `/unidad/obtener-tipo-ruta-asociada/${iIdRuta}`).then(res => {
				this.tipoRuta = res.data;
			});
		},
		//MARCAS
		getMarcas() {
			axios.get(API_URL + "/unidad/obtener-marcas").then(res => {
				this.marcas = res.data;
				if (document.getElementById("iIdMarca")) {
					var element = document.getElementById("iIdMarca");
					this.marcasChoises = new Choices(element, {
						searchEnabled: true,
					});
					this.marcasChoises.setChoices(this.marcas, 'iIdMarca', 'vNombreMarca')

					if(this.generalData.iIdUnidad > 0){
						this.marcasChoises.setChoiceByValue(this.generalData.iIdMarca)
						this.getModelos(this.generalData.iIdMarca);
					}
					//this.marcasChoises.setChoiceByValue(this.marcas[0].iIdMarca)
				}

			});
		},
		getModelos(idMarca) {
            this.modelos = [];

			axios.get(API_URL + "/modelos/marca/" + idMarca).then(res => {
				this.modelos = res.data;
				this.modelosChoises.setChoices(this.modelos, 'iIdModelo', 'vNombre')

				if(this.generalData.iIdUnidad > 0){
					this.modelosChoises.setChoiceByValue(this.generalData.iIdModelo)
					this.getDetailModelo(this.generalData.iIdModelo)
				}
                console.log(this.modelos);
			});

		},
		onChangeMarca(event) {
            this.modelosChoises.clearChoices();
			this.getModelos(event.target.value);
		},
		//MODELOS
		/* getModelos() {
			axios.get(API_URL + "/unidad/obtener-modelos").then(res => {
				this.modelos = res.data;
				if (document.getElementById("iIdModelo")) {
					var modelElement = document.getElementById("iIdModelo");
					this.modelosChoises = new Choices(modelElement, {
						searchEnabled: true,
					});
					this.modelosChoises.setChoices(this.modelos, 'iIdModelo', 'vNombre')
					//this.modelosChoises.setChoiceByValue(this.modelos[0].iIdModelo)
				}
			});

		}, */
		onChangeModelo(event) {
			this.generalData.iIdModelo = event.target.value;
			this.getDetailModelo(this.generalData.iIdModelo);
		},
		//OBTENER DETALLE DEL MODELO
		getDetailModelo(iIdModelo) {
			axios.get(API_URL + `/modelos/obtener-modelo/${iIdModelo}`).then(res => {
				console.log(res.data);
				this.modeloDetail = res.data;
			});
		},
		async dataAdd() {
			
			if(this.showEmpresa == false){
				this.generalData.iIdEmpresa = this.profile.iIdEmpresa;
			}else {
				this.generalData.iIdEmpresa = this.companiesChoises.getValue().value;
			}

			console.log(this.generalData.iIdEmpresa);

			try {
				if(this.generalData.iIdUnidad > 0){
					await axios.post(API_URL + `/unidad/guardar-datos-generales/${this.generalData.iIdUnidad}`, this.generalData);
					showSwal.methods.showSwal({
						type: "success",
						message: "Unidad Actualizada Con Exito!",
						width: 500
					});
				}else {
					await axios.post(API_URL + "/unidad/guardar-datos-generales", this.generalData);
					showSwal.methods.showSwal({
						type: "success",
						message: "Unidad Registrada Con Exito!",
						width: 500
					});
					this.$parent.nextStep();
				}
			} catch (error) {
				if (error.response.status === 422) {
					this.errors = error.response.data.errors;
				}
				showSwal.methods.showSwal({
					type: "error",
					message: "Oops, Ocurrio un error al registrar la Unidad verique los campos!",
					width: 500
				});

			}
		}
	}
}

</script>