import { createStore } from 'vuex';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import { auth } from './auth.module';
import { roles } from './roles.module';
import { users } from './users.module';
import { dashboard } from './dashboard.module';
import { marcas } from './marcas.module';
import { modelos } from './modelos.module';
import { rutas } from './rutas.module';
import { tags } from './tags.module';
import { categories } from './categories.module';
import { items } from './items.module';
import { companies } from './companies.module';
import { units } from './units.module';
import { pActivities } from './prevActivity.module';
import { plans } from './plans.module';
import { preventives } from './preventives.module';
import { correctives } from './correctives.module';
import { inspections } from './inspections.module';
import { bitacoraInicial } from './bitacoraInicial.module';
import { controlLog } from './controlLog.module';
// import { profile } from "./profile.module"

export default createStore({
    modules: {
        auth,
        roles,
        users,
        dashboard,
        marcas,
        modelos,
        rutas,
        tags,
        categories,
        items,
        companies,
        units,
        pActivities,
        plans,
        preventives,
        correctives,
        inspections,
        bitacoraInicial,
        controlLog,
        // profile
    },
    state: {
        hideConfigButton: false,
        isPinned: true,
        showConfig: false,
        sidebarType: 'bg-gradient-dark',
        isRTL: false,
        color: 'success',
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        isDarkMode: false,
        navbarFixed: 'position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4',
        absolute: 'position-absolute px-4 mx-0 w-100 z-index-2',
        bootstrap,
    },
    mutations: {
        toggleConfigurator(state) {
            state.showConfig = !state.showConfig;
        },
        toggleEveryDisplay(state) {
            state.showNavbar = !state.showNavbar;
            state.showSidenav = !state.showSidenav;
            state.showFooter = !state.showFooter;
        },
        toggleHideConfig(state) {
            state.hideConfigButton = !state.hideConfigButton;
        },
        setIsPinned(state, payload) {
            state.isPinned = payload;
        },
        color(state, payload) {
            state.color = payload;
        },
        navbarMinimize(state) {
            const sidenavShow = document.getElementsByClassName('g-sidenav-show')[0];

            if (sidenavShow.classList.contains('g-sidenav-pinned')) {
                sidenavShow.classList.remove('g-sidenav-pinned');
                sidenavShow.classList.add('g-sidenav-hidden');
                state.isPinned = true;
            } else {
                sidenavShow.classList.remove('g-sidenav-hidden');
                sidenavShow.classList.add('g-sidenav-pinned');
                state.isPinned = false;
            }
        },
        navbarFixed(state) {
            if (state.isNavFixed === false) {
                state.isNavFixed = true;
            } else {
                state.isNavFixed = false;
            }
        },
    },
    actions: {
        setColor({ commit }, payload) {
            commit('color', payload);
        },
    },
    getters: {},
});
