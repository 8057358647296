import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getModelos(params) {
        const response = await axios.get(API_URL + "/modelos", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },

    async addModelo(modelo) {
        modelo.type = "modelos"
        //Modelo.relationshipNames=['roles']

        const newJson = dataFormatter.serialize({ stuff: modelo })
        const response = await axios.post(API_URL + "/modelos", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteModelo(modeloId) {
        await axios.delete(API_URL + "/modelo/eliminar-modelo/" + modeloId, { headers: authHeader() });
    },

    async getModelo(modeloId) {
        const response = await axios.get(API_URL + "/modelos/" + modeloId + "?include=roles", { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editModelo(modelo) {
        modelo.type = "modelos"
        const newJson = dataFormatter.serialize({ stuff: modelo })
        const response = await axios.patch(API_URL + "/modelos/" + modelo.id + "?include=roles", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}