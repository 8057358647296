<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <!--form panels-->
                                <div class="row">
                                    <div class="col-12 col-lg-11 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="large material-icons" style="font-size: 35px">streetview</i>
                                                </div>
                                                <h6 class="mb-0">Editar Ruta</h6>
                                            </div>
                                            <div class="card-body">
                                                <form class="multisteps-form__form">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vNombreRuta" v-model:value="rutas.vNombreRuta" label="Nombre Ruta" variant="static" name="vNombreRuta"></material-input>
                                                            <validation-error v-if="errors && errors.vNombreRuta" class="text-danger"> <small>{{ errors.vNombreRuta[0] }}</small> </validation-error>
                                                        </div>
                                                        <!--selectedMarca-->
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <label class="form-label mb-lg-n1">Tipo de Ruta</label>
                                                            <div class="input-group input-group-static">
                                                                <select id="choices-branch" v-model="rutas.iIdTipoRuta" class="form-control" name="choices-branch">
                                                                    <option value="" selected disabled>Seleccionar Tipo de Ruta</option>
                                                                        <option v-for="ruta in tipoRutas" :key="ruta.id" :value="ruta.iIdTipoRuta">{{ ruta.vDescripcion}}</option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdTipoRuta" class="text-danger"> <small>{{ errors.iIdTipoRuta[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vSiglasRuta" v-model:value="rutas.vSiglasRuta" label="Siglas Ruta" variant="static" name="vSiglasRuta"></material-input>
                                                            <validation-error v-if="errors && errors.vSiglasRuta" class="text-danger"> <small>{{ errors.vSiglasRuta[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iKmRuta" v-model:value="rutas.iKmRuta" label="Kilometros" variant="static" name="iKmRuta"></material-input>
                                                            <validation-error v-if="errors && errors.iKmRuta" class="text-danger"> <small>{{ errors.iKmRuta[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-button v-show="!file" size="sm" type="button">
                                                                <label for="fileInput" class="mb-0 text-white small cursor-pointer">Seleccionar KML</label>
                                                                <input id="fileInput" type="file" style="display: none;" @change.prevent="onFileChange">
                                                            </material-button>

                                                            <div v-show="file">
                                                                <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="onFileRemove">
                                                                    <label class="mb-0 text-white small cursor-pointer"> &#10005; Eliminar</label>
                                                                </material-button>
                                                                <material-button size="sm" type="button">
                                                                    <label for="fileInput" class="mb-0 text-white small cursor-pointer">Cambiar</label>
                                                                    <input id="fileInput" type="file" style="display: none;" @change.prevent="onFileChange">
                                                                </material-button>
                                                            </div>
                                                            <label for="fileInput" class="mb-0 small cursor-pointer">{{ rutas.vRutaKml }}</label>
                                                            <!-- <material-input id="vRutaKml" v-model:value="rutas.vRutaKml" label="Kilometraje Ruta" variant="static" name="vRutaKml"></material-input> -->
                                                            <!-- <validation-error class="text-danger" v-if="errors && errors.vRutaKml"> <small>{{ errors.vRutaKml[0] }}</small> </validation-error> -->
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vPuntoInicio" v-model:value="rutas.vPuntoInicio" label="Punto De Inicio" variant="static" name="vPuntoInicio"></material-input>
                                                            <validation-error v-if="errors && errors.vPuntoInicio" class="text-danger"> <small>{{ errors.vPuntoInicio[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vPuntoFin" v-model:value="rutas.vPuntoFin" label="Punto de Fin" variant="static" name="vPuntoFin"></material-input>
                                                            <validation-error v-if="errors && errors.vPuntoFin" class="text-danger"> <small>{{ errors.vPuntoFin[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iTiempoRecorrido" v-model:value="rutas.iTiempoRecorrido" label="Tiempo Recorrido" variant="static" name="iTiempoRecorrido" type="number"></material-input>
                                                            <validation-error v-if="errors && errors.iTiempoRecorrido" class="text-danger"> <small>{{ errors.iTiempoRecorrido[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-12">
                                                            <material-input id="vComentarios" v-model:value="rutas.vComentarios" label="Comentarios" variant="static" name="vComentarios"></material-input>
                                                            <validation-error v-if="errors && errors.vComentarios" class="text-danger"> <small>{{ errors.vComentarios[0] }} </small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-md-12 d-flex justify-content-end mt-3">
                                                            <material-button class="float-right btn btm-sm btn-danger me-3" @click="this.$router.push({ name: 'Rutas' })">Cancelar</material-button>
                                                            <material-button class="float-right btn btm-sm" @click.prevent="rutaEdit">Actualizar</material-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <br/>
                                        <div class="card">
                                            <div class="card-body">
                                                <div id="map" style="width: 100%; height: 400px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import { InitMap, CreateKmlLayer } from "../../../helpers";
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE_URL;
const FILE_URL = process.env.VUE_APP_FILE_BASE_URL;

export default {
    name: 'EditRuta',
    components: {
        MaterialButton,
        MaterialInput
    },
    mixins: [formMixin],
    data() {
        return {
            rutaId: '',
            tipoRutas: [],
            rutas: {
                iActivo: 1,
                iIdTipoRuta: '',
                vNombreRuta: '',
                vSiglasRuta: '',
                iKmRuta: '',
                vPuntoInicio: '',
                vPuntoFin: '',
                iTiempoRecorrido: '',
                vRutaKml: '',
                vComentarios: ''
            },
            errors: {},
            file: null,
                
        }
    },
    mounted(){
        //OBTENER ID
        this.rutaId = this.$route.params.id;
        //OBTENER RUTA
        this.getRuta(this.$route.params.id);
        //TIPO DE RUTAS
        this.getTipoRutas();
    },
    methods: {
        //OBTENER RUTA
        getRuta(rutaId){
            axios.get(API_URL +`/ruta/obtener-ruta/${rutaId}`).then(res =>{
                this.rutas = res.data;

                if(this.rutas.vRutaKml != null){
                    const mapContainer = document.getElementById('map')
                    InitMap(mapContainer)
                    CreateKmlLayer(this.rutas.vRutaKml)
                    this.file = true
                }
            }).catch(function (error){
                if(error.response.status === 404){
                    showSwal.methods.showSwal({
                    type: "error",
                    message: "No se encontro la Ruta para Editar!",
                    width: 500
                });
                }
            });

        },
        //OBTENER TIPO RUTAS
        getTipoRutas(){
            axios.get(API_URL + "/obtener-tipo-ruta").then(res =>{
                console.log(res.data);
                this.tipoRutas = res.data;
            });
        },
        async onFileChange(event) {
            this.file = null
            this.file = event.target.files[0];
            this.rutas.vRutaKml = URL.createObjectURL(this.file);

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.file);
            this.rutas.vRutaKml = FILE_URL + rutaFile
            //this.file = null
        },
        onFileRemove() {
            this.file = null
            this.ruta.vRutaKml = null;
        },
        async rutaEdit() {
            this.resetApiValidation();
            try {
                await axios.put(API_URL + `/ruta/actualizar-ruta/${this.rutaId}`, this.rutas);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Ruta Modificada Con Exito!",
                    width: 500
                });
                this.$router.push({ name: "Rutas" })
            } catch (error) {
               if(error.response.status === 422){
                this.errors = error.response.data.errors;
               }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Oops, Ocurrio un error al Modificar la ruta verique los campos!",
                    width: 500
                });

            }
        }
    }
}
</script>