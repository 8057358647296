import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {

    async getUnidades(params) {
        const response = await axios.get(API_URL + "/unidades", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },
    async addUnidad(unidad) {
        unidad.type = "unidad"
        //marca.relationshipNames=['roles']

        const newJson = dataFormatter.serialize({ stuff: unidad })
        const response = await axios.post(API_URL + "/unidad", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async deleteUnidad(unidadId) {
        await axios.delete(API_URL + "/unidad/eliminar-unidad/" + unidadId, { headers: authHeader() });
    },
    async getUnidad(unidadId) {
        const response = await axios.get(API_URL + "/unidad/" + unidadId + "?include=roles", { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async editUnidad(unidad) {
        unidad.type = "unidades"
        const newJson = dataFormatter.serialize({ stuff: unidad })
        const response = await axios.patch(API_URL + "/unidades/" + unidad.id + "?include=roles", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}