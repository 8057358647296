import bitacoraInicialService from '@/services/bitacoraInicial.service';

const initialState = {
    bitacoras: [],
    bitacora: [],
    unidades: [],
    message: '',
    response: {
        message: '',
        error: false,
    },
};

export const bitacoraInicial = {
    namespaced: true,
    state: initialState,
    actions: {
        async getBitacorasInicial({ commit }) {
            const bitacoras = await bitacoraInicialService.getBitacorasInicial();
            commit('getBitacorasInicial', bitacoras);
        },
        async createBitacoraInicial({ commit }, bitacora) {
            try {
                const message = await bitacoraInicialService.createBitacoraInicial(bitacora);
                commit('createBitacoraInicial', { message, error: false });
            } catch (error) {
                commit('createBitacoraInicial', { message: error.message, error: true });
            }
        },
        async getBitacoraInicial({ commit }, id) {
            const bitacora = await bitacoraInicialService.getBitacoraInicial(id);
            commit('getBitacoraInicial', bitacora);
        },
        async updateBitacoraInicial({ commit }, bitacora) {
            try {
                const message = await bitacoraInicialService.updateBitacoraInicial(bitacora);
                commit('updateBitacoraInicial', { message, error: false });
            } catch (error) {
                commit('updateBitacoraInicial', { message: error.message, error: true });
            }
        },
        async deleteBitacoraInicial({ commit }, id) {
            const message = await bitacoraInicialService.deleteBitacoraInicial(id);
            commit('deleteBitacoraInicial', message);
        },
        async getUnidades({ commit }) {
            const unidades = await bitacoraInicialService.getUnidades();
            commit('getUnidades', unidades);
        },
    },
    mutations: {
        getBitacorasInicial(state, bitacoras) {
            state.bitacoras = bitacoras;
        },
        createBitacoraInicial(state, response) {
            state.response = response;
        },
        getBitacoraInicial(state, bitacora) {
            state.bitacora = bitacora;
        },
        updateBitacoraInicial(state, response) {
            state.response = response;
        },
        getUnidades(state, unidades) {
            state.unidades = unidades;
        },
        deleteBitacoraInicial(state, message) {
            state.message = message;
        },
    },
    getters: {
        getBitacorasInicial(state) {
            return state.bitacoras;
        },
        getBitacoraInicial(state) {
            return state.bitacora;
        },
        createBitacoraInicial(state) {
            return state.response;
        },
        updateBitacoraInicial(state) {
            return state.response;
        },
        deleteBitacoraInicial(state) {
            return state.message;
        },
        getUnidades(state) {
            return state.unidades;
        },
    },
};
