<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <!--form panels-->
                                <div class="row">
                                    <div class="col-12 col-lg-11 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="large material-icons" style="font-size: 35px">streetview</i>
                                                </div>
                                                <h6 class="mb-0">Registro de Rutas</h6>
                                            </div>
                                            <div class="card-body">
                                                <form class="multisteps-form__form">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vNombreRuta" v-model:value="ruta.vNombreRuta" label="Nombre Ruta" variant="dynamic" name="vNombreRuta"></material-input>
                                                            <validation-error v-if="errors && errors.vNombreRuta" class="text-danger"> <small>{{ errors.vNombreRuta[0] }}</small> </validation-error>
                                                        </div>
                                                        <!--selectedMarca-->
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="choices-branch" v-model="ruta.iIdTipoRuta" class="form-control" name="choices-branch">
                                                                    <option value="" selected disabled>Seleccionar Tipo de Ruta</option>
                                                                        <option v-for="item in tipoRutas" :key="item.id" :value="item.iIdTipoRuta">{{ item.vDescripcion }}</option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdTipoRuta" class="text-danger"> <small>{{ errors.iIdTipoRuta[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vSiglasRuta" v-model:value="ruta.vSiglasRuta" label="Siglas Ruta" variant="dynamic" name="vSiglasRuta"></material-input>
                                                            <validation-error v-if="errors && errors.vSiglasRuta" class="text-danger"> <small>{{ errors.vSiglasRuta[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iKmRuta" v-model:value="ruta.iKmRuta" label="Kilometros" variant="dynamic" name="iKmRuta"></material-input>
                                                            <validation-error v-if="errors && errors.iKmRuta" class="text-danger"> <small>{{ errors.iKmRuta[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-button v-show="!file" size="sm" type="button">
                                                                <label for="fileInput" class="mb-0 text-white small cursor-pointer">Seleccionar KML</label>
                                                                <input id="fileInput" type="file" style="display: none;" @change.prevent="onFileChange">
                                                            </material-button>
                                                            <div v-show="file">
                                                                <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="onFileRemove">
                                                                    <label class="mb-0 text-white small cursor-pointer"> &#10005; Eliminar</label>
                                                                </material-button>
                                                                <material-button size="sm" type="button">
                                                                    <label for="fileInput" class="mb-0 text-white small cursor-pointer">Cambiar</label>
                                                                    <input id="fileInput" type="file" style="display: none;" @change.prevent="onFileChange">
                                                                </material-button>
                                                            </div>
                                                            <material-input id="vRutaKml" v-model:value="vRutaKml" label="Kilometraje Ruta" variant="dynamic" name="vRutaKml"></material-input>
                                                            <!-- <validation-error class="text-danger" v-if="errors && errors.vRutaKml"> <small>{{ errors.vRutaKml[0] }}</small> </validation-error> -->
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vPuntoInicio" v-model:value="ruta.vPuntoInicio" label="Punto De Inicio" variant="dynamic" name="vPuntoInicio"></material-input>
                                                            <validation-error v-if="errors && errors.vPuntoInicio" class="text-danger"> <small>{{ errors.vPuntoInicio[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vPuntoFin" v-model:value="ruta.vPuntoFin" label="Punto de Fin" variant="dynamic" name="vPuntoFin"></material-input>
                                                            <validation-error v-if="errors && errors.vPuntoFin" class="text-danger"> <small>{{ errors.vPuntoFin[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iTiempoRecorrido" v-model:value="ruta.iTiempoRecorrido" label="Tiempo Recorrido" variant="dynamic" name="iTiempoRecorrido" type="number"></material-input>
                                                            <validation-error v-if="errors && errors.iTiempoRecorrido" class="text-danger"> <small>{{ errors.iTiempoRecorrido[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-12">
                                                            <material-input id="vComentarios" v-model:value="ruta.vComentarios" label="Comentarios" variant="dynamic" name="vComentarios"></material-input>
                                                            <validation-error v-if="errors && errors.vComentarios" class="text-danger"> <small>{{ errors.vComentarios[0] }} </small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-md-12 d-flex justify-content-end mt-3">
                                                            <material-button class="float-right btn btm-sm btn-danger me-3" @click="this.$router.push({ name: 'Rutas' })">Cancelar</material-button>
                                                            <material-button class="float-right btn btm-sm" @click.prevent="rutaAdd">Guardar</material-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <br/>
                                        <div class="card">
                                            <div class="card-body">
                                                <div id="map" style="width: 100%; height: 400px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import axios from 'axios';
import { InitMap, CreateKmlLayer } from "../../../helpers";
const API_URL = process.env.VUE_APP_API_BASE_URL;
const FILE_URL = process.env.VUE_APP_FILE_BASE_URL;


export default {
    name: 'AddRuta',
    components: {
        MaterialButton,
        MaterialInput
    },
    mixins: [formMixin],
    data() {
        return {
            tipoRutas: [],
            vRutaKml: null,
            ruta: {
                iActivo: 1,
                iIdTipoRuta: '',
                vNombreRuta: '',
                vSiglasRuta: '',
                iKmRuta: '',
                vPuntoInicio: '',
                vPuntoFin: '',
                iTiempoRecorrido: '',
                vRutaKml: '',
                vComentarios: ''
            },
            file: null,
            loading: null,
            errors: {},
        }
    },
    computed: {
        getFile(){
            return this.vRutaKml
        }
    },
    watch: {
        vRutaKml: function(val) {
            const mapContainer = document.getElementById('map')
            InitMap(mapContainer)
            CreateKmlLayer(val)
        }
    },
    mounted(){
        //TIPO DE RUTAS
        this.getTipoRutas();
        //https://googlearchive.github.io/js-v2-samples/ggeoxml/cta.kml
        console.log(this.vRutaKml)
        console.log("this.rutas.vRutaKml")
        if(this.vRutaKml != null){
            const mapContainer = document.getElementById('map')
            InitMap(mapContainer)
            CreateKmlLayer(this.ruta.vRutaKml)
        }
    },
    methods: {
        //OBTENER TIPO RUTAS
        getTipoRutas(){
            axios.get(API_URL + "/obtener-tipo-ruta").then(res =>{
                this.tipoRutas = res.data;
            });
        },
        async onFileChange(event) {
            this.file = event.target.files[0];
            this.ruta.vRutaKml = URL.createObjectURL(this.file);

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.file);
            this.ruta.vRutaKml = FILE_URL + rutaFile
            //this.file = null
        },
        onFileRemove() {
            this.file = null
            this.ruta.vRutaKml = null;
        },
        async rutaAdd() {
            this.resetApiValidation();
            try {
                await axios.post(API_URL + "/ruta/guardar-ruta", this.ruta);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Ruta Registrada Con Exito!",
                    width: 500
                });
                this.$router.push({ name: "Rutas" })
            } catch (error) {
               if(error.response.status === 422){
                this.errors = error.response.data.errors;
               }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Oops, Ocurrio un error al registrar la ruta verique los campos!",
                    width: 500
                });

            }
        }
    }
}
</script>