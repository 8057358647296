<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <!--form panels-->
                                <div class="row">
                                    <div class="col-12 col-lg-10 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">event</i>
                                                </div>
                                                <h6 class="mb-0">Registro de Empresa</h6>
                                            </div>
                                            <div class="card-body">
                                                <form class="multisteps-form__form">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vEmpresa" v-model:value="company.vEmpresa" label="Empresa" variant="dynamic" name="vEmpresa"></material-input>
                                                            <validation-error v-if="errors && errors.vEmpresa" class="text-danger"> {{ errors.vEmpresa[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vNombreCorto" v-model:value="company.vNombreCorto" label="Nombre Corto" variant="dynamic" name="vNombreCorto"></material-input>
                                                            <validation-error v-if="errors && errors.vNombreCorto" class="text-danger"> {{ errors.vNombreCorto[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vNombreEnlace" v-model:value="company.vNombreEnlace" label="Nombre enlace" variant="dynamic" name="vNombreEnlace"></material-input>
                                                            <validation-error v-if="errors && errors.vNombreEnlace" class="text-danger"> {{ errors.vNombreEnlace[0] }}</validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vTelefonoEnlace" v-model:value="company.vTelefonoEnlace" label="Contacto de Enlace" variant="dynamic" name="vTelefonoEnlace"></material-input>
                                                            <validation-error v-if="errors && errors.vTelefonoEnlace" class="text-danger"> {{ errors.vTelefonoEnlace[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vCorreoEnlace" v-model:value="company.vCorreoEnlace" label="Email de Enlace" variant="dynamic" name="vCorreoEnlace"></material-input>
                                                            <validation-error v-if="errors && errors.vCorreoEnlace" class="text-danger"> {{ errors.vCorreoEnlace[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vUbicacionEncierro" v-model:value="company.vUbicacionEncierro" label="Ubicación Encierro" variant="dynamic" name="vUbicacionEncierro"></material-input>
                                                            <validation-error v-if="errors && errors.vUbicacionEncierro" class="text-danger"> {{ errors.vUbicacionEncierro[0] }}</validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-md-12 d-flex justify-content-end mt-3">
                                                            <material-button class="float-right btn btm-sm btn-danger me-3" @click="this.$router.push({ name: 'Companies List' })">Cancelar</material-button>
                                                            <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Guardar</material-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
    name: 'AddCompany',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    data() {
        return {
            company: {}
        }
    },
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch("companies/addCompany", this.company);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Registro exitoso!",
                    width: 500
                });
                this.$router.push({ name: "Companies" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Ocurrió un error, contacte con el administrador.",
                    width: 500
                });
            }
        }
    }
}
</script>