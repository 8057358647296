<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <!--form panels-->
                                <div class="row">
                                    <div class="col-12 col-lg-10 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">event</i>
                                                </div>
                                                <h6 class="mb-0">Registro de planes de mantenimiento</h6>
                                            </div>
                                            <div class="card-body">
                                                <form class="multisteps-form__form">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vNombrePlan" v-model:value="maintenancePlan.vNombrePlan" label="Nombre" variant="dynamic" name="vNombrePlan"></material-input>
                                                            <validation-error v-if="errors && errors.vNombrePlan" class="text-danger" > {{ errors.vNombrePlan[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdMarca" class="form-control" name="iIdMarca" @change="onChangeMarca($event)">
                                                                    <option value="" selected disabled>Seleccionar Marca</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdModelo" class="form-control" name="iIdModelo" @change="onChangeModelo($event)">
                                                                    <option value="" selected disabled>Seleccionar Modelo</option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdModelo" class="text-danger" > <small>{{ errors.iIdModelo[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iCiclo" v-model:value="maintenancePlan.iCiclo" label="Ciclo" variant="dynamic" name="iCiclo"></material-input>
                                                            <validation-error v-if="errors && errors.iCiclo" class="text-danger" > {{ errors.iCiclo[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iMeses" v-model:value="maintenancePlan.iMeses" label="Meses" variant="dynamic" name="iMeses"></material-input>
                                                            <validation-error v-if="errors && errors.iMeses" class="text-danger" > {{ errors.iMeses[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vcomentarios" v-model:value="maintenancePlan.vcomentarios" label="Comentarios" variant="dynamic" name="vcomentarios"></material-input>
                                                            <validation-error v-if="errors && errors.vcomentarios" class="text-danger" > {{ errors.vcomentarios[0] }}</validation-error>
                                                        </div>
                                                    </div>
                                                    <hr class="horizontal mt-5 mb-2" style="background-color: black;" />
                                                    <div class="row mt-4">
                                                        <div class="col-12 mt-3">
                                                            <div class="d-flex flex-row justify-content-end">
                                                                <!-- <material-button class="float-right btn btm-sm" @click.prevent="addDetail()">Agregar Actividad</material-button> -->
                                                                <material-button type="button" class="float-right btn btm-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                    Agregar Actividad
                                                                </material-button>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 mt-3">
                                                            <div class="table-responsive">
                                                                <table id="plan-list" ref="table" class="table table-flush">
                                                                    <thead class="thead-light">
                                                                        <tr>
                                                                            <th data-sortable="false" title="Actividad" class="text-start w-45">Actividad</th>
                                                                            <th data-sortable="false" title="Comentarios" class="text-start w-45">Comentarios</th>
                                                                            <th data-sortable="false" title="Estado" class="w-10 text-center">Estado</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="text-sm">
                                                                        <tr v-for="(item) in maintenancePlan.detallePlan" :key="item.iIdDetallePlanMantenimientoPrev">
                                                                            <td>
                                                                                {{ item.nombreActividad }}
                                                                            </td>
                                                                            <td>
                                                                                {{ item.vComentarios }}
                                                                            </td>
                                                                            <td class="text-center" style="vertical-align: middle;">
                                                                                {{ item.iActivo ? 'Activo' : 'Inactivo' }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-md-12 d-flex justify-content-end mt-3">
                                                            <material-button class="float-right btn btm-sm btn-danger me-3" @click="this.$router.push({ name: 'Companies List' })">Cancelar</material-button>
                                                            <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Guardar</material-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title font-weight-normal">Agregar Actividad</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mt-4">
                        <div class="col-12 mb-3">
                            <div class="input-group input-group-dynamic">
                                <select id="iIdActividad" v-model="detalle.iIdActividad" class="form-control" name="iIdActividad" @change="onChangeActividad($event)">
                                    <option value="" selected disabled>Seleccionar Actividad</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <material-input id="vComentarios" v-model:value="detalle.vComentarios" label="Comentarios" variant="dynamic" name="vComentarios"></material-input>
                        </div>
                        <div class="col-12 my-3 ps-4">
                            <div class="form-check form-switch">
                                <input id="flexSwitchCheckDefault" v-model="detalle.iActivo" class="form-check-input" type="checkbox" checked="true" >
                                <label class="form-check-label" for="flexSwitchCheckDefault">Activo</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <material-button class="float-right btn btm-sm bg-gradient-secondary" data-bs-dismiss="modal">Close</material-button>
                    <material-button class="float-right btn btm-sm" @click.prevent="addDetail()">Agregar</material-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import Choices from "choices.js";

import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'AddPlan',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    data() {
        return {
            detalle: {
                iIdPlanMantenimientoPrev: null,
                iIdActividad: "",
                nombreActividad: "",
                vComentarios: '',
                iActivo: true
            },
            maintenancePlan: {
                vNombrePlan: "",
                iIdModelo: null,
                iCiclo: "",
                iMeses: "",
                vcomentarios: "",
                detallePlan: []
            },
            errors: {}
        }
    },
    async mounted() {
        this.getMarcas();
        this.getModelos(0);
        this.getActividades();

        var modelElement = document.getElementById("iIdModelo");
        this.modelosChoises = new Choices(modelElement, {
            searchEnabled: true,
        });

        var brandElement = document.getElementById("iIdMarca");
        this.marcasChoises = new Choices(brandElement, {
            searchEnabled: true,
        });
    },
    methods: {
        getMarcas() {
			axios.get(API_URL + "/unidad/obtener-marcas").then(res => {
				this.marcas = res.data;
				this.marcasChoises.setChoices(this.marcas, 'iIdMarca', 'vNombreMarca')
			});
		},
        getActividades() {
			axios.get(API_URL + "/plans/actividades").then(res => {
                this.actividades = res.data;

                if (document.getElementById("iIdActividad")) {
                    let element = document.getElementById("iIdActividad");

                    this.actividadesChoices = new Choices(element, { searchEnabled: true, });
                    this.actividadesChoices.setChoices(this.actividades, 'iIdActividad', 'vDescripcion')
                }
			});
		},
		getModelos(idMarca) {
            this.modelos = [];

			axios.get(API_URL + "/modelos/marca/" + idMarca).then(res => {
				this.modelos = res.data;
				this.modelosChoises.setChoices(this.modelos, 'iIdModelo', 'vNombre')

                console.log(this.modelos);
			});

		},
		onChangeMarca(event) {
            this.modelosChoises.clearChoices();
			this.getModelos(event.target.value);
		},
        onChangeModelo(event) {
			this.maintenancePlan.iIdModelo = event.target.value;
		},
        onChangeActividad(event) {
			this.detalle.iIdActividad    = event.target.value;
            this.detalle.nombreActividad = event.target.innerText;

            console.log(this.detalle);
		},
        addDetail() {
            this.maintenancePlan.detallePlan.push(this.detalle);

            this.detalle = {
                iIdPlanMantenimientoPrev: null,
                iIdActividad: "",
                nombreActividad: "",
                vComentarios: '',
                iActivo: true
            }
        },
        async handleAdd() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch("plans/addPlan", this.maintenancePlan);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Registro exitoso!",
                    width: 500
                });
                this.$router.push({ name: "Planes" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Ocurrió un error, contacte con el administrador.",
                    width: 500
                });
            }
        }
    }
}
</script>
<style>
    .table thead th {
        padding: 0.75rem 12px !important;
    }
    .input-group-outline .choices__inner {
        border: 1px solid #d2d6da !important;
    }
</style>