/* eslint-disable no-unused-vars */
import marcasService from '../services/marcas.service';

const initialState = { marcas: null, marca: null };

export const marcas = {
    namespaced: true,
    state: initialState,
    actions: {
        async getMarcas({ commit }, options) {
            const marcas = await marcasService.getMarcas(options);
            commit('getMarcasSuccess', marcas);
        },

        async addMarca({ commit }, newmarca) {
            const marca = await marcasService.addMarca(newmarca);
            commit('getMarcaSuccess', marca);
        },

        async deleteMarca({ commit }, marcaId) {
            await marcasService.deleteMarca(marcaId);
        },


        async getMarca({ commit }, marcaId) {
            const marca = await marcasService.getMarca(marcaId);
            commit('getMarcaSuccess', marca);
        },

        async editMarca({ commit }, modifiedmarca) {
            await marcasService.editMarca(modifiedmarca); 
        },


    },
    mutations: {
        getMarcasSuccess(state, marcas) {
            state.marcas = marcas;
        },
        successUpload(state, picURL){
            state.marca.profile_image = picURL;
        },
        getMarcaSuccess(state,marca){
            state.marca = marca;
        }

    },
    getters: {
        getMarcasData(state) {
            return state.marcas?.data;
        },
        getMarcasMeta(state) {
            return state.marcas?.meta;
        },
        getMarca(state){
            return state.marca
        },
        getImage(state){
            return state.marca.profile_image
        },
    }
}

