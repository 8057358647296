import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getPreventives(params) {
        const response = await axios.get(API_URL + "/preventives", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },
    async getPreventive(preventiveId) {
        const response = await axios.get(API_URL + "/preventives/" + preventiveId, { headers: authHeader() });
        console.log(response.data);
        return response.data;
    },
    async addPreventive(preventive) {
        preventive.type = "preventives"
        const newJson = dataFormatter.serialize({ stuff: preventive });
        let object    = newJson.data?.attributes;

        console.log(object);
        const response = await axios.post(API_URL + "/preventives", object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async editPreventive(preventive) {
        preventive.type = "preventives"
        const newJson = dataFormatter.serialize({ stuff: preventive });
        let object = newJson.data?.attributes;
        const response = await axios.put(API_URL + "/preventives/" + preventive.iIdPreventiveMantenimientoPrev, object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async deletePreventive(preventiveId) {
        await axios.delete(API_URL + "/preventives/" + preventiveId, { headers: authHeader() });
    },
}