<template>
    <div class="row g-3">
        <MantenimientoTotalCard v-for="(total, tipo) in mantenimientos" :key="tipo" :fecha-in="fechaIn" :fecha-fn="fechaFn" :tipo="tipo" :total="total" />
    </div>
</template>

<script>
import axios from 'axios';
import MantenimientoTotalCard from './MantenimientoTotalCard.vue';

export default {
    name: 'MatenimientoTotalCards',
    components: {
        MantenimientoTotalCard,
    },
    data() {
        return {
            fechaIn: '',
            fechaFn: '',
            mantenimientos: {},
        };
    },
    mounted() {
        this.getMantenimientos();
    },
    methods: {
        async getMantenimientos() {
            const {
                data: { fechaIn, fechaFn, tipos },
            } = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/total`);

            this.fechaIn = new Date(fechaIn).toLocaleDateString().split('T').at(0);
            this.fechaFn = new Date(fechaFn).toLocaleDateString().split('T').at(0);
            this.mantenimientos = tipos;
        },
    },
};
</script>
