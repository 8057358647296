import { createRouter, createWebHistory } from 'vue-router';
import Default from '../views/dashboards/Default.vue';

// Dashboard
import Dashboard from '../views/dashboard/Dashboard.vue';

// Rutas módulo de catálogos
import MarcasList from '../views/catalogs/marcas/MarcasList.vue';
import AddMarca from '../views/catalogs/marcas/AddMarca.vue';
import EditMarca from '../views/catalogs/marcas/EditMarca.vue';
import ModelosList from '../views/catalogs/modelos/ModelosList.vue';
import AddModelo from '../views/catalogs/modelos/AddModelo.vue';
import EditModelo from '../views/catalogs/modelos/EditModelo.vue';
import CompaniesList from '../views/catalogs/companies/CompaniesList.vue';
import AddCompany from '../views/catalogs/companies/AddCompany.vue';
import EditCompany from '../views/catalogs/companies/EditCompany.vue';
import RutasList from '../views/catalogs/rutas/RutasList.vue';
import AddRuta from '../views/catalogs/rutas/AddRuta.vue';
import EditRuta from '../views/catalogs/rutas/EditRuta.vue';

import UsersList from '../views/catalogs/users/UsersList.vue';
import AddUser from '../views/catalogs/users/AddUser.vue';
import EditUser from '../views/catalogs/users/EditUser.vue';
import UnitsList from '../views/catalogs/units/UnitsList.vue';
import AddUnit from '../views/catalogs/units/AddUnit.vue';
import EditUnit from '../views/catalogs/units/EditUnit.vue';

// Rutas módulo dashboard
// import Dashboard from '../views/dashboard/Dashboard.vue';

// Rutas módulo mantenimientos
import PlanesList from '../views/maintenances/plans/PlanesList.vue';
import AddPlan from '../views/maintenances/plans/AddPlan.vue';
import EditPlan from '../views/maintenances/plans/EditPlan.vue';
import PrevActivityList from '../views/maintenances/prevActivity/PrevActivityList.vue';
import AddPrevActivity from '../views/maintenances/prevActivity/AddPrevActivity.vue';
import EditPrevActivity from '../views/maintenances/prevActivity/EditPrevActivity.vue';
import PreventiveList from '../views/maintenances/preventives/PreventiveList.vue';
import AddPreventive from '../views/maintenances/preventives/AddPreventive.vue';
import EditPreventive from '../views/maintenances/preventives/EditPreventive.vue';
import CorrectiveList from '../views/maintenances/correctives/CorrectiveList.vue';
import AddCorrective from '../views/maintenances/correctives/AddCorrective.vue';
import EditCorrective from '../views/maintenances/correctives/EditCorrective.vue';
import IncidentList from '../views/maintenances/incidents/IncidentsList.vue';
import AddIncident from '../views/maintenances/incidents/AddIncident.vue';
import EditIncident from '../views/maintenances/incidents/EditIncident.vue';

import Overview from '../views/pages/profile/Overview.vue';
import Notifications from '../views/pages/Notifications.vue';
import NewUser from '../views/pages/users/NewUser.vue';
import Settings from '../views/pages/account/Settings.vue';
import Basic from '../views/auth/signin/Basic.vue';
import Cover from '../views/auth/signin/Cover.vue';
import Illustration from '../views/auth/signin/Illustration.vue';
import ResetCover from '../views/auth/reset/Cover.vue';
import SignupCover from '../views/auth/signup/Cover.vue';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import PasswordForgot from '../views/PasswordForgot.vue';
import PasswordReset from '../views/PasswordReset.vue';
import UserProfile from '../views/examples-api/user-profile/UserProfile.vue';
import RolesList from '../views/examples-api/roles/RolesList.vue';
import EditRole from '../views/examples-api/roles/EditRole.vue';
import AddRole from '../views/examples-api/roles/AddRole.vue';

// Rutas módulo inspecciones
import InspectionList from '@/views/inspections/InspectionList';
import AddInspection from '@/views/inspections/AddInspection';
import EditInspection from '@/views/inspections/EditInspection.vue';

// Rutas módulo bitácora inicial
import BitacoraInicialList from '@/views/bitacoras/inicial/BitacoraInicialList';
import AddBitacoraInicial from '@/views/bitacoras/inicial/AddBitacoraInicial';
import EditBitacoraInicial from '@/views/bitacoras/inicial/EditBitacoraInicial';
import ControlLogList from '@/views/bitacoras/control-log/ControlLogList';
import AddControlLog from '@/views/bitacoras/control-log/AddControlLog';
import EditControlLog from '@/views/bitacoras/control-log/EditControlLog.vue';

import store from '@/store';

const routes = [
    {
        path: '/',
        name: '/',
        redirect: '/login',
    },
    {
        path: '/dashboards/dashboard-default',
        name: 'Default',
        component: Dashboard,
        meta: {
            authRequired: true,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/pages/profile/overview',
        name: 'Profile Overview',
        component: Overview,
    },
    {
        path: '/pages/notifications',
        name: 'Notifications',
        component: Notifications,
    },
    {
        path: '/pages/users/new-user',
        name: 'New User',
        component: NewUser,
    },
    {
        path: '/pages/account/settings',
        name: 'Settings',
        component: Settings,
    },
    {
        path: '/authentication/signin/basic',
        name: 'Signin Basic',
        component: Basic,
    },
    {
        path: '/authentication/signin/cover',
        name: 'Signin Cover',
        component: Cover,
    },
    {
        path: '/authentication/signin/illustration',
        name: 'Signin Illustration',
        component: Illustration,
    },
    {
        path: '/authentication/reset/cover',
        name: 'Reset Cover',
        component: ResetCover,
    },
    {
        path: '/authentication/signup/cover',
        name: 'Signup Cover',
        component: SignupCover,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    {
        path: '/password-forgot',
        name: 'PasswordForgot',
        component: PasswordForgot,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    {
        path: '/password-reset',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    // ------------------------------------------- Dashboard
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Default,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    // ------------------------------------------- Catálogos del Sistema
    {
        path: '/catalogs/user-profile',
        name: 'User Profile',
        component: UserProfile,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    {
        path: '/catalogs/roles-list',
        name: 'Roles List',
        component: RolesList,
        meta: {
            authRequired: false,
            permission: ['admin'],
        },
    },
    {
        path: '/catalogs/add-role',
        name: 'Add Role',
        component: AddRole,
        meta: {
            authRequired: false,
            permission: ['admin'],
        },
    },
    {
        path: '/catalogs/edit-role',
        name: 'Edit Role',
        component: EditRole,
        meta: {
            authRequired: false,
            permission: ['admin'],
        },
    },
    {
        path: '/catalogs/marcas-list',
        name: 'Marcas',
        component: MarcasList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/add-marca',
        name: 'Add Marca',
        component: AddMarca,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/edit-marca/:id',
        name: 'Edit Marca',
        component: EditMarca,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/modelos-list',
        name: 'Modelos',
        component: ModelosList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/add-modelo',
        name: 'Add Modelo',
        component: AddModelo,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/edit-modelo/:id',
        name: 'Edit Modelo',
        component: EditModelo,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/companies-list',
        name: 'Companies',
        component: CompaniesList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/add-company',
        name: 'Add Company',
        component: AddCompany,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/edit-company',
        name: 'Edit Company',
        component: EditCompany,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/rutas',
        name: 'Rutas',
        component: RutasList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/add-ruta',
        name: 'Add Ruta',
        component: AddRuta,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/edit-ruta/:id',
        name: 'Edit Ruta',
        component: EditRuta,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    /* ********** MANTENIMIENTOS ********* */

    {
        path: '/catalogs/edit-ruta/:id',
        name: 'Edit Ruta',
        component: EditRuta,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator']
        }
    },
    {
        path: '/catalogs/users/users-list',
        name: 'Users',
        component: UsersList,
        meta: {
            authRequired: false,
            permission: ['admin'],
        },
    },
    {
        path: '/catalogs/users/add-user',
        name: 'Add User',
        component: AddUser,
        meta: {
            authRequired: false,
            permission: ['admin'],
        },
    },
    {
        path: '/catalogs/users/edit-user/:id',
        name: 'Edit User',
        component: EditUser,
        meta: {
            authRequired: false,
            permission: ['admin'],
        },
    },
    {
        path: '/catalogs/units-list',
        name: 'Units',
        component: UnitsList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/add-unit',
        name: 'Add Unit',
        component: AddUnit,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/catalogs/edit-unit/:id',
        name: 'Edit Unit',
        key: 'editar',
        component: EditUnit,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    // ------------------------------------------- Módulo de Mantenimientos
    {
        path: '/maintenances/planes-list',
        name: 'Planes',
        component: PlanesList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/add-plan',
        name: 'Add Plan',
        component: AddPlan,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/edit-plan/:id',
        name: 'Edit Plan',
        component: EditPlan,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/preventives-list',
        name: 'PreventiveList',
        component: PreventiveList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/add-preventive',
        name: 'AddPreventive',
        component: AddPreventive,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/edit-preventive/:id',
        name: 'EditPreventive',
        component: EditPreventive,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/prevActivity-list',
        name: 'PrevActivity',
        component: PrevActivityList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/add-prevActivity',
        name: 'Add PrevActivity',
        component: AddPrevActivity,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/edit-prevActivity',
        name: 'Edit PrevActivity',
        component: EditPrevActivity,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/correctives-list',
        name: 'CorrectiveList',
        component: CorrectiveList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/add-corrective',
        name: 'AddCorrective',
        component: AddCorrective,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/edit-corrective/:id',
        name: 'EditCorrective',
        component: EditCorrective,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    // ------------------------------------------- Módulo de Inspecciones
    {
        path: '/inspections/list',
        name: 'InspectionList',
        component: InspectionList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/inspections/add',
        name: 'AddInspection',
        component: AddInspection,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/inspections/edit/:id',
        name: 'EditInspection',
        component: EditInspection,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/incident-list',
        name: 'Incident',
        component: IncidentList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/add-incident',
        name: 'Add Incident',
        component: AddIncident,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/maintenances/edit-incident/:id',
        name: 'Edit Incident',
        component: EditIncident,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],

        },
    },
    // ------------------------------------------- Módulo de Bitácora inicial
    {
        path: '/bitacoras/inicial/list',
        name: 'BitacoraInicialList',
        component: BitacoraInicialList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/bitacoras/inicial/add',
        name: 'AddBitacoraInicial',
        component: AddBitacoraInicial,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/bitacoras/inicial/edit/:id',
        name: 'EditBitacoraInicial',
        component: EditBitacoraInicial,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/bitacoras/control/control-logs-list',
        name: 'ControlLogList',
        component: ControlLogList,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/bitacoras/control/add-control-log',
        name: 'AddControlLog',
        component: AddControlLog,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
    {
        path: '/bitacoras/control/edit-control-logs/:id',
        name: 'EditControlLog',
        component: EditControlLog,
        meta: {
            authRequired: false,
            permission: ['admin', 'creator'],
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active',
});

router.beforeEach(async (to, from, next) => {
    const { authRequired, permission } = to.meta;
    const isLoggedIn = store.getters['auth/isLoggedIn'];
    if (authRequired) {
        if (!isLoggedIn) {
            return next({ name: 'Login', query: { returnUrl: to.path } });
        }

        if (permission) {
            try {
                //const userPermission = profile.lMenu;
                //if (!permission.includes(userPermission)) {
                //return next({ name: 'Default', query: { returnUrl: to.path } });
                //}
            } catch (error) {
                try {
                    store.dispatch('auth/logout');
                } finally {
                    // eslint-disable-next-line no-unsafe-finally
                    return next({ name: 'Login' });
                }
            }
        }
    }
    next();
});

export default router;
