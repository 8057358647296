<template>
    <section class="container py-4">
        <div class="row m-4">
            <div class="card col-12 p-0">
                <div class="card-header border-bottom">
                    <div class="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">Bitácora de Inspecciones</h5>
                        <material-button class="btn btm-sm" @click="this.$router.push({ name: 'AddInspection' })">Agregar Inspección</material-button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="co-12 col-md-6 col-lg-4">
                            <material-input v-model:value="search" placeholder="Buscar..." class="mb-1 dataTable-input search-input-table" variant="static" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="table-responsive">
                            <table ref="table" class="table table-light">
                                <thead class="text-bold">
                                    <tr>
                                        <th>PLACAS</th>
                                        <th>NÚMERO ECONÓMICO</th>
                                        <th>NÚMERO CHASIS</th>
                                        <th>MODELO</th>
                                        <th>MARCA</th>
                                        <th>FECHA DE LA INSPECCION</th>
                                        <th>ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm"></tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <BasePagination
                                v-model="pagination.currentPage"
                                class="pagination-success pagination-md m-0 gap-2"
                                :value="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="pagination.total"
                                @click="getDataFromPage($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { DataTable } from 'simple-datatables';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import BasePagination from '@/components/BasePagination.vue';
import eventTable from '@/mixins/eventTable.js';

export default {
    name: 'InspectionList',
    components: {
        MaterialInput,
        MaterialButton,
        BasePagination,
    },
    mixins: [eventTable],
    data() {
        return {
            table: null,
            pagination: {},
            search: '',
        };
    },
    computed: {
        inspecciones() {
            return this.$store.getters['inspections/getInspecciones'];
        },
    },
    watch: {
        inspecciones: {
            handler: 'reactiveTable',
            immediate: false,
            deep: true,
        },
    },
    mounted() {
        if (this.$refs.table) {
            this.table = new DataTable(this.$refs.table, {
                searchable: false,
                fixedHeight: false,
                perPage: 5,
            });

            document.querySelector('.dataTable-bottom').remove();
            this.table.label = null;
            this.table.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);

            this.getInspecciones();
        }
    },
    methods: {
        async getInspecciones() {
            await this.$store.dispatch('inspections/getInspecciones');
        },
        async reactiveTable() {
            if (this.inspecciones.length === 0) {
                return this.table.setMessage('Sin inspecciones registradas.');
            }

            const rows = [];

            for (const inspection of this.inspecciones) {
                rows.push([
                    inspection.unidad.vPlaca,
                    inspection.unidad.vNumEconomico,
                    inspection.unidad.vChasis,
                    inspection.unidad.modelo.vNombre,
                    inspection.unidad.modelo.marca.vNombreMarca,
                    inspection.dFechaInspeccion,
                    `<div class="text-center">
                        ${this.actionEditButton(inspection.iIdInspeccion, 'Editar Inspección') + this.actionDeleteButton(inspection.iIdInspeccion, 'Eliminar Inspección')}
                    </div>`,
                ]);
            }

            this.table.data = [];

            this.table.refresh();

            this.table.insert({ data: rows });

            this.removeEvent();

            this.eventToCall({
                table: this.table,
                redirectPath: 'EditInspection',
                deletePath: 'inspections/deleteInspeccion',
                getPath: 'inspections/getInspecciones',
                textDelete: 'Inspección eliminada.',
                textDefaultData: 'Inspecciones',
            });
        },
    },
};
</script>
