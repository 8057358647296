import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getMarcas(params) {
        const response = await axios.get(API_URL + "/marcas", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },

    async addMarca(marca) {
        marca.type = "marcas"
        //marca.relationshipNames=['roles']

        const newJson = dataFormatter.serialize({ stuff: marca })
        const response = await axios.post(API_URL + "/marcas", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteMarca(marcaId) {
        await axios.delete(API_URL + "/marcas/eliminar-marca/" + marcaId, { headers: authHeader() });
    },

    async getMarca(marcaId) {
        const response = await axios.get(API_URL + "/marcas/" + marcaId + "?include=roles", { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editMarca(marca) {
        marca.type = "marcas"
        const newJson = dataFormatter.serialize({ stuff: marca })
        const response = await axios.patch(API_URL + "/marcas/" + marca.id + "?include=roles", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}