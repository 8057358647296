<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n1 me-2 float-start">
                                    <i class="material-icons opacity-10">event</i>
                                </div>
                                <h5 class="mb-0 mt-3">Editar de Marca</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm" @click="this.$router.push({ name: 'Marcas' })">
                                    Marcas
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="row mt-2">
                                <div class="col-4">
                                    <material-input id="vNombreMarca" v-model:value="marcas.vNombreMarca" label="Marca" variant="static" name="vNombreMarca"></material-input>
                                    <validation-error v-if="errors && errors.vNombreMarca" class="text-danger"> {{ errors.vNombreMarca[0] }}</validation-error>
                                </div>
                                <div class="col-4">
                                    <material-input id="vPaisOrigen" v-model:value="marcas.vPaisOrigen" label="Pais" variant="static" name="vPaisOrigen"></material-input>
                                    <validation-error v-if="errors && errors.vPaisOrigen" class="text-danger"> {{ errors.vPaisOrigen[0] }}</validation-error>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-4">
                                    <material-input id="vPersonaContacto" v-model:value="marcas.vPersonaContacto" label="Persona Contacto" variant="static" name="vPersonaContacto"></material-input>
                                    <validation-error v-if="errors && errors.vPersonaContacto" class="text-danger"> {{ errors.vPersonaContacto[0] }}</validation-error>
                                </div>
                                <div class="col-4">
                                    <material-input id="vTelefono" v-model:value="marcas.vTelefono" label="Telefono" variant="static" name="vTelefono"></material-input>
                                    <validation-error v-if="errors && errors.vTelefono" class="text-danger"> {{ errors.vTelefono[0] }}</validation-error>
                                </div>
                                <div class="col-4">
                                    <material-input id="vCorreoElectronico" v-model:value="marcas.vCorreoElectronico" label="Correo Electronico" variant="static" name="vCorreoElectronico"></material-input>
                                    <validation-error v-if="errors && errors.vCorreoElectronico" class="text-danger"> {{ errors.vCorreoElectronico[0] }}</validation-error>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12 justify-content-end mt-3">
                                    <material-button class="float-center btn btm-sm ms-auto mb-0 float-end" @click.prevent="updateMarca">Editar Marca</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'EditMarca',
    components: {
        MaterialButton,
        MaterialInput
    },
    mixins: [formMixin],
    data() {
        return {
            marcaId: '',
            marcas: {
                iActivo: 1,
                vNombreMarca: '',
                vPaisOrigen: '',
                vPersonaContacto: '',
                vTelefono: '',
                vCorreoElectronico: ''
            },
            errors: {}
        }
    },
    mounted(){
        //console.log(this.$route.params.id);
        this.marcaId = this.$route.params.id;
        this.getMarcasData(this.$route.params.id);
    },
    methods: {

        getMarcasData(marcaId){

            axios.get(API_URL + `/marcas/mostrar-marca/${marcaId}`)
            .then(res =>{

                console.log(res.data.vNombreMarca);
                this.marcas = res.data;

            }).catch(function (error){

                if(error.response.status === 404){
                    showSwal.methods.showSwal({
                    type: "error",
                    message: "No se encontro la Marca para Editar!",
                    width: 500
                });

                }

            });

        },

        async updateMarca() {
            this.resetApiValidation();
            try {
                await axios.put(API_URL + `/marcas/actualizar-marca/${this.marcaId}`, this.marcas);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Marca Editada Con Exito!",
                    width: 500
                });
                this.$router.push({ name: "Marcas" })
            } catch (error) {
               if(error.response.status === 422){
                this.errors = error.response.data.errors;
               }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Oops, Ocurrio un error al editar la marca verique los campos!",
                    width: 500
                });

            }
        }
    }
}
</script>