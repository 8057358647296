<template>
    <div class="col-12 col-md-6">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex flex-column" style="position: relative; max-height: 400px;">
                    <h6>Por tipo de combustible</h6>
                    <canvas id="mantenimiento-unidad-chart"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from 'chart.js';

export default {
    name: 'MantenimientoUnidadChartCard',
    props: {
        mantenimientos: {
            type: Object,
            default: () => ({}),
        },
    },
    mounted() {
        this.setupChart();
    },
    methods: {
        setupChart() {
            const canvas = document.getElementById('mantenimiento-unidad-chart');
            const context = canvas.getContext('2d');

            new Chart(context, {
                type: 'pie',
                data: {
                    labels: Object.keys(this.mantenimientos),
                    datasets: [
                        {
                            // backgroundColor: Object.keys(this.mantenimientos).map(() => `#${Math.floor(Math.random()*16777215).toString(16)}`),
                            backgroundColor: ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850'],
                            data: Object.values(this.mantenimientos).map(mantenimiento => mantenimiento.porcentaje),
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                },
            });
        },
    },
};
</script>
