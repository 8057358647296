/* eslint-disable no-unused-vars */
import companiesService from '../services/companies.service';

const initialState = { companies: null, company: null };

export const companies = {
    namespaced: true,
    state: initialState,
    actions: {
        async getCompanies({ commit }, options) {
            const companies = await companiesService.getCompanies(options);
            commit('getCompaniesSuccess', companies);
        },
        async addCompany({ commit }, newCompany) {
            const company = await companiesService.addCompany(newCompany);
            commit('getCompanySuccess', company);
        },
        async deleteCompany({ commit }, companyId) {
            await companiesService.deleteCompany(companyId);
        },
        async getCompany({ commit }, companyId) {
            const company = await companiesService.getCompany(companyId);
            commit('getCompanySuccess', company);
        },
        async editCompany({ commit }, modifiedcompany) {
            console.log(modifiedcompany);
            await companiesService.editCompany(modifiedcompany); 
        },
    },
    mutations: {
        getCompaniesSuccess(state, companies) {
            state.companies = companies;
        },
        getCompanySuccess(state,company){
            state.company = company;
        }
    },
    getters: {
        getCompaniesData(state) {
            return state.companies?.data;
        },
        getCompaniesMeta(state) {
            return state.companies?.meta;
        },
        getCompany(state){
            return state.company
        },
        getImage(state){
            return state.company.profile_image
        },
    }
}

