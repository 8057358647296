<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Add user</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm" @click="this.$router.push({ name: 'Users' })"> Atras </material-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="row mt-5">
                                <div class="col-md-6">
                                    <label>Rol</label>
                                    <select id="iIdRol" class="multisteps-form__select form-control" @change="onSelectRol($event)"></select>
                                </div>
                                <div class="col-md-6">
                                    <label>Empresa</label>
                                    <select id="iIdEmpresa" class="multisteps-form__select form-control" @change="onSelectEmpresa($event)"></select>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-4">
                                    <material-input id="vNombre" v-model:value="user.vNombre" label="Name" variant="static" name="vNombre"></material-input>
                                    <validation-error :errors="apiValidationErrors.vNombre"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input
                                        id="vPrimerApellido"
                                        v-model:value="user.vPrimerApellido"
                                        label="Primer Apellido"
                                        variant="static"
                                        name="vPrimerApellido"
                                    ></material-input>
                                    <validation-error :errors="apiValidationErrors.vPrimerApellido"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input
                                        id="vSegundoApellido"
                                        v-model:value="user.vSegundoApellido"
                                        label="SegundoA pellido"
                                        variant="static"
                                        name="vSegundoApellido"
                                    ></material-input>
                                    <validation-error :errors="apiValidationErrors.vSegundoApellido"></validation-error>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-4">
                                    <material-input id="vCorreo" v-model:value="user.vCorreo" label="Correo" variant="static" name="vCorreo"></material-input>
                                    <validation-error :errors="apiValidationErrors.vCorreo"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input id="vUsuario" v-model:value="user.vUsuario" label="Usuario" variant="static" name="vUsuario"></material-input>
                                    <validation-error :errors="apiValidationErrors.vUsuario"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input
                                        id="password"
                                        v-model:value="user.password"
                                        type="password"
                                        label="Contrasenia"
                                        variant="static"
                                        name="password"
                                    ></material-input>
                                    <validation-error :errors="apiValidationErrors.password"></validation-error>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-4">
                                    <material-input id="vTelefono" v-model:value="user.vTelefono" label="Telefono" variant="static" name="vTelefono"></material-input>
                                    <validation-error :errors="apiValidationErrors.vTelefono"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input id="vCelular" v-model:value="user.vCelular" label="Celular" variant="static" name="vCelular"></material-input>
                                    <validation-error :errors="apiValidationErrors.vCelular"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input
                                        id="vCorreoPersonal"
                                        v-model:value="user.vCorreoPersonal"
                                        label="Correo Personal"
                                        variant="static"
                                        name="vCorreoPersonal"
                                    ></material-input>
                                    <validation-error :errors="apiValidationErrors.vCorreoPersonal"></validation-error>
                                </div>
                            </div>
                            <div class="button-row d-flex mt-4">
                                <material-button type="button" color="dark" variant="gradient" class="ms-auto mb-0 js-btn-next" @click="handleAdd">Guardar</material-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import ValidationError from '@/components/ValidationError.vue';
import formMixin from '@/mixins/formMixin.js';
import showSwal from '../../../mixins/showSwal';
import Choices from 'choices.js';

export default {
    name: 'Adduser',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    data() {
        return {
            user: {
                iActivo: true,
                iIdMaxNivelAcademico: 1,
                iIdFormacionAcademica: 1,
            },
        };
    },
    async mounted() {
        await this.getRoles();
        await this.getEmpresas();
    },
    methods: {
        async getRoles() {
            await this.$store.dispatch('roles/getRoles');
            const roles = this.$store.getters['roles/getRolesData'];
            const iIdRol = roles[0].iIdRol;
            this.user.iIdRol = iIdRol;
            new Choices('#iIdRol').setChoices(roles, 'iIdRol', 'vRol').setChoiceByValue(iIdRol);
        },
        onSelectRol(event) {
            this.user.iIdRol = Number(event.target.value);
        },
        async getEmpresas() {
            await this.$store.dispatch('companies/getCompanies');
            const empresas = this.$store.getters['companies/getCompaniesData'];
            const iIdEmpresa = empresas[0].iIdEmpresa;
            this.user.iIdEmpresa = iIdEmpresa;
            new Choices('#iIdEmpresa').setChoices(empresas, 'iIdEmpresa', 'vEmpresa').setChoiceByValue(iIdEmpresa);
        },
        onSelectEmpresa(event) {
            this.user.iIdEmpresa = Number(event.target.value);
        },
        async handleAdd() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch('users/addUser', this.user);
                showSwal.methods.showSwal({
                    type: 'success',
                    message: 'User added successfully!',
                    width: 500,
                });
                this.$router.push({ name: 'Users' });
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: 'error',
                    message: 'Oops, something went wrong!',
                    width: 500,
                });
            }
        },
    },
};
</script>
