/* eslint-disable no-unused-vars */
import rutasService from '../services/rutas.service';

const initialState = { rutas: null, ruta: null };

export const rutas = {
    namespaced: true,
    state: initialState,
    actions: {
        async getRutas({ commit }, options) {
            const rutas = await rutasService.getRutas(options);
            commit('getRutasSuccess', rutas);
        },

        async addRuta({ commit }, newruta) {
            const ruta = await rutasService.addRuta(newruta);
            commit('getRutaSuccess', ruta);
        },

        async deleteRuta({ commit }, rutaId) {
            await rutasService.deleteRuta(rutaId);
        },


        async getRuta({ commit }, rutaId) {
            const ruta = await rutasService.getRuta(rutaId);
            commit('getRutaSuccess', ruta);
        },

        async editRuta({ commit }, modifiedruta) {
            await rutasService.editRuta(modifiedruta); 
        },
        async uploadFile({ commit }, file) {
            const picURL = (await rutasService.uploadFile(file)).fileData.ruta;
            return picURL;
            //commit('successUpload', picURL);
         },

    },
    mutations: {
        getRutasSuccess(state, rutas) {
            state.rutas = rutas;
        },
        successUpload(state, picURL){
            console.log("state.ruta")
            console.log(state.ruta)
            //state.ruta.vRutaKml = picURL;
        },
        getRutaSuccess(state,ruta){
            state.ruta = ruta;
        }

    },
    getters: {
        getRutasData(state) {
            return state.rutas?.data;
        },
        getRutasMeta(state) {
            return state.rutas?.ruta;
        },
        getRuta(state){
            return state.ruta
        },
        getImage(state){
            return state.ruta.profile_image
        },
    }
}

