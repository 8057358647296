<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 user">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header border-bottom">
              <div class="user d-flex align-items-center">
                <div class="col-6">
                  <h5 class="mb-0">Marcas</h5>
                </div>
                <div class="col-6 text-end">
                  <material-button class="float-right btn btm-sm" @click="this.$router.push({ name: 'Add Marca' })">
                    <i class="fas fa-user-plus me-2"></i>
                    Add Marca
                  </material-button>
                </div>
              </div>
            </div>
  
            <!-- Card body -->
            <div class="px-0 pb-0 card-body">
  
              <!-- Search -->
              <div class="dataTable-search search-block col-2 ms-3">
                <material-input id="search" v-model:value="search" placeholder="Search..."
                  class="mb-1 dataTable-input search-input-table" variant="static" />
              </div>
  
              <!-- Table -->
              <div class="table-responsive">
                <table id="marcas-list" ref="table" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th title="Nombre">Nombre</th>
                      <th title="Pais Origen">Pais Origen</th>
                      <th title="Persona Contacto">Persona Contacto</th>
                      <th title="Telefono">Telefono</th>
                      <th title="Correo Electronico">Correo Electronico</th>
                      <th title="Acciones" data-sortable="false">Action</th>
                    </tr>
                  </thead>
                  <tbody class="text-sm">
                  </tbody>
                </table>
              </div>
            </div>
  
            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap align-items-center">
              <div class="ms-3">
                <p class="text-sm">
                  Showing {{ pagination.total ? metaPage?.from : 0 }} to {{ metaPage?.to }} of
                  {{ pagination.total }} entries
                </p>
              </div>
              <BasePagination v-model="pagination.currentPage" class="pagination-success pagination-md me-3"
                :value="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"
                @click="getDataFromPage($event)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { DataTable } from "simple-datatables";
  import eventTable from "@/mixins/eventTable.js"
  import store from "@/store";
  import _ from "lodash";
  
  import MaterialButton from "@/components/MaterialButton.vue";
  import MaterialInput from "@/components/MaterialInput.vue";
  import BasePagination from "@/components/BasePagination.vue";
  
  var sortDirection = "created_at";
  var getCurrentPage = 1;
  var searchQuery = '';
  
  const getMarcasList = _.debounce(async function (params) {
    await store.dispatch("marcas/getMarcas", {
      ...(params.sort ? { sort: params.sort } : {}),
      filter: {
        ...(params.query ? { name: params.query } : {}),
      },
      page: {
        number: params.nr,
        size: params.perpage,
      },
    });
  }, 300);
  
  export default {
    name: "MarcasList",
    components: {
      MaterialButton,
      MaterialInput,
      BasePagination
    },
    mixins: [eventTable],
    data() {
      return {
        search: '',
        dataMarcas: [],
        pagination: {},
        dataTable: null,
        keys: null,
        me: null
      }
    },
    computed: {
      marcasList() {
        return this.$store.getters["marcas/getMarcasData"];
  
      },
      metaPage() {
        return this.$store.getters["marcas/getMarcasMeta"];
      },
    },
    watch: {
      metaPage: {
        handler: "reactivePagination",
        immediate: false,
        deep: true,
      },
      marcasList: {
        handler: "reactiveTable",
        immediate: false,
        deep: true,
      },
      search: {
        handler: "reactiveSearch"
      }
    },
    async mounted() {
      if (this.$refs.table) {
        this.dataTable = new DataTable(this.$refs.table, {
          searchable: false,
          fixedHeight: false,
          perPage: 5,
        });
  
        document.querySelector(".dataTable-bottom").remove()
        this.dataTable.label = null;
        this.dataTable.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
  
        await getMarcasList({
          sort: sortDirection,
          query: '',
          nr: getCurrentPage,
          perpage: this.dataTable.options.perPage
        });
  
        this.dataTable.on('datatable.perpage', async function (perpage) {
          this.setMessage(`<i class='fas fa-circle-notch fa-spin'></i>`);
          await getMarcasList({
            sort: sortDirection,
            query: searchQuery,
            nr: getCurrentPage = 1,
            perpage: perpage
          });
        });
  
        this.dataTable.on('datatable.sort', async function (column, direction) {
          column = this.headings[column].title;
          direction = direction == "asc" ? "" : "-";
          await getMarcasList({
            sort: sortDirection = direction + column,
            query: searchQuery,
            nr: getCurrentPage,
            perpage: this.options.perPage
          });
        });
      };
  
      
      
    },
    beforeUnmount() {
      sortDirection = "created_at";
      searchQuery = '';
      getCurrentPage = 1;
    },
    methods: {
      async getDataFromPage(page) {
        await getMarcasList({
          sort: sortDirection,
          query: this.search,
          nr: page,
          perpage: this.dataTable.options.perPage
        });
      },
  
      async reactiveSearch() {
        searchQuery = this.search;
        await getMarcasList({
          sort: sortDirection,
          query: this.search,
          nr: getCurrentPage = 1,
          perpage: this.dataTable.options.perPage
        });
      },
  
      async reactivePagination() {
        this.pagination = this.metaPage;
        this.keys = Object.keys(this.pagination);
        
        this.pagination = {
          currentPage: this.pagination[this.keys[0]],
          perPage: this.pagination[this.keys[9]],
          total: this.pagination[this.keys[12]]
        }
        getCurrentPage = this.pagination.currentPage;
        return this.pagination;
      },
  
      async reactiveTable() {
        this.dataMarcas = [];
        if (this.marcasList.length > 0) {
          this.marcasList.forEach(marca => {
            this.dataMarcas.push(
              [   
                `<h6 class="my-auto">${marca.vNombreMarca}</h6>`,
                marca.vPaisOrigen,
                marca.vPersonaContacto,
                marca.vTelefono,
                marca.vCorreoElectronico,
                this.actionEditButton(marca.iIdMarca, "Edit Marca") + this.actionDeleteButton(marca.iIdMarca, "Eliminar Marca")
              ]
            )
          });
          this.dataTable.data = [];
          this.dataTable.refresh();
          this.dataTable.insert({ data: this.dataMarcas });
          this.removeEvent();
          this.eventToCall({
            table: this.dataTable,
            myUserId: null,
            redirectPath: "Edit Marca",
            deletePath: "marcas/deleteMarca",
            getPath: "marcas/getMarcas",
            textDelete: "Marca Eliminada Con Exíto.",
            textDefaultData: 'marcas'
          });
        }
        else {
          this.dataTable.setMessage('No results match your search query');
        }
      },
      
      getImage(user) {
        console.log("user: ", user)
        if (!user.profile_image) return require("@/assets/img/placeholder.jpg")
        else { return user.profile_image }
      },
    }
  
  };
  </script>  