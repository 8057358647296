/* eslint-disable no-unused-vars */
import preventivesService from '../services/preventives.service';

const initialState = { preventives: null, preventive: null };

export const preventives = {
    namespaced: true,
    state: initialState,
    actions: {
        async getPreventives({ commit }, options) {
            console.log("prevevv")
            const preventives = await preventivesService.getPreventives(options);
            commit('getPreventivesSuccess', preventives);
        },
        async addPreventive({ commit }, newPreventive) {
            const preventive = await preventivesService.addPreventive(newPreventive);
            commit('getPreventiveSuccess', preventive);
        },
        async deletePreventive({ commit }, preventiveId) {
            await preventivesService.deletePreventive(preventiveId);
        },
        async getPreventive({ commit }, preventiveId) {
            const preventive = await preventivesService.getPreventive(preventiveId);
            commit('getPreventiveSuccess', preventive);
        },
        async editPreventive({ commit }, modifiedpreventive) {
            console.log(modifiedpreventive);
            await preventivesService.editPreventive(modifiedpreventive); 
        },
    },
    mutations: {
        getPreventivesSuccess(state, preventives) {
            state.preventives = preventives;
        },
        getPreventiveSuccess(state,preventive){
            state.preventive = preventive;
        }
    },
    getters: {
        getPreventivesData(state) {
            return state.preventives?.data;
        },
        getPreventivesMeta(state) {
            return state.preventives?.meta;
        },
        getPreventive(state){
            return state.preventive
        },
    }
}

