import dashboardService from '../services/dashboard.service';

const initialState = {
    dashboard: null,
};

export const dashboard = {
    namespaced: true,
    state: initialState,
    actions: {
        async getDashboard({ commit }) {
            const dashboard = await dashboardService.getDashboard();
            commit('getDashboard', dashboard);
        },
    },
    mutations: {
        getDashboard(state, dashboard) {
            state.dashboard = dashboard;
        },
    },
    getters: {
        getDashboard(state) {
            return state.dashboard;
        },
    },
};
