/* eslint-disable no-unused-vars */
import bitacorasService from '../services/controlLog.service';

const initialState = { correctives: null, corrective: null };

export const controlLog = {
    namespaced: true,
    state: initialState,
    actions: {
        async getBitacoras({ commit }, options) {
            const bitacoras = await bitacorasService.getBitacoras(options);
            commit('getBitacorasSuccess', bitacoras);
        },
        async addBitacora({ commit }, newBitacora) {
            const corrective = await bitacorasService.addBitacora(newBitacora);
            commit('getBitacoraSuccess', corrective);
        },
        async deleteBitacora({ commit }, correctiveId) {
            await bitacorasService.deleteBitacora(correctiveId);
        },
        async getBitacora({ commit }, correctiveId) {
            const corrective = await bitacorasService.getBitacora(correctiveId);
            commit('getBitacoraSuccess', corrective);
        },
        async editBitacora({ commit }, modifiedcorrective) {
            console.log(modifiedcorrective);
            await bitacorasService.editBitacora(modifiedcorrective); 
        },
    },
    mutations: {
        getBitacorasSuccess(state, bitacoras) {
            state.bitacoras = bitacoras;
        },
        getBitacoraSuccess(state,corrective){
            state.corrective = corrective;
        }
    },
    getters: {
        getBitacorasData(state) {
            return state.bitacoras?.data;
        },
        getBitacorasMeta(state) {
            return state.bitacoras?.meta;
        },
        getBitacora(state){
            return state.corrective
        },
    }
}

