<template>
    <section class="container py-4">
        <div class="row m-4">
            <div class="card col-12 mx-auto">
                <div class="card-header pt-2 px-3">
                    <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                        <i class="material-icons opacity-10">event</i>
                    </div>
                    <h6 class="mb-0">Registro de Bitácora de Control</h6>
                </div>
                <div class="card-body">
                    <div class="row d-flex justify-content-between">
                        <div class="col-12 mb-5">
                            <div class="row d-flex justify-content-between">
                                <div class="col-4 mb-3">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="input-group input-group-dynamic">
                                                <select id="iIdUnidad" class="form-control" @change="onChangeUnidad($event)">
                                                    <option selected disabled>Seleccionar Unidad</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-4">
                                            <material-input v-model:value="bitacora.dFechaBitacora" type="date" label="Fecha de la Bitácora" variant="dynamic" class="is-focused" />
                                        </div>
                                        <div class="col-12 mt-4">
                                            <material-input id="vGestor" v-model:value="bitacora.vGestor" label="Gestor" variant="dynamic" name="vGestor"></material-input>
                                        </div>
                                        <div class="col-12 mt-4">
                                            <material-input id="fSocSalida" v-model:value="bitacora.fSocSalida" label="% SOC Salida" variant="dynamic" name="fSocSalida"></material-input>
                                        </div>
                                        <div class="col-12 mt-4">
                                            <material-input id="fSocEntrada" v-model:value="bitacora.fSocEntrada" label="% SOC Entrada" variant="dynamic" name="fSocEntrada"></material-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-7 mb-3">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 mb-3">
                                            <h6 class="mb-0">Equipo:</h6>
                                            <hr class="my-2" />
                                            <div class="form-check form-switch form-check-reverse">
                                                <input id="pantallas" v-model="bitacora.bPantallas" type="checkbox" role="swtich" class="form-check-input" />
                                                <label for="pantallas" class="form-check-label">Pantallas Interior</label>
                                            </div>
                                            <div class="form-check form-switch form-check-reverse">
                                                <input id="camaras" v-model="bitacora.bCamaras" type="checkbox" role="swtich" class="form-check-input" />
                                                <label for="camaras" class="form-check-label">Cámaras</label>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 mb-3">
                                            <h6 class="mb-0">Funciona:</h6>
                                            <hr class="my-2" />
                                            <div class="form-check form-switch form-check-reverse">
                                                <input id="luces" v-model="bitacora.bLuces" type="checkbox" role="swtich" class="form-check-input" />
                                                <label for="luces" class="form-check-label">Luces</label>
                                            </div>
                                            <div class="form-check form-switch form-check-reverse">
                                                <input id="faros" v-model="bitacora.bFaros" type="checkbox" role="swtich" class="form-check-input" />
                                                <label for="faros" class="form-check-label">Faros</label>
                                            </div>
                                            <div class="form-check form-switch form-check-reverse">
                                                <input id="calaveras" v-model="bitacora.bCalaveras" type="checkbox" role="swtich" class="form-check-input" />
                                                <label for="calaveras" class="form-check-label">Calaveras</label>
                                            </div>
                                            <div class="form-check form-switch form-check-reverse">
                                                <input id="interior" v-model="bitacora.bInterior" type="checkbox" role="swtich" class="form-check-input" />
                                                <label for="interior" class="form-check-label">Interior</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="input-group input-group-outline">
                                                <textarea v-model="bitacora.vObservaciones" class="form-control" placeholder="Observaciones" rows="6"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-5">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="d-flex align-items-center justify-content-center mb-3">
                                        <div class="col-12 px-2">
                                            <img src="@/assets/img/derecho.png" alt="down-arrow" class="arrow ms-auto" :style="{ width: '100%', minHeight: '200px', objectFit: 'contain' }"/>
                                        </div>
                                    </div>
                                    <h6 class="mb-0">Costado Derecho</h6>
                                    <hr class="my-2" />
                                    <div class="d-flex align-items-center justify-content-between">
                                        <p class="form-text text-muted ms-1">Punta</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="puntaG" v-model="bitacora.costadoDerecho.aPunta" type="checkbox" :value="'G'">
                                                    <label for="puntaG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="puntaR" v-model="bitacora.costadoDerecho.aPunta" type="checkbox" :value="'R'">
                                                    <label for="puntaR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Entre ejes</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="ejesG" v-model="bitacora.costadoDerecho.aEjes" type="checkbox" :value="'G'">
                                                    <label for="ejesG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="ejesR" v-model="bitacora.costadoDerecho.aEjes" type="checkbox" :value="'R'">
                                                    <label for="ejesR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Puerta</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="puertaG" v-model="bitacora.costadoDerecho.aPuerta" type="checkbox" :value="'G'">
                                                    <label for="puertaG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="puertaR" v-model="bitacora.costadoDerecho.aPuerta" type="checkbox" :value="'R'">
                                                    <label for="puertaR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Cola</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="colaG" v-model="bitacora.costadoDerecho.aCola" type="checkbox" :value="'G'">
                                                    <label for="colaG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="colaR" v-model="bitacora.costadoDerecho.aCola" type="checkbox" :value="'R'">
                                                    <label for="colaR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="d-flex align-items-center justify-content-center mb-3">
                                        <div class="col-12 px-2">
                                            <img src="@/assets/img/frente.png" alt="down-arrow" class="arrow ms-auto" :style="{ width: '100%', minHeight: '200px', maxHeight: '200px', objectFit: 'contain' }"/>
                                        </div>
                                    </div>
                                    <h6 class="mb-0">Frente</h6>
                                    <hr class="my-2" />
                                    <div class="d-flex align-items-center justify-content-between">
                                        <p class="form-text text-muted ms-1">Medallon</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="medallonG" v-model="bitacora.frente.aMedallon" type="checkbox" :value="'G'">
                                                    <label for="medallonG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="medallonR" v-model="bitacora.frente.aMedallon" type="checkbox" :value="'R'">
                                                    <label for="medallonR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Panorámico</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="panomaricoG" v-model="bitacora.frente.aPanoramico" type="checkbox" :value="'G'">
                                                    <label for="panomaricoG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="panomaricoR" v-model="bitacora.frente.aPanoramico" type="checkbox" :value="'R'">
                                                    <label for="panomaricoR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Parte Interior</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="interiorG" v-model="bitacora.frente.aInferior" type="checkbox" :value="'G'">
                                                    <label for="interiorG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="interiorR" v-model="bitacora.frente.aInferior" type="checkbox" :value="'R'">
                                                    <label for="interiorR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="d-flex align-items-center justify-content-center mb-3">
                                        <div class="col-12 px-2">
                                            <img src="@/assets/img/cola.png" alt="down-arrow" class="arrow ms-auto" :style="{ width: '100%', minHeight: '200px', maxHeight: '200px', objectFit: 'contain' }"/>
                                        </div>
                                    </div>
                                    <h6 class="mb-0">Cola</h6>
                                    <hr class="my-2" />
                                    <div class="d-flex align-items-center justify-content-between">
                                        <p class="form-text text-muted ms-1">Panorámico</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="panoramicoG" v-model="bitacora.cola.aPanoramico" type="checkbox" :value="'G'">
                                                    <label for="panoramicoSG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="panoramicoR" v-model="bitacora.cola.aPanoramico" type="checkbox" :value="'R'">
                                                    <label for="panoramicoSR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Tapa de motor</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="tapaMotorG" v-model="bitacora.cola.aTapaMotor" type="checkbox" :value="'G'">
                                                    <label for="tapaMotorG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="tapaMotorR" v-model="bitacora.cola.aTapaMotor" type="checkbox" :value="'R'">
                                                    <label for="tapaMotorR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Calaveras</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="calaverasG" v-model="bitacora.cola.aCalaveras" type="checkbox" :value="'G'">
                                                    <label for="calaverasG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="calaverasR" v-model="bitacora.cola.aCalaveras" type="checkbox" :value="'R'">
                                                    <label for="calaverasR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Parte Inferior</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="inferiorG" v-model="bitacora.cola.aInferior" type="checkbox" :value="'G'">
                                                    <label for="inferiorG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="inferiorR" v-model="bitacora.cola.aInferior" type="checkbox" :value="'R'">
                                                    <label for="inferiorR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-3">
                                    <div class="d-flex align-items-center justify-content-center mb-3">
                                        <div class="col-12 px-2">
                                            <img src="@/assets/img/izquierdo.png" alt="down-arrow" class="arrow ms-auto" :style="{ width: '100%', minHeight: '200px', objectFit: 'contain' }"/>
                                        </div>
                                    </div>
                                    <h6 class="mb-0">Costado Izquierdo</h6>
                                    <hr class="my-2" />
                                    <div class="d-flex align-items-center justify-content-between">
                                        <p class="form-text text-muted ms-1">Punta</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="puntaG" v-model="bitacora.costadoIzquierdo.aPunta" type="checkbox" :value="'G'">
                                                    <label for="puntaG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="puntaR" v-model="bitacora.costadoIzquierdo.aPunta" type="checkbox" :value="'R'">
                                                    <label for="puntaR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Entre ejes</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="ejesG" v-model="bitacora.costadoIzquierdo.aEjes" type="checkbox" :value="'G'">
                                                    <label for="ejesG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="ejesR" v-model="bitacora.costadoIzquierdo.aEjes" type="checkbox" :value="'R'">
                                                    <label for="ejesR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">                                        
                                        <p class="form-text text-muted ms-1">Cola</p>
                                        <div class="col">
                                            <div class="d-flex justify-content-end">
                                                <div class="form-check form-check-reverse">
                                                    <input id="colaG" v-model="bitacora.costadoIzquierdo.aCola" type="checkbox" :value="'G'">
                                                    <label for="colaG" class="form-check-label">G</label>
                                                </div>
                                                <div class="form-check form-check-reverse">
                                                    <input id="colaR" v-model="bitacora.costadoIzquierdo.aCola" type="checkbox" :value="'R'">
                                                    <label for="colaR" class="form-check-label">R</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-5">
                            <hr class="hr">
                            <div class="row">
                                <div class="col-12 mt-3">
                                    <div class="d-flex flex-row justify-content-between">
                                        <h6 class="mb-0">Control de Salidas</h6>
                                        <material-button type="button" class="float-right btn btm-sm" data-bs-toggle="modal" data-bs-target="#modalActivity">Agregar Salida</material-button>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="table-responsive">
                                        <table class="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th class="title-column ps-2"># Vuelta</th>
                                                    <th class="title-column ps-2">KM Inicial</th>
                                                    <th class="title-column ps-2">% SOC Inicial</th>
                                                    <th class="title-column ps-2">Lugar de Salida</th>
                                                    <th class="title-column ps-2">Nombre y Clave</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in bitacora.lSalidas" :key="item.iIdControlSalida">
                                                    <td><p class="table-p mb-0">{{ item.iVuelta }}</p></td>
                                                    <td><p class="table-p mb-0">{{ item.iKmInicial }}</p></td>
                                                    <td><p class="table-p mb-0">{{ item.fSocInicial }}</p></td>
                                                    <td><p class="table-p mb-0">{{ item.vLugarSalida }}</p></td>
                                                    <td><p class="table-p mb-0">{{ item.vNombre }}</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex justify-content-end gap-3">
                                <button type="button" name="button" class="btn btn-danger" @click="this.$router.push({ name: 'ControlLogList' })">Cancelar</button>
                                <button type="button" name="button" class="btn btn-success" @click="onCreate">Crear Bitácora</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div id="modalActivity" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalActivityLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="modalActivityLabel" class="modal-title font-weight-normal">Registro de Salida</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mb-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row mb-3">
                                <div class="col-12 mt-2">
                                    <material-input id="iVuelta" v-model:value="salida.iVuelta" label="Número de vuelta" variant="static" name="iVuelta"></material-input>
                                </div>
                                <div class="col-12 mt-2">
                                    <material-input id="iKmInicial" v-model:value="salida.iKmInicial" label="Km Inicial" variant="static" name="iKmInicial"></material-input>
                                </div>
                                <div class="col-12 mt-2">
                                    <material-input id="vNombre" v-model:value="salida.vNombre" label="Nombre y clave" variant="static" name="vNombre"></material-input>
                                </div>
                                <div class="col-12 mt-2">
                                    <material-input id="fSocInicial" v-model:value="salida.fSocInicial" label="% SOC Inicial" variant="static" name="fSocInicial"></material-input>
                                </div>
                                <div class="col-12 mt-2">
                                    <material-input id="vLugarSalida" v-model:value="salida.vLugarSalida" label="Lugar de Salida" variant="static" name="vLugarSalida"></material-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <material-button class="float-right btn btm-sm bg-gradient-secondary" data-bs-dismiss="modal">Close</material-button>
                    <material-button class="float-right btn btm-sm btn-success" @click.prevent="addDetail()">Agregar</material-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Choices from 'choices.js';
import MaterialInput from '@/components/MaterialInput.vue';
import formMixin from '@/mixins/formMixin.js';
import showSwal from '@/mixins/showSwal.js';

export default {
    name: 'AddControlLog',
    components: {
        MaterialInput,
    },
    mixins: [formMixin, showSwal],
    data() {
        return {
            bitacora: {
                iIdBitacoraControl: 0,
                iIdUnidad: 0,
                dFechaBitacora: '',
                vGestor: '',
                vObservaciones: '',
                fSocSalida: null,
                fSocEntrada: null,
                bPantallas: false,
                bCamaras: false,
                bLuces: false,
                bFaros: false,
                bCalaveras: false,
                bInterior: false,
                costadoDerecho: {
                    iIdBitacoraControlDerecho: 0,
                    aPunta: [],
                    aEjes: [],
                    aPuerta: [],
                    aCola: [],
                },
                frente: {
                    iIdBitacoraControlFrente: 0,
                    aMedallon: [],
                    aPanoramico: [],
                    aInferior: [],
                },
                cola: {
                    iIdBitacoraControlCola: 0,
                    aPanoramico: [],
                    aTapaMotor: [],
                    aCalaveras: [],
                    aInferior: [],
                },
                costadoIzquierdo: {
                    iIdBitacoraControlIzquierdo: 0,
                    aPunta: [],
                    aEjes: [],
                    aCola: [],
                },
                lSalidas: []
            },
            unidad: {
                vSerie: '',
                vChasis: '',
                vPlaca: '',
                vNumEconomico: '',
                vNumConcesion: '',
                modelo: {
                    vNombre: '',
                    iCapacidadTotal: 0,
                    marca: {
                        vNombreMarca: '',
                    },
                    tipo: {
                        vNombre: '',
                    },
                },
            },
            salida: {
                iIdBitacoraControl: 0,
                iIdControlSalida: 0,
                iVuelta: 1,
                iKmInicial: "0",
                vNombre: "",
                fSocInicial: 100,
                vLugarSalida: "",
            },
        };
    },
    mounted() {
        this.getUnidades();
    },
    methods: {
        async getUnidades() {
            await this.$store.dispatch('inspections/getUnidades');
            const unidades = this.$store.getters['inspections/getUnidades'];

            new Choices('#iIdUnidad', { searchEnabled: true }).setChoices(unidades, 'iIdUnidad', 'vNumEconomico');
        },
        onChangeUnidad(event) {
            this.bitacora.iIdUnidad = Number(event.target.value);
            console.log(this.bitacora);
        },
        addDetail() {
            this.bitacora.lSalidas.push(this.salida);
            this.salida = {
                iVuelta: 1,
                iKmInicial: "",
                vNombre: "",
                fSocInicial: 0,
                vLugarSalida: ""
            };

            this.addModal.hide();
            document.querySelector('.modal-backdrop').remove();
        },
        async onCreate() {
            try {
                await this.$store.dispatch("controlLog/addBitacora", this.bitacora);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Registro exitoso!",
                    width: 500
                });
                this.$router.push({ name: "AddControlLog" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Ocurrió un error, contacte con el administrador.",
                    width: 500
                });
            }
        },
    },
};
</script>
