<template>
    <div class="col-12 col-md-6">
        <div class="card h-100">
            <div class="card-body">
                <div class="d-flex flex-column">
                    <h6>Mantenimientos por tipo de unidad</h6>
                    <ul class="list-group list-group-flush">
                        <li v-for="(mantenimiento, tipo) in mantenimientos" :key="tipo" class="list-group-item">
                            <div class="row">
                                <div class="col-4">
                                    <div class="d-flex flex-column">
                                        <h6 class="text-xs fw-bold mb-2">Tipo unidad:</h6>
                                        <p class="text-sm fw-normal">{{ tipo }}</p>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="d-flex flex-column align-items-center">
                                        <h6 class="text-xs fw-bold mb-2">Mantenimientos:</h6>
                                        <p class="text-sm fw-normal">{{ mantenimiento.total }}</p>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="d-flex flex-column align-items-center">
                                        <h6 class="text-xs fw-bold mb-2">Porcentaje:</h6>
                                        <p class="text-sm fw-normal">{{ mantenimiento.porcentaje }}%</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MantenimientoUnidadTableCard',
    props: {
        mantenimientos: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
