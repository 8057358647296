<template>
    <div class="row g-3">
        <MantenimientoUnidadChartCard v-if="Object.keys(mantenimientos).length > 0" :mantenimientos="mantenimientos" />
        <MantenimientoUnidadTableCard :mantenimientos="mantenimientos" />
    </div>
</template>

<script>
import axios from 'axios';
import MantenimientoUnidadChartCard from './MantenimientoUnidadChartCard.vue';
import MantenimientoUnidadTableCard from './MantenimientoUnidadTableCard.vue';

export default {
    name: 'MantenimientoUnidadCards',
    components: {
        MantenimientoUnidadChartCard,
        MantenimientoUnidadTableCard,
    },
    data() {
        return {
            mantenimientos: {},
        };
    },
    mounted() {
        this.getMantenimientos();
    },
    methods: {
        async getMantenimientos() {
            const { data } = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/unidad`);
            this.mantenimientos = data;
        },
    },
};
</script>
