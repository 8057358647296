<template>
    <nav class="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 border-radius-xl z-index-sticky blur shadow-blur left-auto" data-scroll="true">
        <div class="container-fluid py-1 px-3">
            <nav aria-label="breadcrumb">
                <h4 class="font-weight-bolder mb-0">ATY - Agencia de Transporte de Yucatán</h4>
                <h6 class="font-weight-bolder mb-0">CONTROL DE MANTENIMIENTO EN UNIDADES DE TRANSPORTE</h6>
            </nav>
        </div>
    </nav>
    <div class="container-fluid py-2">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card" style="background-color: #262f44">
                    <div class="card-body">
                        <div class="row gy-3">
                            <div class="col-12">
                                <MantenimientoTotalCards />
                            </div>
                            <div class="col-12">
                                <MantenimientoUnidadCards />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MantenimientoTotalCards from './components/MantenimientoTotalCards.vue';
import MantenimientoUnidadCards from './components/MantenimientoUnidadCards.vue';

export default {
    name: 'Dashboard',
    components: {
        MantenimientoTotalCards,
        MantenimientoUnidadCards,
    },
};
</script>
