<template>
    <section class="container py-4">
        <div class="row m-4">
            <div class="card col-10 mx-auto">
                <div class="card-header pt-2 px-3">
                    <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                        <i class="material-icons opacity-10">event</i>
                    </div>
                    <h6 class="mb-0">Registro de inspección de la unidad.</h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 mb-6">
                            <div class="row">
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.vPlaca"
                                        label="Unidad"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.vPlaca }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.vNumEconomico"
                                        label="Número Económico"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.vNumEconomico }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.vNumConcesion"
                                        label="Número de Concesión"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.vNumConcesion }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.modelo.marca.vNombreMarca"
                                        label="Marca"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.modelo.marca.vNombreMarca }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.modelo.tipo.vNombre"
                                        label="Tipo"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.modelo.tipo.vNombre }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.modelo.vNombre"
                                        label="Modelo"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.modelo.vNombre }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.vPlaca"
                                        label="Placa Actual"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.vPlaca }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.modelo.iCapacidadTotal"
                                        label="Capacidad"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.modelo.iCapacidadTotal }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input
                                        v-model:value="inspeccion.unidad.vSerie"
                                        label="Número de Serie"
                                        variant="dynamic"
                                        :class="{ 'is-focused': inspeccion.unidad.vSerie }"
                                        disabled
                                    />
                                </div>
                                <div class="col-12 col-sm-6 mt-4">
                                    <material-input v-model:value="inspeccion.dFechaInspeccion" type="date" label="Fecha de la Inspección" variant="dynamic" class="is-focused" />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mb-6">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-lg-4">
                                    <h6 class="mb-0">Funcionamiento del Vehículo</h6>
                                    <p class="form-text text-muted ms-1">(Revisión en operación)</p>
                                    <hr class="my-2" />
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="cinturones" v-model="inspeccion.funcionamiento.iCinturones" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="cinturones" class="form-check-label">Cinturones</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="frenos-abs" v-model="inspeccion.funcionamiento.iFrenosAbs" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="frenos-abs" class="form-check-label">Frenos Abs</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="escape" v-model="inspeccion.funcionamiento.iEscape" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="escape" class="form-check-label">Escape</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="imagen-completa" v-model="inspeccion.funcionamiento.iImagenCompleta" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="imagen-completa" class="form-check-label">Imagen Completa</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input
                                            id="limpia-parabrisas"
                                            v-model="inspeccion.funcionamiento.iLimpiaParabrisas"
                                            type="checkbox"
                                            role="swtich"
                                            class="form-check-input"
                                        />
                                        <label for="limpia-parabrisas" class="form-check-label">Limpia Parabrisas</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="claxon" v-model="inspeccion.funcionamiento.iClaxon" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="claxon" class="form-check-label">Claxón</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input
                                            id="aire-acondicionado"
                                            v-model="inspeccion.funcionamiento.iAireAcondicionado"
                                            type="checkbox"
                                            role="swtich"
                                            class="form-check-input"
                                        />
                                        <label for="aire-acondicionado" class="form-check-label">Aire Acondicionado</label>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4">
                                    <h6 class="mb-0">Estado Físico de la Unidad</h6>
                                    <p class="form-text text-muted ms-1">(Revisión Estática)</p>
                                    <hr class="my-2" />
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="defensas-y-carroceria" v-model="inspeccion.fisica.iDefensasCarroceria" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="defensas-y-carroceria" class="form-check-label">Defensas y Carroceria</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="piso" v-model="inspeccion.fisica.iPiso" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="piso" class="form-check-label">Piso</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="puertas" v-model="inspeccion.fisica.iPuertas" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="puertas" class="form-check-label">Puertas</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="pintura" v-model="inspeccion.fisica.iPintura" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="pintura" class="form-check-label">Pintura</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="micas-luces" v-model="inspeccion.fisica.iMicasLuces" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="micas-luces" class="form-check-label">Micas de Luces</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="espejos-retrovisores" v-model="inspeccion.fisica.iEspejosRetrovisores" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="espejos-retrovisores" class="form-check-label">Espejos Retrovisores</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="panoramico" v-model="inspeccion.fisica.iPanoramico" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="panoramico" class="form-check-label">Panorámico</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="ventanillas" v-model="inspeccion.fisica.iVentanillas" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="ventanillas" class="form-check-label">Ventanillas</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="llantas" v-model="inspeccion.fisica.iLlantas" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="llantas" class="form-check-label">Llantas</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="asientos" v-model="inspeccion.fisica.iAsientos" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="asientos" class="form-check-label">Asientos</label>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4">
                                    <h6 class="mb-0">Luces</h6>
                                    <p class="form-text text-muted ms-1">(Revisión en operación)</p>
                                    <hr class="my-2" />
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="cuartos" v-model="inspeccion.luces.iCuartos" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="cuartos" class="form-check-label">Cuartos</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="alta-baja" v-model="inspeccion.luces.iAltaBaja" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="alta-baja" class="form-check-label">Alta/Baja</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="direccionales" v-model="inspeccion.luces.iDireccionales" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="direccionales" class="form-check-label">Direccionales</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="advertencia" v-model="inspeccion.luces.iAdvertencia" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="advertencia" class="form-check-label">Advertencia</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="frenado" v-model="inspeccion.luces.iFrenado" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="frenado" class="form-check-label">Frenado</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="reversa" v-model="inspeccion.luces.iReversa" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="reversa" class="form-check-label">Reversa</label>
                                    </div>
                                    <div class="form-check form-switch form-check-reverse">
                                        <input id="placas" v-model="inspeccion.luces.iPlacas" type="checkbox" role="swtich" class="form-check-input" />
                                        <label for="placas" class="form-check-label">Placas</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mb-6">
                            <div class="input-group input-group-outline">
                                <textarea v-model="inspeccion.vComentarios" class="form-control" placeholder="Observaciones" rows="4"></textarea>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex justify-content-end gap-3">
                                <button type="button" name="button" class="btn btn-danger" @click="this.$router.push({ name: 'InspectionList' })">Cancelar</button>
                                <button type="button" name="button" class="btn btn-success" @click="onUpdate">Actualizar Inspección</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import MaterialInput from '@/components/MaterialInput.vue';
import formMixin from '@/mixins/formMixin.js';
import showSwal from '@/mixins/showSwal';

export default {
    name: 'EditInspection',
    components: {
        MaterialInput,
    },
    mixins: [formMixin, showSwal],
    data() {
        return {
            inspeccion: {
                iIdInspeccion: 0,
                dFechaInspeccion: '',
                vComentarios: '',
                funcionamiento: {
                    iCinturones: false,
                    iFrenosAbs: false,
                    iEscape: false,
                    iImagenCompleta: false,
                    iLimpiaParabrisas: false,
                    iClaxon: false,
                    iAireAcondicionado: false,
                },
                fisica: {
                    iDefensasCarroceria: false,
                    iPiso: false,
                    iPuertas: false,
                    iPintura: false,
                    iMicasLuces: false,
                    iEspejosRetrovisores: false,
                    iPanoramico: false,
                    iVentanillas: false,
                    iLlantas: false,
                    iAsientos: false,
                },
                luces: {
                    iCuartos: false,
                    iAltaBaja: false,
                    iDireccionales: false,
                    iAdvertencia: false,
                    iFrenado: false,
                    iReversa: false,
                    iPlacas: false,
                },
                unidad: {
                    vSerie: '',
                    vChasis: '',
                    vPlaca: '',
                    vNumEconomico: '',
                    vNumConcesion: '',
                    modelo: {
                        vNombre: '',
                        iCapacidadTotal: 0,
                        marca: {
                            vNombreMarca: '',
                        },
                        tipo: {
                            vNombre: '',
                        },
                    },
                },
            },
        };
    },
    mounted() {
        this.getInspection(+this.$route.params.id);
    },
    methods: {
        async getInspection(id) {
            await this.$store.dispatch('inspections/getInspeccion', id);
            const inspeccion = this.$store.getters['inspections/getInspeccion'];

            if (inspeccion) {
                this.inspeccion = inspeccion;
            }
        },
        async onUpdate() {
            try {
                await this.$store.dispatch('inspections/updateInspeccion', this.inspeccion);
                const message = this.$store.getters['inspections/updateInspeccion'];

                showSwal.methods.showSwal({
                    type: 'success',
                    message: message,
                    width: 500,
                });

                this.$router.push({ name: 'InspectionList' });
            } catch (error) {
                showSwal.methods.showSwal({
                    type: 'error',
                    message: error.message,
                    width: 500,
                });
            }
        },
    },
};
</script>
