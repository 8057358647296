import axios from 'axios';
import authHeader from './auth-header';

const URL = `${process.env.VUE_APP_API_BASE_URL}/bitacoras/inicial`;

export default {
    async getBitacorasInicial() {
        try {
            const { data } = await axios.get(URL);
            return data;
        } catch (error) {
            console.log('Bitácora inicial service:', error);
            return [];
        }
    },
    async createBitacoraInicial(bitacora) {
        try {
            const { data } = await axios.post(URL, bitacora, { headers: authHeader() });
            return data;
        } catch (error) {
            let message = 'Ocurrió un error al guardar la bitácora inicial.';

            if (error.response.status === 422) {
                message = error.response.data.message;
            }

            throw new Error(message);
        }
    },
    async getBitacoraInicial(id) {
        try {
            const { data } = await axios.get(`${URL}/${id}`);
            return data;
        } catch (error) {
            let message = 'Ocurrio un error al obtener la bitácora inicial.';

            if (error.response.status === 422) {
                message = error.response.data.message;
            }

            return message;
        }
    },
    async updateBitacoraInicial(bitacora) {
        try {
            const { data } = await axios.put(`${URL}/${bitacora.iIdBitacoraInicial}`, bitacora, { headers: authHeader() });
            return data;
        } catch (error) {
            let message = 'Ocurrió un error al actualizar la bitácora inicial.';

            if (error.response.status === 422) {
                message = error.response.data.message;
            }

            throw new Error(message);
        }
    },
    async deleteBitacoraInicial(id) {
        try {
            const { data } = await axios.delete(`${URL}/${id}`, null, { headers: authHeader() });
            return data;
        } catch (error) {
            let message = 'Oops, Ocurrio un error al crear la inspección.';

            if (error.response.status === 422) {
                message = error.response.data.message;
            }

            return message;
        }
    },
    async getUnidades() {
        try {
            const { data } = await axios.get(`${URL}/unidades`);
            return data;
        } catch (error) {
            console.log('Bitácora inicial service:', error);
            return [];
        }
    },
};
