<template>
    <aside id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3" :class="`${isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'} ${sidebarType}`">
        <div class="sidenav-header pe-10">
            <i id="iconSidenav" class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none" aria-hidden="true"></i>
            <router-link class="m-0 navbar-brand" to="/">
                <img :src="logo" class="logo me-3" alt="main_logo"/>
                <span class="font-weight-bold text-white">ATY</span>
            </router-link>
        </div>
        <hr class="horizontal light mt-0 mb-2" />
        <sidenav-list />
    </aside>
</template>
<script>
    import SidenavList from "./SidenavList.vue";
    import logo from "@/assets/img/aty-logo.png";
    import logoDark from "@/assets/img/aty-logo.png";
    import { mapState } from "vuex";
    export default {
        name: "Index",
        components: {
            SidenavList,
        },
        data() {
            return {
                logo,
                logoDark,
            };
        },
        computed: {
            ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
        },
    };
</script>
<style>
    aside {
        background-image: linear-gradient(195deg, #283147 0%, #191919 100%) !important;
    }
</style>
