<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 plan">
            <div class="col-12">
                <div class="plan d-flex align-items-center">
                    <div class="col-6">
                        <h5 class="mb-4">Mantenimientos correctivos</h5>
                    </div>
                    <div class="col-6 text-end"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-9">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <div class="input-group input-group-outline bg-white">
                                <select v-model="iIdEmpresa" class="form-control" name="iIdEmpresa" placeholder="Selecciona" @change="onChangeEmpresa($event)">
                                    <option selected value="">Seleccione una empresa</option>
                                    <option v-for="item in empresas" :key="item.iIdEmpresa" :value="item.iIdEmpresa">
                                        {{ item.vEmpresa }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="input-group input-group-outline bg-white">
                                <select v-model="iIdMarca" class="form-control" name="iIdMarca" placeholder="Selecciona" @change="onChangeMarca($event)">
                                    <option selected value="">Seleccione una marca</option>
                                    <option v-for="item in marcas" :key="item.iIdMarca" :value="item.iIdMarca">
                                        {{ item.vNombreMarca }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="input-group input-group-outline bg-white">
                                <select v-model="iIdModelo" class="form-control" name="iIdModelo" placeholder="Selecciona" @change="onChangeModelo($event)">
                                    <option selected value="">Seleccione un modelo</option>
                                    <option v-for="item in modelos" :key="item.iIdModelo" :value="item.iIdModelo">
                                        {{ item.vNombre }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="input-group input-group-outline bg-white">
                                <select v-model="iIdUnidad" class="form-control" name="iIdUnidad" placeholder="Selecciona" @change="onChangeUnidad($event)">
                                    <option selected value="">Seleccione una unidad</option>
                                    <option v-for="item in unidades" :key="item.iIdUnidad" :value="item.iIdUnidad">
                                        No. eco: {{ item.vNumEconomico }} / Placa: {{ item.vPlaca }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="d-flex justify-content-between">
                        <a href="#" class="btn btn-success" role="button" aria-pressed="true" @click.prevent="getCorrectives(filters)">Filtrar</a>
                        <material-button class="float-right btn btm-sm btn-success" @click="this.$router.push({ name: 'AddCorrective' })">Agregar Nuevo</material-button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="title-column ps-2">Marca</th>
                                        <th class="title-column ps-2">Modelo</th>
                                        <th class="title-column ps-2">Serie</th>
                                        <th class="title-column ps-2 text-center">Placa</th>
                                        <th class="title-column ps-2 text-center">Año</th>
                                        <th class="title-column ps-2 text-center">Fecha Ingreso</th>
                                        <th class="title-column ps-2 text-center">Fecha Estimada</th>
                                        <th class="title-column ps-2 text-center">Fecha Egreso</th>
                                        <th class="title-column ps-2 text-center">Costo</th>
                                        <th class="title-column ps-2">Comentarios</th>
                                        <th class="title-column ps-2 text-center">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in correctiveList" :key="item.iIdMantenimiento">
                                        <td><p class="table-p">{{ item.vNombreModelo }}</p></td>
                                        <td><p class="table-p">{{ item.vNombreMarca }}</p></td>
                                        <td><p class="table-p">{{ item.vSerie }}</p></td>
                                        <td><p class="table-p text-center">{{ item.vPlaca }}</p></td>
                                        <td><p class="table-p text-center">{{ item.iAnioFabricacion }}</p></td>
                                        <td><p class="table-p text-center">{{ item.dFechaIngreso == null ? "No Aplica" : item.dFechaIngreso }}</p></td>
                                        <td><p class="table-p text-center">{{ item.dFechaEstimada == null ? "No Aplica" : item.dFechaEstimada }}</p></td>
                                        <td><p class="table-p text-center">{{ item.dFechaEgreso == null ? "No Aplica" : item.dFechaEgreso }}</p></td>
                                        <td><p class="table-p text-end">${{ formatPrice(item.fCosto) }}</p></td>
                                        <td><p class="table-p">{{ (item.vComentarios != "") ? item.vComentarios : 'Sin comentarios' }}</p></td>
                                        <td class="text-center">
                                            <div class="col-md-12 m-0 text-center">
                                                <div class="d-flex align-items-center justify-content-end">
                                                    <material-button class="float-right btn btm-sm btn-success mx-auto" @click="this.$router.push({ name: 'EditCorrective', params: { id: item.iIdMantenimiento } })">Detalle</material-button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// import Choices from "choices.js";
import axios from 'axios';
import authHeader from '../../../services/auth-header';


const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: "CorrectiveList",
    components: {
        
    },
    data() {
        return {
            search: '',
            iIdMarca: '',
            iIdModelo: '',
            iIdUnidad: '',
            iIdEmpresa: '',
            correctiveList: [],
            empresas: [],
            marcas: [],
            modelos: [],
            unidadesAll: [],
            unidades: [],
            historial: [],
            filters: {
                iIdMarca: null,
                iIdModelo: null,
                iIdUnidad: null,
            }
        }
    },
    async mounted() {
        this.getCorrectives(this.filters);
        this.getEmpresas();
        this.getMarcas();
        this.getModelos(0);
        this.getUnidades(0);
    },
    methods: {
        getCorrectives(filters) {
            this.correctiveList = [];
            
            axios.get(API_URL + "/correctives", { headers: authHeader(), params: { iIdMarca: filters.iIdMarca, iIdModelo: filters.iIdModelo, iIdUnidad: filters.iIdUnidad } }).then(res => {
				this.correctiveList = res.data;
			});
        },
        getEmpresas() {
			axios.get(API_URL + "/companies/all", { headers: authHeader() }).then(res => {
				this.empresas = res.data;
			});
		},
        getMarcas() {
			axios.get(API_URL + "/unidad/obtener-marcas").then(res => {
				this.marcas = res.data;
			});
		},
		getModelos(idMarca) {
            this.modelos = [];

			axios.get(API_URL + "/modelos/marca/" + idMarca).then(res => {
				this.modelos = res.data;
			});
		},
        getUnidades(iIdModelo) {
            this.unidades = [];

			axios.get(API_URL + "/unidades/modelo/" + iIdModelo, { headers: authHeader() }).then(res => {
				this.unidades    = res.data;
				this.unidadesAll = res.data;
			});
		},
        getHistorial(idUnidad) {
            this.historial = [];

			axios.get(API_URL + "/correctives/unidad/" + idUnidad).then(res => {
				this.historial = res.data;
			});
		},
        onChangeEmpresa(event) {
            this.iIdEmpresa = event.target.value;

            if(this.iIdEmpresa > 0) {
                this.unidades = this.unidadesAll.filter(x => x.iIdEmpresa == this.iIdEmpresa);
            } else {
                this.unidades = this.unidadesAll;
            }
		},
        onChangeMarca(event) {
            this.iIdMarca = event.target.value;

            this.filters.iIdMarca  = this.iIdMarca == '' ? null : this.iIdMarca;
            this.filters.iIdModelo = this.iIdModelo == '' ? null : this.iIdModelo;
            this.filters.iIdUnidad = this.iIdUnidad == '' ? null : this.iIdUnidad;

			this.getModelos(this.iIdMarca == '' ? 0 : this.iIdMarca);
		},
        onChangeModelo(event) {
            this.iIdModelo = event.target.value == '' ? null : event.target.value;

            this.filters.iIdMarca  = this.iIdMarca == '' ? null : this.iIdMarca;
            this.filters.iIdModelo = this.iIdModelo == '' ? null : this.iIdModelo;
            this.filters.iIdUnidad = this.iIdUnidad == '' ? null : this.iIdUnidad;

            this.getUnidades(this.iIdModelo == '' ? 0 : this.iIdModelo);
		},
        onChangeUnidad(event) {
            this.iIdUnidad = event.target.value;

            this.filters.iIdMarca  = this.iIdMarca == '' ? null : this.iIdMarca;
            this.filters.iIdModelo = this.iIdModelo == '' ? null : this.iIdModelo;
            this.filters.iIdUnidad = this.iIdUnidad == '' ? null : this.iIdUnidad;
		},
        formatPrice(value) {
            let val = (value/1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getStatus(value) {
            switch (value) {
                case 1:
                    return "Pendiente";
                case 2:
                    return "Completado";
                default:
                    return "Pendiente";
            }
        }
    }
};
</script>
<style>
    .title-column {
        text-transform: uppercase !important;
        color: #7b809a !important;
        opacity: 0.7 !important;
        font-weight: 700 !important;
        font-size: 0.65rem !important;
    }
    .table-p {
        font-weight: 400 !important;
        font-size: 0.75rem !important;
        line-height: 1.25 !important;
        margin-bottom: 0 !important;
    }
</style>
