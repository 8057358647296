<!-- <template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Edit Company</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Companies List' })">
                                    Back to list
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-10">
                                    <material-input id="name" v-model:value="company.name" label="Name" name="name"
                                        variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>
                            </div>
                            <div class="row mt-4 mb-5 d-flex align-items-end">
                                <div class="col-10">
                                <material-input id="description" v-model:value="company.description" label="Description"
                                        name="description" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.description"></validation-error>
                                </div>
                                <div class="col-2 text-end">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Edit Company</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> -->
<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <!--form panels-->
                                <div class="row">
                                    <div class="col-12 col-lg-10 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">event</i>
                                                </div>
                                                <h6 class="mb-0">Editar Empresa</h6>
                                            </div>
                                            <div class="card-body">
                                                <form class="multisteps-form__form">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vEmpresa" v-model:value="company.vEmpresa" label="Empresa" variant="static" name="vEmpresa"></material-input>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vNombreCorto" v-model:value="company.vNombreCorto" label="Nombre Corto" variant="static" name="vNombreCorto"></material-input>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vNombreEnlace" v-model:value="company.vNombreEnlace" label="Nombre enlace" variant="static" name="vNombreEnlace"></material-input>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vTelefonoEnlace" v-model:value="company.vTelefonoEnlace" label="Contacto de Enlace" variant="static" name="vTelefonoEnlace"></material-input>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vCorreoEnlace" v-model:value="company.vCorreoEnlace" label="Email de Enlace" variant="static" name="vCorreoEnlace"></material-input>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vUbicacionEncierro" v-model:value="company.vUbicacionEncierro" label="Ubicación Encierro" variant="static" name="vUbicacionEncierro"></material-input>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-md-12 d-flex justify-content-end mt-3">
                                                            <material-button class="float-right btn btm-sm btn-danger me-3" @click="this.$router.push({ name: 'Companies List' })">Cancelar</material-button>
                                                            <material-button class="float-right btn btm-sm" @click.prevent="update">Guardar</material-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

import companiesService from '../../../services/companies.service';

export default {
    name: 'EditCompany',
    components: {
        MaterialButton,
        MaterialInput,
    },
    mixins: [formMixin],
    data() {
        return {
            company: {
                vEmpresa: '',
                vNombreCorto: '',
                vNombreEnlace: '',
                vTelefonoEnlace: '',
                vCorreoEnlace: '',
                vUbicacionEncierro: '',
                iActivo: true,
            },
            errors: {}
        }
    },
    async mounted() {
        await this.getData(this.$route.params.id);
    },
    methods: {
        async getData(id) {
            this.company = await companiesService.getCompany(id);
        },
        async update() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch("companies/editCompany", this.company);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Company added successfully!",
                    width: 500
                });
                this.$router.push({ name: "Companies List" });
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Ocurrió un error, contacte con el administrador.",
                    width: 500
                });

            }
        }
    }
}
</script>