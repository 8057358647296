<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <div class="row">
                                    <div class="col-12 col-lg-9 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">event</i>
                                                </div>
                                                <h6 class="mb-0">Registro de mantenimiento preventivo</h6>
                                            </div>
                                            <div class="card-body">
                                                <div class="row mt-4">
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Placa</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.vPlaca }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Num Econónico</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.vNumEconomico }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Marca</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.NombreMarca }}
                                                        </p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Modelo</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.NombreModelo }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Año</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.iAnioFabricacion }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Serie</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.vSerie }}</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Empresa</h6>
                                                        <p class="text-sm text-secondary mb-0">ADO</p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Odómetro</h6>
                                                        <p class="text-sm text-secondary mb-0">{{ unidad.iKmActual }}</p>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-md-3">
                                                        <div class="input-group input-group-static null is-focused">
                                                            <material-input id="dFechaIngreso" v-model:value="preventive.dFechaIngreso" label="Fecha Ingreso a taller" variant="static" name="dFechaIngreso" type="date"></material-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="input-group input-group-static null is-focused">
                                                            <material-input id="dFechaEgreso" v-model:value="preventive.dFechaEstimada" label="Fecha programada de salida" variant="static" name="dFechaEgreso" type="date"></material-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="input-group input-group-static null is-focused">
                                                            <material-input id="dFechaEgresoReal" v-model:value="preventive.dFechaEgreso" label="Fecha real de salida de taller" variant="static" name="dFechaEgresoReal" type="date"></material-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <material-input id="vUbicacion" v-model:value="preventive.vUbicacion" label="Lugar" variant="static" name="vUbicacion"></material-input>
                                                    </div>
                                                </div>
                                                <div class="row d-flex align-items-end justify-content-center mt-4">
                                                    <div class="col-md-3">
                                                        <material-input id="fCosto" v-model:value="preventive.fCosto" label="Costo Total" variant="static" name="fCosto"></material-input>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <material-input id="vComentarios" v-model:value="preventive.vComentarios" label="Comentarios" variant="static" name="vComentarios"></material-input>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Estatus</h6>
                                                        <p class="text-sm text-secondary mb-0">
                                                            <span v-if="preventive.iEstatus === 1" class="badge badge-md badge-info">En Proceso</span>
                                                            <span v-else-if="preventive.iEstatus === 3" class="badge badge-md badge-danger">Cancelado</span>
                                                            <span v-else class="badge badge-md badge-success">Completado</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <h6 class="mb-0 text-sm">Factura del Mantenimiento</h6>
                                                        <div class="col-12 col-md-12 col-lg-12">
                                                            <material-button v-show="!file" size="sm" type="button">
                                                                <label for="fileInput" class="mb-0 text-white small cursor-pointer">Seleccionar Factura</label>
                                                                <input id="fileInput" type="file" style="display: none;" @change.prevent="onFileChange">
                                                            </material-button>
                                                            <div v-show="file">
                                                                <material-button class="btn btn-icon btn-2 btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                    <span class="btn-inner--icon"><i class="material-icons">description</i></span>
                                                                </material-button>
                                                                <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="onFileRemove">
                                                                    <label class="mb-0 text-white small cursor-pointer">&#10005; Eliminar</label>
                                                                </material-button>
                                                                <material-button size="sm" type="button">
                                                                    <label for="fileInput" class="mb-0 text-white small cursor-pointer">Cambiar</label>
                                                                    <input id="fileInput" type="file" style="display: none;" @change.prevent="onFileChange">
                                                                </material-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <hr class="hr">
                                                    <h6 class="mb-0">Detalle del Mantenimiento</h6>
                                                    <div class="table-responsive">
                                                        <table class="table align-items-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th class="title-column ps-2 text-start">Clave</th>
                                                                    <th class="title-column ps-2 text-start">Actividad</th>
                                                                    <th class="title-column ps-2 text-start">Tipo de Conjunto</th>
                                                                    <th class="title-column ps-2 text-start">Puntos a Considerar</th>
                                                                    <th class="title-column ps-2 text-start">Acción de Mtto.</th>
                                                                    <th class="title-column ps-2 text-center">Ciclo de Mtto.</th>
                                                                    <th class="title-column ps-2 text-center">Tolerancia</th>
                                                                    <th class="title-column ps-2 text-center">Complejidad</th>
                                                                    <th class="title-column ps-2 text-center">Duración estimada</th>
                                                                    <th class="title-column ps-2 text-center">Criticidad (alta, media, baja)</th>
                                                                    <th class="title-column ps-2 text-end">Costo mano Obra</th>
                                                                    <th class="title-column ps-2 text-end">Costo Refacciones</th>
                                                                    <th class="title-column ps-2 text-end">Costo total</th>
                                                                    <th class="title-column ps-2 text-center">Estatus</th>
                                                                    <th class="title-column ps-2">Evidencia Fotográfica</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(item, index) in preventive.lDetalle" :key="item.iIdDetallePlanMantenimientoPrev">
                                                                    <td><input v-model="item.selected" type="checkbox"></td>
                                                                    <td><p class="table-p mb-0 text-start">{{ item.vClave }}</p></td>
                                                                    <td><p class="table-p mb-0 text-start">{{ item.nombreActividad }}</p></td>
                                                                    <td><p class="table-p mb-0 text-start">{{ item.nombreTipoConjunto }}</p></td>
                                                                    <td><p class="table-p mb-0 text-start">{{ item.vNotasTecnicas }}</p></td>
                                                                    <td><p class="table-p mb-0 text-start">{{ item.nombreTipoAccion }}</p></td>
                                                                    <td><p class="table-p mb-0 text-center">{{ item.iKms }}</p></td>
                                                                    <td><p class="table-p mb-0 text-center">{{ item.iToleranciaKms }}</p></td>
                                                                    <td><p class="table-p mb-0 text-center">{{ item.nombreComplejidad }}</p></td>
                                                                    <td><p class="table-p mb-0 text-center">{{ item.iToleranciaMeses }}</p></td>
                                                                    <td><p class="table-p mb-0 text-center">{{ item.nombreComplejidad }}</p></td>
                                                                    <td><p class="table-p mb-0 text-end">$ {{ item.fCostoManoObra }}</p></td>
                                                                    <td><p class="table-p mb-0 text-end">$ {{ item.fCostoRefacciones }}</p></td>
                                                                    <td><p class="table-p mb-0 text-end">$ {{ item.fCostoTotal }}</p></td>
                                                                    <td class="text-center">
                                                                        <div class="mt-1 text-center mb-2">
                                                                            <material-button v-show="!item.fFile" size="sm" type="button">
                                                                                <label :for="`imageInput_${index}`" class="mb-0 text-white small">Select Image</label>
                                                                                <input :id="`imageInput_${index}`" :data-type="`${index}`" type="file" style="display: none;" accept="image/*" @change.prevent="onFileChangeMulti">
                                                                            </material-button>
                                                                            <div v-show="item.fFile">
                                                                                <material-button class="btn btn-icon btn-2 btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#modalImage" @click.prevent="onViewImage(item.vEvidencia)">
                                                                                    <span class="btn-inner--icon"><i class="material-icons">description</i></span>
                                                                                </material-button>
                                                                                <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="onFileRemoveMulti(index)">
                                                                                    <label class="mb-0 text-white small">&#10005; Eliminar</label>
                                                                                </material-button>
                                                                                <material-button size="sm" type="button">
                                                                                    <label :for="`imageInput_${index}`" class="mb-0 text-white small">Cambiar</label>
                                                                                    <input :id="`imageInput_${index}`" :data-type="`${index}`" type="file" style="display: none;" accept="image/*" @change.prevent="onFileChangeMulti">
                                                                                </material-button>
                                                                            </div>
                                                                            <br /><br />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-md-12 d-flex justify-content-end">
                                                        <button class="btn btn-danger mb-0 js-btn-prev me-2" type="button" title="Return" onClick="history.go(-1); return false;">Regresar</button>
                                                        <material-button class="float-right btn btm-sm" @click.prevent="update">Guardar</material-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title font-weight-normal">Factura del Mantenimiento</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe :src="preventive.vFactura" style="width:100%; height:700px;" frameborder="0"></iframe>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
    <div id="modalImage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="exampleModalLabel" class="modal-title font-weight-normal">Evidencia</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img :src="image" style="width:100%; object-fit: contain;">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import showSwal from "../../../mixins/showSwal";
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL;
const FILE_URL = process.env.VUE_APP_FILE_BASE_URL;
import preventivesService from '../../../services/preventives.service';

export default {
    name: 'EditPreventive',
    components: {
        MaterialButton,
        MaterialInput,
    },
    data() {
        return {
            unidad: {},
            plan: {},
            preventive: {
                iIdPlanPreventivo: null,
                iIdPlanMantenimientoPrev: null,
                iIdUnidad: null,
                dFechaIngreso: "",
                dFechaEgreso: "",
                dFechaEstimada: "",
                iOdometro: 0,
                vDescripcion: "",
                vComentarios: "",
                fCosto: 0,
                iEstatus: 1,
                vFactura: "",
                vUbicacion: "",
                lDetalle: []
            },
            file: null,
            image: null,
            errors: {}
        }
    },
    async mounted() {
        await this.getData(this.$route.params.id);
    },
    methods: {
        getUnidad(id) {
            axios.get(API_URL + "/unidades/" + id).then(res => {
                this.unidad = res.data;

                this.preventive.iIdUnidad = this.unidad.iIdUnidad;
                this.preventive.iOdometro = this.unidad.iKmActual;
            });
        },
        async onFileChange(event) {
            this.file = event.target.files[0];
            this.preventive.vFactura = URL.createObjectURL(this.file);

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.file);
            this.preventive.vFactura = FILE_URL + rutaFile;
            //this.file = null
        },
        onFileRemove() {
            this.file = null
            this.preventive.vFactura = null;
        },
        async onFileChangeMulti(event) {
            const index = event.target.getAttribute("data-type");
            this.preventive.lDetalle[index].fFile = event.target.files[0];

            const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.preventive.lDetalle[index].fFile);
            this.preventive.lDetalle[index].vEvidencia = FILE_URL + rutaFile
        },
        onFileRemoveMulti(index) {
            this.preventive.lDetalle[index].fFile = null
            this.preventive.lDetalle[index].vEvidencia = require("@/assets/img/placeholder.jpg");
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        onViewImage(path) {
            this.image = path;
        },
        formatDate(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1;
            var y = date.getFullYear();
            return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
        },
        async getData(id) {
            this.preventive = await preventivesService.getPreventive(id);

            this.preventive.dFechaIngreso  = this.formatDate(new Date(this.preventive.dFechaIngreso));
            this.preventive.dFechaEstimada = this.formatDate(new Date(this.preventive.dFechaEstimada));
            this.preventive.dFechaEgreso   = this.formatDate(new Date(this.preventive.dFechaEgreso));

            this.getUnidad(this.preventive.iIdUnidad);
        },
        async update() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch("preventives/editPreventive", this.preventive);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Preventive added successfully!",
                    width: 500
                });
                this.$router.push({ name: "PreventiveList" });
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Ocurrió un error, contacte con el administrador.",
                    width: 500
                });
            }
        }
    }
}
</script>
<style>
    .choices__inner {
        padding-bottom: 0 !important;
    }
    .nombre-actividad {
        width: 350px;
        text-overflow: ellipsis;
        cursor: pointer;
        word-break: break-all;
        overflow: hidden;
        white-space: nowrap;
    }
    .nombre-actividad:hover{
        overflow: visible; 
        white-space: normal;
        height:auto;  /* just added this line */
    }
</style>