/* eslint-disable no-unused-vars */
import prevActivityService from '../services/prevActivity.service';

const initialState = { prevActivities: null, prevActivity: null };

export const pActivities = {
    namespaced: true,
    state: initialState,
    actions: {
        async getPrevActivities({ commit }, options) {
            const prevActivities = await prevActivityService.getPrevActivities(options);
            console.log("si gertere")
            console.log(prevActivities)
            commit('getPrevActivitiesSuccess', prevActivities);
        },
        async addPrevActivity({ commit }, newPrevActivity) {
            const prevActivity = await prevActivityService.addPrevActivity(newPrevActivity);
            commit('getCompanySuccess', prevActivity);
        },
        async deletePrevActivity({ commit }, id) {
            await prevActivityService.deletePrevActivity(id);
        },
        async getPrevActivity({ commit }, id) {
            const prevActivity = await prevActivityService.getPrevActivity(id);
            commit('getCompanySuccess', prevActivity);
        },
        async editPrevActivity({ commit }, prevActivity) {
            console.log(prevActivity);
            await prevActivityService.editPrevActivity(prevActivity); 
        },
    },
    mutations: {
        getPrevActivitiesSuccess(state, prevActivity) {
            state.prevActivities = prevActivity;
        },
        getCompanySuccess(state, prevActivity){
            state.prevActivity = prevActivity;
        }
    },
    getters: {
        getPrevActivitiesData(state) {
            return state.prevActivities?.data;
        },
        getPrevActivitiesMeta(state) {
            return state.prevActivities?.meta;
        },
        getprevActivity(state){
            return state.prevActivity
        }
    }
}

