import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getBitacoras(params) {
        const response = await axios.get(API_URL + "/bitacoras/control", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },
    async getBitacora(bitacoraId) {
        const response = await axios.get(API_URL + "/bitacoras/control/" + bitacoraId, { headers: authHeader() });
        console.log(response.data);
        return response.data;
    },
    async addBitacora(bitacora) {
        bitacora.type = "bitacoras"
        const newJson = dataFormatter.serialize({ stuff: bitacora });
        let object    = newJson.data?.attributes;

        console.log(object);
        const response = await axios.post(API_URL + "/bitacoras/control", object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async editBitacora(bitacora) {
        bitacora.type = "bitacoras"
        const newJson = dataFormatter.serialize({ stuff: bitacora });
        let object = newJson.data?.attributes;
        const response = await axios.put(API_URL + "/bitacoras/control/" + bitacora.iIdBitacoraControl, object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async deleteBitacora(bitacoraId) {
        await axios.delete(API_URL + "/bitacoras/control/" + bitacoraId, { headers: authHeader() });
    },
};
