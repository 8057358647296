<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <!--form panels-->
                                <div class="row">
                                    <div class="col-12 col-lg-11 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">event</i>
                                                </div>
                                                <h6 class="mb-0">Registro de Modelos</h6>
                                            </div>
                                            <div class="card-body">
                                                <form class="multisteps-form__form">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vNombre" v-model:value="modelos.vNombre" label="Nombre Modelo" variant="dynamic" name="vNombre"></material-input>
                                                            <validation-error v-if="errors && errors.vNombre" class="text-danger"> <small>{{ errors.vNombre[0] }}</small> </validation-error>
                                                        </div>
                                                        <!--selectedMarca-->
                                                        <div class="col-12 col-sm-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdMarca" class="form-control" name="iIdMarca">
                                                                    <option value="" selected disabled>Seleccionar Marca</option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdMarca" class="text-danger"> <small>{{ errors.iIdMarca[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iCapacidadPasajerosSentados" v-model:value="modelos.iCapacidadPasajerosSentados" label="Capacidad Pasajeros Sentados" variant="dynamic" name="iCapacidadPasajerosSentados"></material-input>
                                                            <validation-error v-if="errors && errors.iCapacidadPasajerosSentados" class="text-danger"> <small>{{ errors.iCapacidadPasajerosSentados[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iCapacidadPasajerosPie" v-model:value="modelos.iCapacidadPasajerosPie" label="Capacidad Pasajeros de Pie" variant="dynamic" name="iCapacidadPasajerosPie"></material-input>
                                                            <validation-error v-if="errors && errors.iCapacidadPasajerosPie" class="text-danger"> <small>{{ errors.iCapacidadPasajerosPie[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iCapacidadTotal" v-model:value="modelos.iCapacidadTotal" label="Capacidad Total" variant="dynamic" name="iCapacidadTotal"></material-input>
                                                            <validation-error v-if="errors && errors.iCapacidadTotal" class="text-danger"> <small>{{ errors.iCapacidadTotal[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="nLargo" v-model:value="modelos.nLargo" label="Largo" variant="dynamic" name="nLargo"></material-input>
                                                            <validation-error v-if="errors && errors.nLargo" class="text-danger"> <small>{{ errors.nLargo[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="nAncho" v-model:value="modelos.nAncho" label="Ancho" variant="dynamic" name="nAncho"></material-input>
                                                            <validation-error v-if="errors && errors.nAncho" class="text-danger"> <small>{{ errors.nAncho[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iNumeroEjes" v-model:value="modelos.iNumeroEjes" label="Numero Ejes" variant="dynamic" name="iNumeroEjes"></material-input>
                                                            <validation-error v-if="errors && errors.iNumeroEjes" class="text-danger"> <small>{{ errors.iNumeroEjes[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="nPesoVehicular" v-model:value="modelos.nPesoVehicular" label="Peso Vehicular" variant="dynamic" name="nPesoVehicular"></material-input>
                                                            <validation-error v-if="errors && errors.nPesoVehicular" class="text-danger"> <small>{{ errors.nPesoVehicular[0] }} </small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iAutonomia" v-model:value="modelos.iAutonomia" label="Autonomia" variant="dynamic" name="iAutonomia"></material-input>
                                                            <validation-error v-if="errors && errors.iAutonomia" class="text-danger"> <small>{{ errors.iAutonomia[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <!--selectedTipo-->
                                                        <div class="col-12 col-sm-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdTipoVehiculo" class="form-control" name="iIdTipoVehiculo">
                                                                    <option value="" selected disabled>Seleccionar Tipo Vehiculo</option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdTipoVehiculo" class="text-danger"> <small>{{ errors.iIdTipoVehiculo[0] }}</small> </validation-error>
                                                        </div>
                                                        <!--selectedCombustible-->
                                                        <div class="col-12 col-sm-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdTipoCombustible" class="form-control" name="iIdTipoCombustible">
                                                                    <option value="" selected disabled>Seleccionar Combustible</option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdTipoCombustible" class="text-danger"> <small>{{ errors.iIdTipoCombustible[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-md-12 d-flex justify-content-end mt-3">
                                                            <material-button class="float-right btn btm-sm btn-danger me-3" @click="this.$router.push({ name: 'Modelos' })">Cancelar</material-button>
                                                            <material-button class="float-right btn btm-sm" @click.prevent="marcaAdd">Guardar</material-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import Choices from "choices.js";

import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'AddModelo',
    components: {
        MaterialButton,
        MaterialInput
    },
    mixins: [formMixin],
    data() {
        return {
            marcas: [],
            marcasChoises: null,
            tipoVehiculos: [],
            tipoVehiculosChoises: null,
            tipoCombustible: [],
            tipoCombustibleChoises: null,
            modelos: {
                iActivo: 1,
                vNombre: '',
                iIdMarca: null,
                iIdTipoVehiculo: null,
                iIdTipoCombustible: null,
                iCapacidadPasajerosSentados: '',
                iCapacidadPasajerosPie: '',
                iCapacidadTotal: '',
                nLargo: '',
                nAncho: '',
                iNumeroEjes: '',
                nPesoVehicular: '',
                iAutonomia:''
            },
            errors: {}
        }
    },
    mounted(){
        //MARCAS
        this.getMarcas();
        //SELECT TIPO VEHICULOS
        this.getTipoVehiculos();
        //SELECT COMBUSTIBLE
        this.getCombustible();

    },
    methods: {
        //OBTENER MARCAS
        getMarcas(){
            axios.get(API_URL + "/obtener-marcas").then(res => {
                this.marcas = res.data;
                if(document.getElementById("iIdMarca")){
                    var element = document.getElementById("iIdMarca");
                    this.marcasChoises = new Choices(element,{
                        searchEnabled: true,
                });
                this.marcasChoises.setChoices(this.marcas, 'iIdMarca','vNombreMarca')
                }
            });
        },
        //OBTENER TIPO DE VEHICULOS
        getTipoVehiculos(){
            axios.get(API_URL + "/obtener-tipo-vehiculos").then(res =>{
                console.log(res.data);
                this.tipoVehiculos = res.data;
                if(document.getElementById("iIdTipoVehiculo")){
                    var element = document.getElementById("iIdTipoVehiculo");
                    this.tipoVehiculosChoises = new Choices(element,{
                        searchEnabled: true,
                });
                this.tipoVehiculosChoises.setChoices(this.tipoVehiculos, 'iIdTipoVehiculo','vNombre')
                }
            });

        },
        //OBTENER TIPO COMBUSTIBLE
        getCombustible(){
            axios.get(API_URL + "/obtener-tipo-combustible").then(res =>{
                console.log(res.data);
                this.tipoCombustible = res.data;
                if(document.getElementById("iIdTipoCombustible")){
                    var element = document.getElementById("iIdTipoCombustible");
                    this.tipoCombustibleChoises = new Choices(element,{
                        searchEnabled: true,
                    });
                    this.tipoCombustibleChoises.setChoices(this.tipoCombustible,'iIdTipoCombustible','vNombre')
                }
            });

        },
        async marcaAdd() {
            this.resetApiValidation();
            this.modelos.iIdMarca = this.marcasChoises.getValue().value;
            this.modelos.iIdTipoVehiculo = this.tipoVehiculosChoises.getValue().value;
            this.modelos.iIdTipoCombustible = this.tipoCombustibleChoises.getValue().value;
            try {
                await axios.post(API_URL + "/modelo/guardar-modelo", this.modelos);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Modelo Registrado Con Exito!",
                    width: 500
                });
                this.$router.push({ name: "Modelos" })
            } catch (error) {
               if(error.response.status === 422){
                this.errors = error.response.data.errors;
               }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Oops, Ocurrio un error al registrar el modelo verique los campos!",
                    width: 500
                });

            }
        }
    }
}
</script>