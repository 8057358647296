import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getCompanies(params) {
        const response = await axios.get(API_URL + "/companies", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },
    async getCompany(companyId) {
        const response = await axios.get(API_URL + "/companies/" + companyId, { headers: authHeader() });
        console.log(response.data);
        return response.data;
    },
    async addCompany(company) {
        company.type = "companies"
        const newJson = dataFormatter.serialize({ stuff: company });
        let object = newJson.data?.attributes;
        const response = await axios.post(API_URL + "/companies", object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async editCompany(company) {
        company.type = "companies"
        const newJson = dataFormatter.serialize({ stuff: company });
        let object = newJson.data?.attributes;
        const response = await axios.put(API_URL + "/companies/" + company.iIdEmpresa, object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async deleteCompany(companyId) {
        await axios.delete(API_URL + "/companies/" + companyId, { headers: authHeader() });
    },
}