<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Add user</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm" @click="this.$router.push({ name: 'Users' })"> Back to list </material-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="row mt-5">
                                <div class="col-md-6">
                                    <label>Rol</label>
                                    <select id="iIdRol" class="multisteps-form__select form-control" @change="onSelectRol($event)"></select>
                                </div>
                                <div class="col-md-6">
                                    <label>Empresa</label>
                                    <select id="iIdEmpresa" class="multisteps-form__select form-control" @change="onSelectEmpresa($event)"></select>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-4">
                                    <material-input id="vNombre" v-model:value="user.vNombre" label="Name" variant="static" name="vNombre"></material-input>
                                    <validation-error :errors="apiValidationErrors.vNombre"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input
                                        id="vPrimerApellido"
                                        v-model:value="user.vPrimerApellido"
                                        label="Primer Apellido"
                                        variant="static"
                                        name="vPrimerApellido"
                                    ></material-input>
                                    <validation-error :errors="apiValidationErrors.vPrimerApellido"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input
                                        id="vSegundoApellido"
                                        v-model:value="user.vSegundoApellido"
                                        label="SegundoA pellido"
                                        variant="static"
                                        name="vSegundoApellido"
                                    ></material-input>
                                    <validation-error :errors="apiValidationErrors.vSegundoApellido"></validation-error>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-4">
                                    <material-input id="vCorreo" v-model:value="user.vCorreo" label="Correo" variant="static" name="vCorreo"></material-input>
                                    <validation-error :errors="apiValidationErrors.vCorreo"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input id="vUsuario" v-model:value="user.vUsuario" label="Usuario" variant="static" name="vUsuario"></material-input>
                                    <validation-error :errors="apiValidationErrors.vUsuario"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input
                                        id="password"
                                        v-model:value="user.password"
                                        type="password"
                                        label="Contrasenia"
                                        variant="static"
                                        name="password"
                                    ></material-input>
                                    <validation-error :errors="apiValidationErrors.password"></validation-error>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-4">
                                    <material-input id="vTelefono" v-model:value="user.vTelefono" label="Telefono" variant="static" name="vTelefono"></material-input>
                                    <validation-error :errors="apiValidationErrors.vTelefono"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input id="vCelular" v-model:value="user.vCelular" label="Celular" variant="static" name="vCelular"></material-input>
                                    <validation-error :errors="apiValidationErrors.vCelular"></validation-error>
                                </div>
                                <div class="col-md-4">
                                    <material-input
                                        id="vCorreoPersonal"
                                        v-model:value="user.vCorreoPersonal"
                                        label="Correo Personal"
                                        variant="static"
                                        name="vCorreoPersonal"
                                    ></material-input>
                                    <validation-error :errors="apiValidationErrors.vCorreoPersonal"></validation-error>
                                </div>
                            </div>
                            <div class="row mt-4 mb-5 d-flex align-items-end">
                                <div class="col-2 text-end">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Editar</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import formMixin from '@/mixins/formMixin.js';
import showSwal from '../../../mixins/showSwal';
import Choices from 'choices.js';

export default {
    name: 'EditUser',
    components: {
        MaterialButton,
        MaterialInput,
        // ValidationError,
    },
    mixins: [formMixin],
    data() {
        return {
            user: {},
        };
    },
    async mounted() {
        try {
            await this.getUser();
            await this.getRoles();
            await this.getEmpresas();
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        async getUser() {
            await this.$store.dispatch('users/getUser', this.$route.params.id);
            this.user = this.$store.getters['users/getUser'];
        },
        async getRoles() {
            await this.$store.dispatch('roles/getRoles');
            const roles = this.$store.getters['roles/getRolesData'];
            new Choices("#iIdRol").setChoices(roles, 'iIdRol', 'vRol')
                .setChoiceByValue(this.user.iIdRol);
        },
        async getEmpresas() {
            await this.$store.dispatch('companies/getCompanies');
            const empresas = this.$store.getters['companies/getCompaniesData'];
            new Choices("#iIdEmpresa").setChoices(empresas, 'iIdEmpresa', 'vEmpresa')
                .setChoiceByValue(this.user.iIdEmpresa);
        },
        onSelectRol(event) {
            this.user.iIdRol = Number(event.target.value);
        },
        onSelectEmpresa(event) {
            this.user.iIdEmpresa = Number(event.target.value);
        },
        async handleEdit() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch('users/editUser', this.user);
                showSwal.methods.showSwal({
                    type: 'success',
                    message: 'User modified successfully!',
                    width: 500,
                });
                this.$router.push({ name: 'Users' });
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: 'error',
                    message: 'Oops, something went wrong!',
                    width: 500,
                });
            }
        },
    },
};
</script>
