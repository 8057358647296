<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 plan">
            <div class="col-12">
                <div class="plan d-flex align-items-center">
                    <div class="col-6">
                        <h5 class="mb-0">Mantenimientos preventivos</h5>
                    </div>
                    <div class="col-6 text-end"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="input-group input-group-outline bg-white">
                                <select v-model="iIdMarca" class="form-control" name="iIdMarca" placeholder="Selecciona"
                                    @change="onChangeMarca($event)">
                                    <option selected value="">Seleccione una marca</option>
                                    <option v-for="item in marcas" :key="item.iIdMarca"
                                        :value="item.iIdMarca">
                                        {{ item.vNombreMarca }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="input-group input-group-outline bg-white">
                                <select v-model="iIdModelo" class="form-control" name="iIdModelo"
                                    placeholder="Selecciona" @change="onChangeModelo($event)">
                                    <option selected value="">Seleccione un modelo</option>
                                    <option v-for="item in modelos" :key="item.iIdModelo"
                                        :value="item.iIdModelo">
                                        {{ item.vNombre }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="input-group input-group-outline bg-white">
                                <select v-model="iIdUnidad" class="form-control" name="iIdUnidad"
                                    placeholder="Selecciona" @change="onChangeUnidad($event)">
                                    <option selected value="">Seleccione una unidad</option>
                                    <option v-for="item in unidades" :key="item.iIdUnidad"
                                        :value="item.iIdUnidad">
                                        {{ item.vPlaca }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 justify-content-start">
                    <a href="#" class="btn btn-success" role="button" aria-pressed="true"
                        @click.prevent="getPreventives(filters)">Filtrar</a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="title-column ps-2">Marca</th>
                                        <th class="title-column ps-2">Modelo</th>
                                        <th class="title-column ps-2">Serie</th>
                                        <th class="title-column ps-2 text-center">Placa</th>
                                        <th class="title-column ps-2 text-center">Año</th>
                                        <th class="title-column ps-2 text-center">Km Actual</th>
                                        <th class="title-column ps-2 text-center">Fecha último Mantenimiento</th>
                                        <th class="title-column ps-2">Comentarios</th>
                                        <th class="title-column ps-2 text-center">Sig. Mantenimiento</th>
                                        <th class="title-column ps-2">Siguiente Plan</th>
                                        <th class="title-column ps-2"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in prevList" :key="item.idPlan">
                                        <td><p class="table-p">{{ item.marca }}</p></td>
                                        <td><p class="table-p">{{ item.modelo }}</p></td>
                                        <td><p class="table-p">{{ item.serie }}</p></td>
                                        <td><p class="table-p text-center">{{ item.placa }}</p></td>
                                        <td><p class="table-p text-center">{{ item.anio }}</p></td>
                                        <td><p class="table-p text-center">{{ item.kmActual }}</p></td>
                                        <td><p class="table-p text-center">{{ (item.idPreventivo != null) ? item.prevFecha.toString() : 'Sin mantenimientos' }}</p></td>
                                        <td><p class="table-p">{{ (item.prevComentarios != "") ? item.prevComentarios : 'Sin comentarios' }}</p></td>
                                        <td :class="{ 'bg-success': (item.kmsPlan > item.kmActual), 'bg-danger': (item.kmsPlan < item.kmActual) }"><p class="table-p text-center text-white">{{ item.kmsPlan }} km</p></td>
                                        <td><p class="table-p">{{ item.nombrePlan }}</p></td>
                                        <td>
                                            <div class="col-md-12  m-0">
                                                <div class="d-flex align-items-center justify-content-end">
                                                    <material-button class="btn btm-sm mb-0" type="button" data-bs-toggle="modal" data-bs-target="#historyModal" @click.prevent="getHistorial(item.idUnidad)">Historial</material-button>
                                                    <material-button class="btn btm-sm btn-success mb-0 me-3" @click.prevent="addPreventive(item.idUnidad, item.idPlan)">Ingresar Vehículo</material-button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="historyModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="historyModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="historyModalLabel" class="modal-title font-weight-normal">Historial de mantenimientos</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mt-4">
                        <div class="col-12">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="title-column ps-2 text-start">Fecha Ingreso</th>
                                        <th class="title-column ps-2 text-start">Fecha Egreso</th>
                                        <th class="title-column ps-2 text-center">Odometro</th>
                                        <th class="title-column ps-2 text-end">Costo</th>
                                        <th class="title-column ps-2 text-center">Estatus</th>
                                        <th class="title-column ps-2 text-center">Factura</th>
                                        <th class="title-column ps-2 text-center">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in historial" :key="item.iIdPlanPreventivo">
                                        <td><p class="table-p text-start">{{ item.dFechaIngreso }}</p></td>
                                        <td><p class="table-p text-start">{{ item.dFechaEgreso }}</p></td>
                                        <td><p class="table-p text-center">{{ item.iOdometro }}</p></td>
                                        <td><p class="table-p text-end">${{ formatPrice(item.fCosto) }}</p></td>
                                        <td class="text-center">
                                            <span v-if="item.iEstatus === 1" class="badge badge-md badge-info">En Proceso</span>
                                            <span v-else-if="item.iEstatus === 3" class="badge badge-md badge-danger">Cancelado</span>
                                            <span v-else class="badge badge-md badge-success">Completado</span>
                                        </td>
                                        <td class="text-center">
                                            <p v-if="item.vFactura == ''" class="table-p text-center">Sin Factura</p>
                                            <div v-else class="col-md-12 text-center m-0">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <a :href="item.vFactura" target="_blank">
                                                        <span class="btn-inner--icon"><i class="material-icons">description</i></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div class="col-md-12 text-center m-0">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <material-button class="btn btm-sm btn-success m-0" @click="this.$router.push({ name: 'EditPreventive', params: { id: item.iIdPlanPreventivo } })">Detalle</material-button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <material-button class="float-right btn btm-sm bg-gradient-secondary" data-bs-dismiss="modal">Close</material-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// import Choices from "choices.js";
import axios from 'axios';
import authHeader from '../../../services/auth-header';
import MaterialButton from "@/components/MaterialButton.vue";
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: "PreventiveList",
    components: {
        MaterialButton,
    },
    data() {
        return {
            search: '',
            iIdMarca: '',
            iIdModelo: '',
            iIdUnidad: '',
            prevList: [],
            marcas: [],
            modelos: [],
            unidades: [],
            historial: [],
            filters: {
                iIdMarca: null,
                iIdModelo: null,
                iIdUnidad: null,
            }
        }
    },
    async mounted() {
        this.getPreventives(this.filters);
        this.getMarcas();
        this.getModelos(0);
        this.getUnidades(0);
    },
    methods: {
        addPreventive(iIdUnidad, idPlan) {
            this.$router.push({ path: "/maintenances/add-preventive", query: { iIdUnidad: iIdUnidad, idPlan: idPlan } });
        },
        getPreventives(filters) {
            this.prevList = [];

            axios.get(API_URL + "/preventives", { headers: authHeader() }, { params: { iIdMarca: filters.iIdMarca, iIdModelo: filters.iIdModelo, iIdUnidad: filters.iIdUnidad } }).then(res => {
                this.prevList = res.data;
            });
        },
        getMarcas() {
            axios.get(API_URL + "/unidad/obtener-marcas").then(res => {
                this.marcas = res.data;
            });
        },
        getModelos(idMarca) {
            this.modelos = [];

            axios.get(API_URL + "/modelos/marca/" + idMarca).then(res => {
                this.modelos = res.data;
            });
        },
        getUnidades(iIdModelo) {
            this.unidades = [];

            axios.get(API_URL + "/unidades/modelo/" + iIdModelo, { headers: authHeader() }).then(res => {
                this.unidades = res.data;
            });
        },
        getHistorial(idUnidad) {
            this.historial = [];

            axios.get(API_URL + "/preventives/unidad/" + idUnidad).then(res => {
                this.historial = res.data;
            });
        },
        onChangeMarca(event) {
            this.iIdMarca = event.target.value;

            this.filters.iIdMarca = this.iIdMarca == '' ? null : this.iIdMarca;
            this.filters.iIdModelo = this.iIdModelo == '' ? null : this.iIdModelo;
            this.filters.iIdUnidad = this.iIdUnidad == '' ? null : this.iIdUnidad;

            this.getModelos(this.iIdMarca == '' ? 0 : this.iIdMarca);
        },
        onChangeModelo(event) {
            this.iIdModelo = event.target.value == '' ? null : event.target.value;

            this.filters.iIdMarca = this.iIdMarca == '' ? null : this.iIdMarca;
            this.filters.iIdModelo = this.iIdModelo == '' ? null : this.iIdModelo;
            this.filters.iIdUnidad = this.iIdUnidad == '' ? null : this.iIdUnidad;

            this.getUnidades(this.iIdModelo == '' ? 0 : this.iIdModelo);
        },
        onChangeUnidad(event) {
            this.iIdUnidad = event.target.value;

            this.filters.iIdMarca = this.iIdMarca == '' ? null : this.iIdMarca;
            this.filters.iIdModelo = this.iIdModelo == '' ? null : this.iIdModelo;
            this.filters.iIdUnidad = this.iIdUnidad == '' ? null : this.iIdUnidad;
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        getStatus(value) {
            switch (value) {
                case 1:
                    return "Pendiente";
                case 2:
                    return "Completado";
                default:
                    return "Pendiente";
            }
        }
    }
};
</script>
<style>
.title-column {
    text-transform: uppercase !important;
    color: #7b809a !important;
    opacity: 0.7 !important;
    font-weight: 700 !important;
    font-size: 0.65rem !important;
}

.table-p {
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.25 !important;
    margin-bottom: 0 !important;
}
a:hover {
    color: #4caf50 !important;
}
</style>
