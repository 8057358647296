<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-bolder mb-0">Poliza de Seguro</h5>
        <p class="mb-0 text-sm">Campos obligatorios *</p>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <material-input id="vNumPoliza" v-model:value="policy.vNumPoliza" label="Número Póliza" variant="dynamic"
            name="vNumPoliza"></material-input>
          <validation-error v-if="errors && errors.vNumPoliza" class="text-danger"> {{
            errors.vNumPoliza[0] }}</validation-error>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <div class="input-group input-group-dynamic null is-focused">
            <material-input id="dFechaContratacion" v-model:value="policy.dFechaContratacion"
              label="Fecha Inicio de Vigencia" variant="dynamic" name="dFechaContratacion" type="date"></material-input>
          </div>
          <validation-error v-if="errors && errors.dFechaContratacion" class="text-danger"> {{
            errors.dFechaContratacion[0] }}</validation-error>
        </div>
        <div class="col-12 col-sm-4">
          <div class="input-group input-group-dynamic null is-focused">
            <material-input id="dFechaVencimiento" v-model:value="policy.dFechaVencimiento"
              label="Fecha Fin de Vigencia" variant="dynamic" name="dFechaVencimiento" type="date"></material-input>
          </div>
          <validation-error v-if="errors && errors.dFechaVencimiento" class="text-danger"> {{
            errors.dFechaVencimiento[0] }}</validation-error>
        </div>
        <!-- <div class="col-12 col-sm-4">
          <material-input id="nDiasRestantes" v-model:value="policy.nDiasRestantes" label="Dias Restantes"
            variant="dynamic" name="nDiasRestantes" disabled></material-input>
          <validation-error v-if="errors && errors.nDiasRestantes" class="text-danger"> {{
            errors.nDiasRestantes[0] }}</validation-error>
        </div> -->
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <select id="iIdAseguradora" class="multisteps-form__select form-control" name="iIdAseguradora">
            <option value="" selected>Seleccionar Aseguradora</option>
          </select>
        </div>
        <div class="col-12 col-sm-4">
          <select id="iIdCobertura" class="multisteps-form__select form-control" name="iIdCobertura">
            <option value="" selected>Seleccionar Cobertura</option>
          </select>
        </div>
        <div class="col-12 col-sm-4">
          <material-input id="fSumaAsegurada" v-model:value="policy.fSumaAsegurada" label="Suma Asegurada"
            variant="dynamic" name="fSumaAsegurada"></material-input>
          <validation-error v-if="errors && errors.fSumaAsegurada" class="text-danger"> {{
            errors.fSumaAsegurada[0] }}</validation-error>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-12">
          <div class="input-group input-group-dynamic">
            <textarea v-model="policy.vComentarios" class="form-control" placeholder="Notas" rows="3"></textarea>
          </div>
        </div>
      </div>
      <div class="mt-4 button-row d-flex">
        <material-button type="button" color="dark" variant="gradient" class="ms-auto mb-0 js-btn-next"
          @click="dataAdd">Guardar</material-button>
        <!-- <button class="mb-0 btn bg-gradient-light js-btn-prev" type="button" title="Prev" @click="this.$parent.prevStep">
          Prev
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../../mixins/showSwal";
import axios from 'axios';
import Choices from "choices.js";
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "InsurancePolicy",
  components: {
    MaterialInput,
    MaterialButton
  },
  mixins: [formMixin],
  data() {
    return {
      aseguradoras: [],
      aseguradorasChoices: null,
      coberturas: [],
      coberturasChoices: null,
      policy: {
        vNumPoliza: null,
        iIdUnidad: '',
        iIdCobertura: null,
        iIdAseguradora: null,
        iActivo: true
      }
    }
  },
  mounted() {
    this.getAseguradoras();
    this.getCoberturas();
  },
  methods: {
    getAseguradoras() {
      axios.get(API_URL + "/aseguradoras").then(res => {
        console.log(res.data);
        this.aseguradoras = res.data;
        if (document.getElementById("iIdAseguradora")) {
          let element = document.getElementById("iIdAseguradora");
          this.aseguradorasChoices = new Choices(element, {
            searchEnabled: true,
          });
          this.aseguradorasChoices.setChoices(this.aseguradoras, 'iIdAseguradora', 'vNombreAseguradora')
          //this.aseguradorasChoices.setChoiceByValue(this.aseguradoras[0]?.iIdAseguradora)
        }
      });
    },
    getCoberturas() {
      axios.get(API_URL + "/coberturas").then(res => {
        console.log(res.data);
        this.coberturas = res.data;
        if (document.getElementById("iIdCobertura")) {
          let element = document.getElementById("iIdCobertura");
          this.coberturasChoices = new Choices(element, {
            searchEnabled: true,
          });
          this.coberturasChoices.setChoices(this.coberturas, 'iIdCobertura', 'vDescripcion')
          //this.coberturasChoices.setChoiceByValue(this.coberturas[0]?.iIdCobertura)
        }
      });
    },
    async dataAdd() {
      this.resetApiValidation();
      this.policy.iIdAseguradora = this.aseguradorasChoices.getValue().value;
      this.policy.iIdCobertura = this.coberturasChoices.getValue().value;
      try {
        await axios.post(API_URL + "/unidad/guardar-poliza", this.policy);
        showSwal.methods.showSwal({
          type: "success",
          message: "Poliza Registrada Con Exito!",
          width: 500
        });
        this.$parent.nextStep();
      } catch (error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, Ocurrio un error al registrar la Unidad verique los campos!",
          width: 500
        });

      }

    }
  }
};
</script>
