<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="multisteps-form mb-9">
                                <!--form panels-->
                                <div class="row">
                                    <div class="col-12 col-lg-10 m-auto">
                                        <div class="card">
                                            <div class="card-header p-3 pt-2">
                                                <div
                                                    class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">event</i>
                                                </div>
                                                <h6 class="mb-0">Registro Actividades de Mantenimiento Preventivo</h6>
                                            </div>
                                            <div class="card-body">
                                                <form class="multisteps-form__form">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="vClave" v-model:value="activity.vClave"
                                                                label="Clave" variant="dynamic"
                                                                name="vClave"></material-input>
                                                            <validation-error v-if="errors && errors.vClave"
                                                                class="text-danger"> {{ errors.vClave[0]
                                                                }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <material-input id="vDescripcion"
                                                                    v-model:value="activity.vDescripcion" variant="dynamic"
                                                                    label="Descripcion" name="vDescripcion" />
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iKms" v-model:value="activity.iKms"
                                                                label="Kms" variant="dynamic" name="iKms"
                                                                type="number"></material-input>
                                                            <validation-error v-if="errors && errors.iKms"
                                                                class="text-danger"> {{ errors.iKms[0]
                                                                }}</validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iMeses" v-model:value="activity.iMeses"
                                                                label="Meses" variant="dynamic" name="iMeses"
                                                                type="number"></material-input>
                                                            <validation-error v-if="errors && errors.iMeses"
                                                                class="text-danger"> {{ errors.iMeses[0]
                                                                }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iToleranciaKm"
                                                                v-model:value="activity.iToleranciaKm" label="Tolerancia Km"
                                                                variant="dynamic" name="iToleranciaKm"
                                                                type="number"></material-input>
                                                            <validation-error v-if="errors && errors.iToleranciaKm"
                                                                class="text-danger"> {{
                                                                    errors.iToleranciaKm[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iToleranciaMeses"
                                                                v-model:value="activity.iToleranciaMeses"
                                                                label="Tolerancia Meses" variant="dynamic"
                                                                name="iToleranciaMeses" type="number"></material-input>
                                                            <validation-error v-if="errors && errors.iToleranciaMeses"
                                                                class="text-danger"> {{
                                                                    errors.iToleranciaMeses[0] }}</validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="iDuracionHoras"
                                                                v-model:value="activity.iDuracionHoras"
                                                                label="Duracion Horas" variant="dynamic"
                                                                name="iDuracionHoras" type="number"></material-input>
                                                            <validation-error v-if="errors && errors.iDuracionHoras"
                                                                class="text-danger"> {{
                                                                    errors.iDuracionHoras[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="fCostoManoObra"
                                                                v-model:value="activity.fCostoManoObra"
                                                                label="Costo Mano de Obra" variant="dynamic"
                                                                name="fCostoManoObra" type="number"></material-input>
                                                            <validation-error v-if="errors && errors.fCostoManoObra"
                                                                class="text-danger"> {{
                                                                    errors.fCostoManoObra[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="fCostoRefacciones"
                                                                v-model:value="activity.fCostoRefacciones"
                                                                label="Costo de Refacciones" variant="dynamic"
                                                                name="fCostoRefacciones" type="number"></material-input>
                                                            <validation-error v-if="errors && errors.fCostoRefacciones"
                                                                class="text-danger"> {{
                                                                    errors.fCostoRefacciones[0] }}</validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="fCostoOtro"
                                                                v-model:value="activity.fCostoOtro" label="Costo Otro"
                                                                variant="dynamic" name="fCostoOtro"
                                                                type="number"></material-input>
                                                            <validation-error v-if="errors && errors.fCostoOtro"
                                                                class="text-danger"> {{ errors.fCostoOtro[0]
                                                                }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="fCostoTotal"
                                                                v-model:value="activity.fCostoTotal" label="Costo Total"
                                                                variant="dynamic" name="fCostoTotal"
                                                                type="number"></material-input>
                                                            <validation-error v-if="errors && errors.fCostoTotal"
                                                                class="text-danger"> {{
                                                                    errors.fCostoTotal[0] }}</validation-error>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <material-input id="fCostoRefacciones"
                                                                v-model:value="activity.fCostoRefacciones"
                                                                label="Costo de Refacciones" variant="dynamic"
                                                                name="fCostoRefacciones" type="number"></material-input>
                                                            <validation-error v-if="errors && errors.fCostoRefacciones"
                                                                class="text-danger"> {{
                                                                    errors.fCostoRefacciones[0] }}</validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-md-12">
                                                            <div class="input-group input-group-dynamic">
                                                                <textarea v-model="activity.vNotasTecnicas" class="form-control"
                                                                    placeholder="Notas Tecnicas" rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-sm-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdTipoConjunto"
                                                                    class="multisteps-form__select form-control"
                                                                    name="iIdTipoConjunto">
                                                                    <option value="" selected disabled>Seleccionar Tipo
                                                                        Conjunto
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdTipoConjunto"
                                                                class="text-danger"> <small>{{
                                                                    errors.iIdTipoConjunto[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-sm-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdTipoAccion"
                                                                    class="multisteps-form__select form-control"
                                                                    name="iIdTipoAccion">
                                                                    <option value="" selected disabled>Seleccionar Tipo
                                                                        Accion
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdTipoAccion"
                                                                class="text-danger"> <small>{{
                                                                    errors.iIdTipoAccion[0] }}</small> </validation-error>
                                                        </div>
                                                        <div class="col-12 col-sm-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdComplejidad"
                                                                    class="multisteps-form__select form-control"
                                                                    name="iIdComplejidad">
                                                                    <option value="" selected disabled>Seleccionar
                                                                        Complejidad
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdComplejidad"
                                                                class="text-danger"> <small>{{
                                                                    errors.iIdComplejidad[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-sm-4">
                                                            <div class="input-group input-group-dynamic">
                                                                <select id="iIdModelo"
                                                                    class="multisteps-form__select form-control"
                                                                    name="iIdModelo">
                                                                    <option value="" selected disabled>Seleccionar
                                                                        Modelo
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <validation-error v-if="errors && errors.iIdModelo"
                                                                class="text-danger"> <small>{{
                                                                    errors.iIdModelo[0] }}</small> </validation-error>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-5">
                                                        <div class="col-md-12 d-flex justify-content-end mt-3">
                                                            <material-button class="float-right btn btm-sm btn-danger me-3"
                                                                @click="this.$router.push({ name: 'PrevActivity' })">Cancelar</material-button>
                                                            <material-button class="float-right btn btm-sm"
                                                                @click.prevent="handleAdd">Guardar</material-button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import Choices from "choices.js";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'AddCompany',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    data() {
        return {
            activity: {},
            complejidadChoises: null,
            conjuntoChoises: null,
            accionChoises: null,
            modeloChoises: null,
        }
    },
    async mounted() {
        this.getConjuntos();
        this.getAcciones();
        this.getComplejidades();
        this.getModelos();
    },
    methods: {
        getConjuntos() {
            axios.get(API_URL + '/conjuntos').then(res => {
                this.listConjuntos = res.data;
                if (document.getElementById("iIdTipoConjunto")) {
                    var element = document.getElementById("iIdTipoConjunto");
                    this.conjuntoChoises = new Choices(element, {
                        searchEnabled: true,
                        itemSelectText: 'Press to select',
                    });
                    this.conjuntoChoises.setChoices(this.listConjuntos, 'iIdTipoConjunto', 'vDescripcion')
                }
            });
        },
        getAcciones() {
            axios.get(API_URL + '/acciones').then(res => {
                this.listAccion = res.data;
                if (document.getElementById("iIdTipoAccion")) {
                    var element = document.getElementById("iIdTipoAccion");
                    this.accionChoises = new Choices(element, {
                        searchEnabled: true,
                        itemSelectText: 'Press to select',
                    });
                    this.accionChoises.setChoices(this.listAccion, 'iIdTipoAccion', 'vDescripcion')
                }
            });
        },
        getComplejidades() {
            axios.get(API_URL + '/complejidades').then(res => {
                this.listComplejidad = res.data;
                if (document.getElementById("iIdComplejidad")) {
                    var element = document.getElementById("iIdComplejidad");
                    this.complejidadChoises = new Choices(element, {
                        searchEnabled: true,
                        itemSelectText: 'Press to select',
                    });
                    this.complejidadChoises.setChoices(this.listComplejidad, 'iIdComplejidad', 'vDescripcion')
                }
            });
        },
        getModelos() {
            axios.get(API_URL + '/unidad/obtener-modelos').then(res => {
                this.listModelo = res.data;
                if (document.getElementById("iIdModelo")) {
                    var element = document.getElementById("iIdModelo");
                    this.modeloChoises = new Choices(element, {
                        searchEnabled: true,
                        itemSelectText: 'Press to select',
                    });
                    this.modeloChoises.setChoices(this.listModelo, 'iIdModelo', 'vNombre')
                }
            });
        },
        async handleAdd() {
            this.resetApiValidation();
            this.activity.iIdTipoConjunto = this.conjuntoChoises.getValue().value;
            this.activity.iIdTipoAccion = this.accionChoises.getValue().value;
            this.activity.iIdComplejidad = this.complejidadChoises.getValue().value;
            this.activity.iIdModelo = this.modeloChoises.getValue().value;

            try {
                await this.$store.dispatch("pActivities/addPrevActivity", this.activity);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Registro exitoso!",
                    width: 500
                });
                this.$router.push({ name: "PrevActivity" })
            } catch (error) {
                console.log("erreer")
                console.log(error)
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Ocurrió un error, contacte con el administrador.",
                    width: 500
                });
            }
        }
    }
}
</script>