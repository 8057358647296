<template>
    <div class="multisteps-form__panel border-radius-xl bg-white" data-animation="FadeIn">
        <h5 class="font-weight-bolder mb-0">About me</h5>
        <div class="multisteps-form__content">
            <div class="row mt-4 overflow-hidden">
                <div>
                    <material-avatar :img="getImage" shadow="regular" class="image-fluid w-20 mt-7" :fixed-size="true"></material-avatar>
                </div>
                <div class="mt-1  mb-2">
                    <material-button v-show="!file" size="sm" type="button">
                        <label for="imageInput" class="mb-0 text-white small cursor-pointer">Select Image</label>
                        <input id="imageInput" type="file" style="display: none;" accept="image/*" @change.prevent="onFileChange">
                    </material-button>
                    <div v-show="file">
                        <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="onFileRemove">
                            <label class="mb-0 text-white small cursor-pointer"> &#10005; Remove</label>
                        </material-button>
                        <material-button size="sm" type="button">
                            <label for="imageInput" class="mb-0 text-white small cursor-pointer">Change</label>
                            <input id="imageInput" type="file" style="display: none;" accept="image/*" @change.prevent="onFileChange">
                        </material-button>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <material-input id="name" v-model:value="user.name" label="Name" variant="static" name="name" />
                <validation-error :errors="apiValidationErrors.name" />
            </div>
            <div class="row mt-5">
                <material-input id="email" v-model:value="user.email" type="email" label="Email Address" variant="static" name="email" />
                <validation-error :errors="apiValidationErrors.email" />
            </div>
            <div class="button-row d-flex mt-4">
                <material-button type="button" color="dark" variant="gradient" class="ms-auto mb-0 js-btn-next" @click="handleSubmit">Submit Changes</material-button>
            </div>
        </div>
    </div>
</template>
<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import _ from "lodash"

export default {
    name: "Info",
    components: {
        MaterialInput,
        MaterialButton,
        MaterialAvatar,
        ValidationError,
    },
    mixins: [formMixin],
    data() {
        return {
            user: {},
            file: null,
            loading: null,
            initialImageUrl: "",
        }
    },
    computed: {
        getImage() {
            if (!this.user.profile_image || this.loading) return require("@/assets/img/placeholder.jpg")
            else { return this.user.profile_image }
        }
    },
    async mounted() {
        this.loading = true
        try {
            //await this.$store.dispatch("profile/getProfile");
            this.user = _.omit(this.$store.getters['profile/getUserProfile'], 'links');
            this.loading = false
            this.initialImageUrl = this.getImage;
        } catch (error) {
            try {
                this.$store.dispatch('auth/logout');
            } finally {
                // eslint-disable-next-line no-unsafe-finally
                return this.$router.push({ name: "Login" });
            }
        }
        this.loading = false
    },
    methods: {
        onFileChange(event) {
            this.file = event.target.files[0];
            this.user.profile_image = URL.createObjectURL(this.file);
        },
        onFileRemove() {
            this.file = null
            this.user.profile_image = this.initialImageUrl;
        },
        async handleSubmit() {

        }
    },
};
</script>
