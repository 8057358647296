/* eslint-disable no-unused-vars */
import correctivesService from '../services/correctives.service';

const initialState = { correctives: null, corrective: null };

export const correctives = {
    namespaced: true,
    state: initialState,
    actions: {
        async getCorrectives({ commit }, options) {
            const correctives = await correctivesService.getCorrectives(options);
            commit('getCorrectivesSuccess', correctives);
        },
        async addCorrective({ commit }, newCorrective) {
            const corrective = await correctivesService.addCorrective(newCorrective);
            commit('getCorrectiveSuccess', corrective);
        },
        async deleteCorrective({ commit }, correctiveId) {
            await correctivesService.deleteCorrective(correctiveId);
        },
        async getCorrective({ commit }, correctiveId) {
            const corrective = await correctivesService.getCorrective(correctiveId);
            commit('getCorrectiveSuccess', corrective);
        },
        async editCorrective({ commit }, modifiedcorrective) {
            console.log(modifiedcorrective);
            await correctivesService.editCorrective(modifiedcorrective); 
        },
    },
    mutations: {
        getCorrectivesSuccess(state, correctives) {
            state.correctives = correctives;
        },
        getCorrectiveSuccess(state,corrective){
            state.corrective = corrective;
        }
    },
    getters: {
        getCorrectivesData(state) {
            return state.correctives?.data;
        },
        getCorrectivesMeta(state) {
            return state.correctives?.meta;
        },
        getCorrective(state){
            return state.corrective
        },
    }
}

