import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getCorrectives(params) {
        const response = await axios.get(API_URL + "/correctives", { headers: authHeader(), params: params });
        return { data: response.data.data, meta: response.data };
    },
    async getCorrective(correctiveId) {
        const response = await axios.get(API_URL + "/correctives/" + correctiveId, { headers: authHeader() });
        console.log(response.data);
        return response.data;
    },
    async addCorrective(corrective) {
        corrective.type = "correctives"
        const newJson = dataFormatter.serialize({ stuff: corrective });
        let object    = newJson.data?.attributes;

        console.log(object);
        const response = await axios.post(API_URL + "/correctives", object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async editCorrective(corrective) {
        corrective.type = "correctives"
        const newJson  = dataFormatter.serialize({ stuff: corrective });
        let object     = newJson.data?.attributes;
        const response = await axios.put(API_URL + "/correctives/" + corrective.iIdMantenimiento, object, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
    async deleteCorrective(correctiveId) {
        await axios.delete(API_URL + "/correctives/" + correctiveId, { headers: authHeader() });
    },
}