<template>
  <div class="pt-3 bg-white multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="text-center row">
      <div class="mx-auto col-10">
        <h5 class="font-weight-bolder mb-0">Galeria de Imagenes</h5>
      </div>
    </div>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div v-for="(item, index) in listImage" :key="item.id" class="col-md-6">
          <h4 class="font-weight-bold text-success text-center mb-5">{{ item.vDescripcion }}</h4>
          <div>
            <material-avatar :img="item.vRutaImagen" shadow="regular" class="image-fluid w-70 h-90 mt-7"
              :fixed-size="false"></material-avatar>
          </div>
          <div class="mt-1  mb-2">
            <material-button v-show="!item.fFile" size="sm" type="button">
              <label :for="`imageInput_${index}`" class="mb-0 text-white small">Select Image</label>
              <input :id="`imageInput_${index}`" :data-type="`${index}`" type="file" style="display: none;"
                accept="image/*" @change.prevent="onFileChange">
            </material-button>

            <div v-show="item.fFile">
              <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="onFileRemove(index)">
                <label class="mb-0 text-white small"> &#10005; Eliminar</label>
              </material-button>
              <material-button size="sm" type="button">
                <label :for="`imageInput_${index}`" class="mb-0 text-white small">Cambiar</label>
                <input :id="`imageInput_${index}`" :data-type="`${index}`" type="file" style="display: none;"
                  accept="image/*" @change.prevent="onFileChange">
              </material-button>
            </div>
            <br /><br />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mt-4 button-row d-flex col-12">
          <button class="mb-0 btn bg-gradient-light js-btn-prev" type="button" title="Prev"
            @click="this.$parent.prevStep">
            Atras
          </button>
          <button class="mb-0 btn bg-gradient-dark ms-auto" type="button" title="Guardar" @click="dataAdd">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import showSwal from "../../../../mixins/showSwal";
import axios from 'axios';
const FILE_URL = process.env.VUE_APP_FILE_BASE_URL;
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  name: "GaleryImages",
  components: {
    MaterialButton,
    MaterialAvatar
  },
  data() {
    return {
      listImage: [
        { iTipoImagen: 1, vDescripcion: "Frente", vRutaImagen: require("@/assets/img/placeholder.jpg"), fFile: null },
        { iTipoImagen: 2, vDescripcion: "Atras", vRutaImagen: require("@/assets/img/placeholder.jpg"), fFile: null },
        { iTipoImagen: 3, vDescripcion: "Lateral Izquierdo", vRutaImagen: require("@/assets/img/placeholder.jpg"), fFile: null },
        { iTipoImagen: 4, vDescripcion: "Lateral Derecho", vRutaImagen: require("@/assets/img/placeholder.jpg"), fFile: null },
        { iTipoImagen: 5, vDescripcion: "A Bordo de Frente", vRutaImagen: require("@/assets/img/placeholder.jpg"), fFile: null },
        { iTipoImagen: 6, vDescripcion: "A Bordo Atras", vRutaImagen: require("@/assets/img/placeholder.jpg"), fFile: null },
      ],
    }
  },
  computed: {

  },
  methods: {
    async onFileChange(event) {
      const index = event.target.getAttribute("data-type");
      this.listImage[index].fFile = event.target.files[0];

      const rutaFile = await this.$store.dispatch("rutas/uploadFile", this.listImage[index].fFile);
      this.listImage[index].vRutaImagen = FILE_URL + rutaFile
    },
    onFileRemove(index) {
      this.listImage[index].fFile = null
      this.listImage[index].vRutaImagen = require("@/assets/img/placeholder.jpg");
    },
    async dataAdd() {
      console.log("save files")
      console.log(this.listImage)

      try {
        await axios.post(API_URL + "/unidad/guardar-galeria", this.listImage);
        showSwal.methods.showSwal({
          type: "success",
          message: "Galeria Guardado Con Exito!",
          width: 500
        });
        this.$router.push({ name: "Items List" })
      } catch (error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, Ocurrio un error al registrar la Unidad verique los campos!",
          width: 500
        });
      }
    },
  },
};
</script>
