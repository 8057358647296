<template>
    <div class="col-12 col-md-4">
        <div class="card h-100">
            <div class="card-body d-flex flex-column justify-content-around">
                <div class="d-flex align-items-center justify-content-between gap-2">
                    <h6 class="text-sm mb-0 text-capitalize fw-bold">Mantenimientos {{ tipo }}</h6>
                    <small class="text-xs text-secondary">{{ fechaIn }} - {{ fechaFn }}</small>
                </div>
                <h4 class="fw-bold mb-0">{{ total }}</h4>
                <p class="text-sm text-secondary mb-0"><span class="text-success">+55%</span> en el último mes.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MantenimientoTotalCard',
    props: {
        fechaIn: {
            type: String,
            default: '',
        },
        fechaFn: {
            type: String,
            default: '',
        },
        tipo: {
            type: String,
            default: '',
        },
        total: {
            type: Number,
            default: 0,
        },
    },
};
</script>
