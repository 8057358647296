<template>
    <section class="container py-4">
        <div class="row m-2 mx-sm-0">
            <div class="card col-12 col-sm-10 mx-auto bitacora-inicial-form">
                <div class="card-header pt-2 px-3">
                    <div class="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                        <i class="material-icons opacity-10">event</i>
                    </div>
                    <h6 class="mb-0">Bitácora Inicial</h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="mb-3">
                                <material-input v-model:value="bitacora.unidad.vNumEconomico" variant="static" label="N° Económico" disabled />
                            </div>
                            <div class="mb-3">
                                <material-input v-model:value="bitacora.nKm" variant="static" type="number" label="KM" disabled />
                            </div>
                            <div class="mb-3">
                                <material-input v-model:value="bitacora.vMatricula" variant="static" label="Matrícula" disabled />
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="mb-3">
                                <material-input v-model:value="bitacora.dFecha" variant="static" type="datetime-local" label="Fecha y hora" disabled />
                            </div>
                            <div class="mb-3">
                                <material-input v-model:value="bitacora.vObra" variant="static" label="Obra" disabled />
                            </div>
                            <div>
                                <material-input v-model:value="bitacora.vOperario" variant="static" label="Operario" disabled />
                            </div>
                        </div>

                        <div class="col-12 mt-5">
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iEncender" class="form-check-label">Encender vehículo</label>
                                <input id="iEncender" v-model="bitacora.iEncender" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iCarga" class="form-check-label">Comprobacion carga vehículo</label>
                                <input id="iCarga" v-model="bitacora.iCarga" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <div class="d-flex flex-column flex-sm-row" style="gap: 1rem">
                                    <label for="iBateriasStock" class="form-check-label">Comprobar baterías</label>
                                    <material-input v-model:value="bitacora.nStock" variant="static" type="number" label="Stock" />
                                </div>
                                <input id="iBateriasStock" v-model="bitacora.iBateriasStock" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iPuertas" class="form-check-label">Comprobación sistema puertas</label>
                                <input id="iPuertas" v-model="bitacora.iPuertas" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iRampa" class="form-check-label">Comprobación Rampa</label>
                                <input id="iRampa" v-model="bitacora.iRampa" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iLuces" class="form-check-label">Comprobar luces: interior/exterior/señalización</label>
                                <input id="iLuces" v-model="bitacora.iLuces" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iBocinas" class="form-check-label">Comprobar bocina/limpias</label>
                                <input id="iBocinas" v-model="bitacora.iBocinas" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iRetrovisores" class="form-check-label">Comprobar bocina/limpias</label>
                                <input id="iRetrovisores" v-model="bitacora.iRetrovisores" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iCamaras" class="form-check-label">Comprobación cámara marcha atrás/ puertas</label>
                                <input id="iCamaras" v-model="bitacora.iCamaras" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iHaltbrake" class="form-check-label">Comprobar Haltbrake</label>
                                <input id="iHaltbrake" v-model="bitacora.iHaltbrake" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iRuido" class="form-check-label">Comprobar ruido blanco</label>
                                <input id="iRuido" v-model="bitacora.iRuido" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iCircuitoA" class="form-check-label">Comprobar nivel circuito A</label>
                                <input id="iCircuitoA" v-model="bitacora.iCircuitoA" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iCircuitoB" class="form-check-label">Comprobar nivel circuito B</label>
                                <input id="iCircuitoB" v-model="bitacora.iCircuitoB" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iCircuitoC" class="form-check-label">Comprobar nivel circuito C</label>
                                <input id="iCircuitoC" v-model="bitacora.iCircuitoC" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iAsientos" class="form-check-label">Comprobar asientos</label>
                                <input id="iAsientos" v-model="bitacora.iAsientos" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iBarras" class="form-check-label">Comprobar barras/pulsadores</label>
                                <input id="iBarras" v-model="bitacora.iBarras" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iAverias" class="form-check-label">Comprobar averías cuadro y SPN</label>
                                <input id="iAverias" v-model="bitacora.iAverias" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="i24v" class="form-check-label">Comprobar averías cuadro y SPN</label>
                                <input id="i24v" v-model="bitacora.i24v" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iPreacondicionamiento" class="form-check-label">Preacondicionamiento</label>
                                <input id="iPreacondicionamiento" v-model="bitacora.iPreacondicionamiento" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iApagar" class="form-check-label">Apagar vehículo</label>
                                <input id="iApagar" v-model="bitacora.iApagar" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div class="d-flex justify-content-between form-check form-switch p-0 mb-3" style="gap: 1rem">
                                <label for="iDisponible" class="form-check-label">Disponible</label>
                                <input id="iDisponible" v-model="bitacora.iDisponible" type="checkbox" role="swtich" class="form-check-input" />
                            </div>
                            <div>
                                <material-textarea v-model:value="bitacora.vComentarios" placeholder="Notas" />
                            </div>
                        </div>

                        <div class="col-12 mt-5">
                            <div class="d-flex flex-column flex-sm-row justify-content-sm-end gap-3">
                                <material-button color="danger" @click="this.$router.push({ name: 'BitacoraInicialList' })">Cancelar</material-button>
                                <material-button @click="updateBitacoraInicial">Actualizar bitácora inicial</material-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialTextarea from '@/components/MaterialTextarea.vue';
import formMixin from '@/mixins/formMixin.js';
import showSwal from '@/mixins/showSwal.js';

export default {
    name: 'EditBitacoraInicial',
    components: {
        MaterialButton,
        MaterialInput,
        MaterialTextarea,
    },
    mixins: [formMixin, showSwal],
    data() {
        return {
            bitacora: {
                iIdBitacoraInicial: 0,
                iIdUnidad: 0,
                nKm: 0,
                vMatricula: '',
                dFecha: null,
                vObra: '',
                vOperario: '',
                iEncender: false,
                iCarga: false,
                iBateriasStock: false,
                nStock: 0,
                iPuertas: false,
                iRampa: false,
                iLuces: false,
                iBocinas: false,
                iRetrovisores: false,
                iCamaras: false,
                iHaltbrake: false,
                iRuido: false,
                iCircuitoA: false,
                iCircuitoB: false,
                iCircuitoC: false,
                iAsientos: false,
                iBarras: false,
                iAverias: false,
                i24v: false,
                iPreacondicionamiento: false,
                iApagar: false,
                iDisponible: false,
                vComentarios: '',
                unidad: {
                    vNumEconomico: '',
                },
            },
        };
    },
    mounted() {
        this.getBitacoraInicial(Number(this.$route.params.id));
    },
    methods: {
        async getBitacoraInicial(id) {
            await this.$store.dispatch('bitacoraInicial/getBitacoraInicial', id);
            const bitacora = this.$store.getters['bitacoraInicial/getBitacoraInicial'];

            if (bitacora) {
                this.bitacora = bitacora;
            }
        },
        async updateBitacoraInicial() {
            await this.$store.dispatch('bitacoraInicial/updateBitacoraInicial', this.bitacora);
            const { message, error } = this.$store.getters['bitacoraInicial/updateBitacoraInicial'];

            showSwal.methods.showSwal({
                type: error ? 'danger' : 'success',
                message: message,
                width: 500,
            });

            if (!error) {
                this.$router.push({ name: 'BitacoraInicialList' });
            }
        },
    },
};
</script>

<style>
.bitacora-inicial-form label {
    margin: 0;
}
</style>
