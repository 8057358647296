<template>
    <main class="main-content  mt-0">
        <div class="page-header align-items-start min-vh-100" :style="{ backgroundImage: 'url(' + require('@/assets/img/login3.jpg') + ')'}">
            <span class="mask bg-gradient-dark opacity-6"></span>
            <div class="container my-auto">
                <div class="row">
                    <div class="col-lg-4 col-md-8 col-12 mx-auto">
                        <div class="card z-index-0 fadeIn3 fadeInBottom">
                            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
                                    <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">Iniciar Sesión</h4>
                                    <p class="mb-1 text-sm text-white text-center">Ingresa tu correo y contraseña para iniciar sesión</p>
                                </div>
                            </div>
                            <div class="card-body">
                                <Form role="form" class="text-start mt-3" :validation-schema="schema" @submit="handleLogin" @invalid-submit="badSubmit">
                                    <div class="mb-3">
                                        <material-input-field id="email" v-model:value="user.email" type="email" label="Email" name="email" variant="static" />
                                    </div>
                                    <div class="mb-3">
                                        <material-input-field id="password" v-model:value="user.password" type="password" label="Password" name="password" variant="static" />
                                    </div>
                                    <material-switch id="rememberMe" name="Remember Me">Remember me</material-switch>
                                    <div class="text-center">
                                        <material-button class="my-4 mb-2" variant="gradient" color="success" full-width>
                                            <span>Iniciar Sesión</span>
                                        </material-button>
                                    </div>
                                </Form>
                            </div>
                            <div class="card-footer text-center pt-0 px-sm-4 px-1 mt-4">
                                <p class="mb-2 mx-auto">
                                    <router-link :to="{ name: 'PasswordForgot' }" class="text-success text-gradient font-weight-bold">Recuperar Contraseña</router-link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import MaterialInputField from "@/components/MaterialInputField.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import showSwal from "@/mixins/showSwal";
import { mapMutations } from "vuex";
import { Form } from "vee-validate"
import * as Yup from 'yup'
const body = document.getElementsByTagName("body")[0];

export default {
    name: "Login",
    components: {
        MaterialInputField,
        MaterialSwitch,
        MaterialButton,
        Form,
    },
    data() {
        return {
            user: { email: "", password: "" },
            schema: Yup.object().shape({
                email: Yup.string().required("Email is a required input"),
                password: Yup.string().required("Password is a required input")
            }),
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.loggedIn;
        }
    },
    beforeMount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        async handleLogin() {
            try {
                await this.$store.dispatch("auth/login", this.user);
                this.$router.push({ name: "Default" });
            } catch (error) {
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Invalid credentials!",
                    width: 500,
                });
            }
        },
    },
};
</script>
