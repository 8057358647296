<template>
    <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100 scrollable-list">
        <ul class="navbar-nav">
            <li class="nav-item" :class="getRoute() === 'porfileUser' ? 'active' : ''">
                <sidenav-collapse collapse-ref="porfileUser" :nav-text="getProfileName"  :class="getRoute() === 'porfileUser' ? 'active' : ''">
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item :to="{ name: 'User Profile' }" mini-icon="P" text="My Profile" />
                            <sidenav-item :to="{ name: 'Settings' }" mini-icon="S" text="Settings" />
                            <sidenav-item :to="{ name: 'Login' }" mini-icon="L" text="Logout" @click="logout" />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
            <hr class="horizontal light mt-2 mb-2" />
            <li class="nav-item" :class="getRoute() === 'catalogs' ? 'active' : ''">
                <sidenav-collapse collapse-ref="catalogs" nav-text="Catálogos" :class="getRoute() === 'catalogs' ? 'active' : ''">
                    <template #icon>
                        <i class="material-icons-round opacity-10">dashboard</i>
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item v-if="visible('Usuarios')" :to="{ name: 'Users' }" mini-icon="U" text="Usuarios" />
                            <sidenav-item v-if="visible('Marcas')" :to="{ name: 'Marcas' }" mini-icon="M" text="Marcas" />
                            <sidenav-item v-if="visible('Modelos')" :to="{ name: 'Modelos' }"  mini-icon="M" text="Modelos" />
                            <sidenav-item v-if="visible('Marcas')" :to="{ name: 'Units' }" mini-icon="M" text="Unidades" />
                            <sidenav-item v-if="visible('Empresas')" :to="{ name: 'Companies' }" mini-icon="E" text="Empresas" />
                            <sidenav-item v-if="visible('Rutas')" :to="{ name: 'Rutas' }" mini-icon="R" text="Rutas" />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
            <li class="nav-item" :class="getRoute() === 'maintenances' ? 'active' : ''">
                <sidenav-collapse collapse-ref="maintenances" nav-text="Mantenimientos" :class="getRoute() === 'maintenances' ? 'active' : ''">
                    <template #icon>
                        <i class="material-icons-round opacity-10">construction</i>
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item v-if="visible('Planes de Mantenimiento')" :to="{ name: 'Planes' }" mini-icon="PM" text="Planes" />
                            <sidenav-item v-if="visible('Mantenimientos Preventivos')" :to="{ name: 'PrevActivity' }" mini-icon="AM" text="Actividades" />
                            <sidenav-item v-if="visible('Mantenimientos Preventivos')" :to="{ name: 'PreventiveList' }" mini-icon="MP" text="Preventivos" />
                            <sidenav-item v-if="visible('Mantenimientos Correctivos')" :to="{ name: 'CorrectiveList' }" mini-icon="MC" text="Correctivos" />
                            <sidenav-item v-if="visible('Mantenimientos Preventivos')" :to="{ name: 'Incident' }" mini-icon="PP" text="Registro de Incidentes" />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
            <li class="nav-item" :class="getRoute() === 'inspections' ? 'active' : ''">
                <sidenav-collapse collapse-ref="inspections" nav-text="Inspecciones" :class="getRoute() === 'inspections' ? 'active' : ''">
                    <template #icon>
                        <i class="material-icons-round opacity-10">content_paste</i>
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item v-if="visible('Bitacora de Inspécciones')" :to="{ name: 'InspectionList' }" mini-icon="B" text="Bitacora" />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
            <li class="nav-item" :class="getRoute() === 'bitacoras' ? 'active' : ''">
                <sidenav-collapse collapse-ref="bitacoras" nav-text="Electromovilidad" :class="getRoute() === 'bitacoras' ? 'active' : ''">
                    <template #icon>
                        <i class="material-icons-round opacity-10">content_paste_go</i>
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item v-if="visible('Bitácora inicial')" :to="{ name: 'BitacoraInicialList' }" mini-icon="B" text="Bitácora inicial" />
                            <sidenav-item  :to="{ name: 'ControlLogList' }" mini-icon="BC" text="Bitácora Control" />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
        </ul>
    </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapState } from "vuex";

export default {
    name: "SidenavList",
    components: {
        SidenavItem,
        SidenavCollapse,
        // SidenavCollapseItem,
    },
    data() {
        return {
            title: "Soft UI Dashboard PRO",
            controls: "dashboardsExamples",
            isActive: "active",
            profile_image: null,
            profile_name: "ATY"
        };
    },
    computed: {
        ...mapState(["isRTL"]),
        getImage() {
            if (!this.profile_image) return require("@/assets/img/placeholder.jpg");
            else {
                return this.profile_image;
            }
        },
        role() {
            const user = this.$store.getters["profile/getUserProfile"];
            return user;
        },
        getProfileName(){
            const profile = JSON.parse(localStorage.getItem('profile'))
            return profile?.vNombre
        }
    },
    async created() {
        try {
            //await this.$store.dispatch("profile/getProfile");
            //this.profile_image = this.$store.getters["profile/getUserProfileImage"];
        } catch (error) {
            try {
                this.$store.dispatch("auth/logout");
            } finally {
                this.$router.push({ name: "Login" });
            }
        }
    },
    methods: {
        getRoute() {
            const routeArr = this.$route.path.split("/");
            console.log(routeArr[1]);
            return routeArr[1];
        },
        visible(menu) {
            const permission = JSON.parse(localStorage.getItem('profile'))?.lMenu;
            if (permission?.includes(menu)) {
                return true;
            }else {
                return false;
            }
        },
        logout() {
            this.$store.dispatch("auth/logout");
        },
    },
};
</script>
<style>
    .scrollable-list {
        max-height: fit-content;
        overflow-y: auto;
        scrollbar-width: none;
    }
    .scrollable-list::-webkit-scrollbar {
        width: 0;
    }
</style>
