/* eslint-disable no-unused-vars */
import axios from 'axios';
import authService from './auth.service';
import authHeader from './auth-header';

const URL = `${process.env.VUE_APP_API_BASE_URL}/inspecciones`;

export default {
    async getUnidades() {
        try {
            const { data } = await axios.get(`${URL}/unidades`);

            return data;
        } catch (error) {
            console.log('Inspection Service:', error);

            return [];
        }
    },
    async getUnidad(id) {
        try {
            const { data } = await axios.get(`${URL}/unidades/${id}`);

            return data;
        } catch (error) {
            console.log('Inspection Service:', error);

            return null;
        }
    },
    async getInspecciones() {
        try {
            const session = await authService.getSession();
            const { data } = await axios.get(`${URL}?empresa=${session.iIdEmpresa}`);

            return data;
        } catch (error) {
            console.log('Inspection Service:', error);

            return [];
        }
    },
    async createInspeccion(inspeccion) {
        try {
            const { data } = await axios.post(URL, inspeccion, { headers: authHeader() });

            return data;
        } catch (error) {
            let message = 'Oops, Ocurrio un error al crear la inspección.';

            if (error.response.status === 422) {
                message = error.response.data.message;
            }

            throw new Error(message);
        }
    },
    async getInspeccion(id) {
        try {
            const { data } = await axios.get(`${URL}/${id}`);

            return data;
        } catch (error) {
            console.log('Inspection Service:', error);

            return null;
        }
    },
    async updateInspeccion(inspeccion) {
        try {
            const { data } = await axios.put(`${URL}/${inspeccion.iIdInspeccion}`, inspeccion, { headers: authHeader() });

            return data;
        } catch (error) {
            let message = 'Oops, Ocurrio un error al crear la inspección.';

            if (error.response.status === 422) {
                message = error.response.data.message;
            }

            throw new Error(message);
        }
    },
    async deleteInspeccion(id) {
        try {
            const { data } = await axios.delete(`${URL}/${id}`, null, { headers: authHeader() });

            return data;
        } catch (error) {
            let message = 'Oops, Ocurrio un error al crear la inspección.';

            if (error.response.status === 422) {
                message = error.response.data.message;
            }

            throw new Error(message);
        }
    },
};
