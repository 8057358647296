/* eslint-disable no-unused-vars */
import plansService from '../services/plans.service';

const initialState = { plans: null, plan: null };

export const plans = {
    namespaced: true,
    state: initialState,
    actions: {
        async getPlans({ commit }, options) {
            const plans = await plansService.getPlans(options);
            commit('getPlansSuccess', plans);
        },
        async addPlan({ commit }, newPlan) {
            const plan = await plansService.addPlan(newPlan);
            commit('getPlanSuccess', plan);
        },
        async deletePlan({ commit }, planId) {
            await plansService.deletePlan(planId);
        },
        async getPlan({ commit }, planId) {
            const plan = await plansService.getPlan(planId);
            commit('getPlanSuccess', plan);
        },
        async editPlan({ commit }, modifiedplan) {
            console.log(modifiedplan);
            await plansService.editPlan(modifiedplan); 
        },
    },
    mutations: {
        getPlansSuccess(state, plans) {
            state.plans = plans;
        },
        getPlanSuccess(state,plan){
            state.plan = plan;
        }
    },
    getters: {
        getPlansData(state) {
            return state.plans?.data;
        },
        getPlansMeta(state) {
            return state.plans?.meta;
        },
        getPlan(state){
            return state.plan
        },
    }
}

