/* eslint-disable no-unused-vars */
import router from '@/router';
import tooltip from '@/assets/js/tooltip';
import removeTooltip from '@/assets/js/removeTooltip';
import Swal from 'sweetalert2';
import showSwal from './showSwal.js';
import store from '@/store';

var deleteState = false;
var paramsCall = {
    sort: 'created_at',
    query: '',
    nr: '1',
    perpage: '5',
};

function showSwalDelete(id, table, deletePath, getPath, textDelete) {
    showSwal.methods.showSwalConfirmationDelete().then(async result => {
        if (result.isConfirmed) {
            try {
                deleteState = true;
                await store.dispatch(deletePath, id);
                await store.dispatch(getPath, {
                    ...(paramsCall.sort ? { sort: paramsCall.sort } : {}),
                    filter: {
                        ...(paramsCall.query ? { name: paramsCall.query } : {}),
                    },
                    page: {
                        number: paramsCall.nr,
                        size: paramsCall.perpage,
                    },
                });
                showSwal.methods.showSwal({
                    type: 'success',
                    message: textDelete,
                });
            } catch (error) {
                console.log(error);
                showSwal.methods.showSwal({
                    type: 'error',
                    message: error.response.data.errors[0].detail,
                });
            }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.dismiss;
        }
    });
}

export default {
    methods: {
        eventToCall(options) {
            if (deleteState) {
                removeTooltip();
                deleteState = false;
            }
            tooltip(store.state.bootstrap);

            const buttons = document.querySelectorAll('.actionButton');

            for (const button of buttons) {
                const { id, url, action, } = button.dataset;

                button.addEventListener('click', () => {
                    switch (action) {
                        case 'print':
                            window.open(`${process.env.VUE_APP_API_BASE_URL}/${url}`, '_blank');
                            break;
                        case 'edit':
                            router.push({ name: options.redirectPath, params: { id } });
                            break;
                        case 'delete':
                            showSwalDelete(id, options.table, options.deletePath, options.getPath, options.textDelete);
                            break;
                    }
                });
            }
        },
        actionPrintButton(url, text) {
            return `<a data-url="${url}" data-action="print" class="actionButton cursor-pointer me-3" data-bs-toggle="tooltip" title="${text}">
                <i class="fas fa-print text-info"></i>
              </a>`;
        },
        actionEditButton(id, text) {
            return `<a data-id="${id}" data-action="edit" class="actionButton cursor-pointer me-3" data-bs-toggle="tooltip" title="${text}">
                <i class="fas fa-user-edit text-success"></i>
              </a>`;
        },
        actionDeleteButton(id, text) {
            return `<a data-id="${id}" data-action="delete" class="actionButton deleteButton cursor-pointer" data-bs-toggle="tooltip" title="${text}">
                <i class="fas fa-trash text-danger"></i>
              </a>`;
        },
        removeEvent() {
            var buttons = this.$el.querySelectorAll('.actionButton');
            buttons.forEach(function (button) {
                button.replaceWith(button.cloneNode(true));
            });
        },
    },
};
